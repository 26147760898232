import React, { Component } from 'react';
import { Card, CardBody, Col, Container, Row, Button } from 'reactstrap';
import { withTranslation } from 'react-i18next';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import * as myConstClass from '../../../constant.js';
import 'font-awesome/css/font-awesome.min.css';

class Plan_view extends Component {
  constructor(props) {
    super(props);
    this.state = {
      plan: [],
    }
  }

  componentDidMount() {
    const answer_array = this.props.location.pathname.split('/');
    if (answer_array['3']) {
      fetch(myConstClass.BASE_URL + '/user/getPlanDetailById/' + answer_array['3'])
        .then(response => response.json())
        .then(response =>
          this.setState({ plan: response })
        )
        .catch(err => console.log(err))
    }
    if (!localStorage.getItem('session_id')) {
      this.props.history.push('/log_in');
    }
  }

  render() {
    const { plan } = this.state;
    return (
      <Container>
        <Row>
          <Col md={12}>
            <h3 className="page-title">Plan Detail</h3>
          </Col>
          <ToastContainer enableMultiContainer containerId={'A'} position={toast.POSITION.BOTTOM_LEFT} />
          <ToastContainer enableMultiContainer containerId={'B'} position={toast.POSITION.TOP_RIGHT} />
        </Row>
        <Row>
          <Col md={12} lg={12}>
            <Card>
              <CardBody>
                <Row>
                  <Col md={3} lg={3}>
                    <h5><b>Licence Total : </b></h5>
                  </Col>
                  <Col md={6} lg={6}>
                    <h5>{plan.licence_total}</h5>
                  </Col>
                </Row>
                <br />
                <Row>
                  <Col md={3} lg={3}>
                    <h5><b>Licence Used : </b></h5>
                  </Col>
                  <Col md={6} lg={6}>
                    <h5>{plan.licence_used}</h5>
                  </Col>
                </Row>
                <br />
                <Row>
                  <Col md={3} lg={3}>
                    <h5><b>Id : </b></h5>
                  </Col>
                  <Col md={6} lg={6}>
                    <h5>{plan.id}</h5>
                  </Col>
                </Row>
                <br />

                <Row>
                  <Col md={3} lg={3}>
                    <h5><b>Title : </b></h5>
                  </Col>
                  <Col md={6} lg={6}>
                    <h5>{plan.title}</h5>
                  </Col>
                </Row>
                <br />

                <Row>
                  <Col md={3} lg={3}>
                    <h5><b>Duration : </b></h5>
                  </Col>
                  <Col md={6} lg={6}>
                    <h5>{plan.duration}</h5>
                  </Col>
                </Row>
                <br />

                <Row>
                  <Col md={3} lg={3}>
                    <h5><b>Price : </b></h5>
                  </Col>
                  <Col md={6} lg={6}>
                    <h5>{plan.price}</h5>
                  </Col>
                </Row>
                <br />

                <Row>
                  <Col md={3} lg={3}>
                    <h5><b>Status : </b></h5>
                  </Col>
                  <Col md={6} lg={6}>
                    <h5>{plan.status == "1" ? ("Active") : ("Inactive")}</h5>
                  </Col>
                </Row>
                <br />

                <Row>
                  <Col md={3} lg={3}>
                    <h5><b>SOS : </b></h5>
                  </Col>
                  <Col md={6} lg={6}>
                    <h5>{plan.sos}</h5>
                  </Col>
                </Row>
                <br />

                <Row>
                  <Col md={3} lg={3}>
                    <h5><b>SOS description : </b></h5>
                  </Col>
                  <Col md={6} lg={6}>
                    <h5>{plan.sos_description}</h5>
                  </Col>
                </Row>
                <br />

                <Row>
                  <Col md={3} lg={3}>
                    <h5><b>Follow me : </b></h5>
                  </Col>
                  <Col md={6} lg={6}>
                    <h5>{plan.follow_me}</h5>
                  </Col>
                </Row>
                <br />

                <Row>
                  <Col md={3} lg={3}>
                    <h5><b>Follow me description: </b></h5>
                  </Col>
                  <Col md={6} lg={6}>
                    <h5>{plan.follow_me_description}</h5>
                  </Col>
                </Row>
                <br />

                <Row>
                  <Col md={3} lg={3}>
                    <h5><b>POSH : </b></h5>
                  </Col>
                  <Col md={6} lg={6}>
                    <h5>{plan.posh}</h5>
                  </Col>
                </Row>
                <br />

                <Row>
                  <Col md={3} lg={3}>
                    <h5><b>POSH description : </b></h5>
                  </Col>
                  <Col md={6} lg={6}>
                    <h5>{plan.posh_description}</h5>
                  </Col>
                </Row>
                <br />

                <Row>
                  <Col md={3} lg={3}>
                    <h5><b>Ambulance : </b></h5>
                  </Col>
                  <Col md={6} lg={6}>
                    <h5>{plan.ambulance}</h5>
                  </Col>
                </Row>
                <br />

                <Row>
                  <Col md={3} lg={3}>
                    <h5><b>Ambulance description : </b></h5>
                  </Col>
                  <Col md={6} lg={6}>
                    <h5>{plan.ambulance_description}</h5>
                  </Col>
                </Row>
                <br />

                <Row>
                  <Col md={3} lg={3}>
                    <h5><b>Road side assistance : </b></h5>
                  </Col>
                  <Col md={6} lg={6}>
                    <h5>{plan.road_side_assistance}</h5>
                  </Col>
                </Row>
                <br />

                <Row>
                  <Col md={3} lg={3}>
                    <h5><b>Road side assistance description : </b></h5>
                  </Col>
                  <Col md={6} lg={6}>
                    <h5>{plan.road_side_assistance_description}</h5>
                  </Col>
                </Row>
                <br />

                <Row>
                  <Col md={3} lg={3}>
                    <h5><b>Road side assistance : </b></h5>
                  </Col>
                  <Col md={6} lg={6}>
                    <h5>{plan.road_side_assistance}</h5>
                  </Col>
                </Row>
                <br />

                <Row>
                  <Col md={3} lg={3}>
                    <h5><b>Accidental insurance : </b></h5>
                  </Col>
                  <Col md={6} lg={6}>
                    <h5>{plan.accidental_insurance}</h5>
                  </Col>
                </Row>
                <br />

                <Row>
                  <Col md={3} lg={3}>
                    <h5><b>Accidental insurance description : </b></h5>
                  </Col>
                  <Col md={6} lg={6}>
                    <h5>{plan.accidental_insurance_description}</h5>
                  </Col>
                </Row>
                <br />

                <Row>
                  <Col md={3} lg={3}>
                    <h5><b>Changed Plan : </b></h5>
                  </Col>
                  <Col md={6} lg={6}>
                    <h5>{plan.is_changed == "1" ? ("Changed") : ("")}</h5>
                  </Col>
                </Row>
                <br />
                {plan.old_id != null ? (
                  <Row>
                    <Col md={3} lg={3}>
                      <h5><b>Old plan id : </b></h5>
                    </Col>
                    <Col md={6} lg={6}>
                      <h5>{plan.old_id != "" ? plan.old_id : ("")}</h5>
                    </Col>
                  </Row>
                ) : ("")}
                {plan.old_id != null ? (<br />) : ("")}

                {plan.updated_field != null ? (
                  <Row>
                    <Col md={3} lg={3}>
                      <h5><b>Updated field : </b></h5>
                    </Col>
                    <Col md={6} lg={6}>
                      <h5>{plan.updated_field}</h5>
                    </Col>
                  </Row>
                ) : ("")}
                {plan.updated_field != null ? (<br />) : ("")}

                {plan.is_deleted == "1" ? (
                  <Row>
                    <Col md={3} lg={3}>
                      <h5><b>Deleted : </b></h5>
                    </Col>
                    <Col md={6} lg={6}>
                      <h5>{plan.is_deleted == "1" ? ("Deleted") : ("")}</h5>
                    </Col>
                  </Row>
                ) : ("")}
                {plan.is_deleted == "1" ? (<br />) : ("")}

                <Row>
                  <Col md={3} lg={3}>
                    <h5><b>Date/Time : </b></h5>
                  </Col>
                  <Col md={6} lg={6}>
                    <h5>{plan.created_at}</h5>
                  </Col>
                </Row>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>)
  }
}

export default withTranslation('common')(Plan_view);
