import React, { Component } from 'react';
import { Col, Container, Row, Button, Card, CardBody, ButtonToolbar, Input } from 'reactstrap';
import { Link } from 'react-router-dom';
import { withTranslation } from 'react-i18next';
import * as myConstClass from '../../../constant.js';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { reduxForm } from 'redux-form';
import { ValidatorForm, TextValidator } from 'react-material-ui-form-validator';
import $ from 'jquery';

class Qrcode_add extends Component {
  constructor(props) {
    super(props);
    this.state = {
      formData: {},
      submitted: false,
      errors: {},
    }
  }

  componentDidMount() {
    if (!localStorage.getItem('session_id')) {
      this.props.history.push('/log_in');
    }
  }

  handleChange = (event) => {
    const { formData } = this.state;
    formData[event.target.name] = event.target.value;
    this.setState({ formData });
  }

  handleChangeType = (event) => {
    const { formData } = this.state;
    formData['type'] = event.target.value;
    formData[event.target.name] = event.target.value;
    this.setState({ formData });
  }

  handleSubmit = () => {
    let form = document.querySelector('#pform');
    const errors = {};
    const formData = new FormData(form);
    if (localStorage.getItem('uid')) {
      formData.append('user_id', localStorage.getItem('uid'));
    }
    var code = $("#code").val();
    var type = $("#type").val();
    var check_code = this.checkCodeLength(code);
    if (code == "") {
      toast('Please add code', { containerId: 'B', type: toast.TYPE.ERROR });
    } else if (check_code != "") {
      toast(check_code, { containerId: 'B', type: toast.TYPE.ERROR });
      // } else if (type == "") {
      //   toast('Please select type', { containerId: 'B', type: toast.TYPE.ERROR });
    } else {

      this.setState({ submitted: true }, () => {
        setTimeout(() => this.setState({ submitted: false }), 5000);
        fetch(myConstClass.BASE_URL + `/qrcode/checkQrCode`, {
          method: 'POST',
          body: formData,
        }).then(response => response.json()).then((response) => {

          if (response == 0) {
            setTimeout(() => this.setState({ submitted: false }), 5000);
            fetch(myConstClass.BASE_URL + `/qrcode/AddQrcode`, {
              method: 'POST',
              body: formData,
            }).then(response => response.json()).then((response) => {

              if (response == true) {
                this.props.history.push('/forms/qrcode_list');
                toast('QR Code Added Successfully..!', { containerId: 'B', type: toast.TYPE.SUCCESS })
              } else {
                toast('QR Code Insertion Failed..!', { containerId: 'B', type: toast.TYPE.ERROR })
              }
            }).catch();

          } else {
            toast('Code already exists.', { containerId: 'B', type: toast.TYPE.ERROR })
          }
        }).catch();
      });
    }
  }

  handleChangeCode = (event) => {
    const { formData } = this.state;
    formData[event.target.name] = event.target.value;
    this.setState({ formData });

    const errors = {};
    var val = event.target.value;
    var check_code = this.checkCodeLength(val);
    errors["code_error"] = check_code;
    this.setState({ errors: errors });
  }

  checkCodeLength(val) {
    var code_error_text = "";
    if (val != "") {
      var regex_letter = /[a-zA-Z]/;
      var regex_digit = /[0-9]/;
      if (!val.match(regex_letter)) {
        code_error_text = "QR Code must contain letter";
      }
      if (!val.match(regex_digit)) {
        if (code_error_text != "") {
          code_error_text = code_error_text + ", QR Code must contain digit";
        } else {
          code_error_text = code_error_text + "QR Code must contain digit";
        }
      }
      // if (val.length != 10) {
      if (val.length <= 9 || val.length >= 21) {
        if (code_error_text != "") {
          code_error_text = code_error_text + ", QR Code minimum 10 & maximum 20 character";
        } else {
          code_error_text = code_error_text + "QR Code minimum 10 & maximum 20 character";
        }
      }
    } else {
      code_error_text = "Enter code";
    }
    return code_error_text;
  }

  render() {
    const { formData, submitted, errors } = this.state;

    return (
      <Container>
        <Row>
          <Col md={12}>
            <h3 className="page-title">Add QR Code</h3>
          </Col>
          <ToastContainer enableMultiContainer containerId={'A'} position={toast.POSITION.BOTTOM_LEFT} />
          <ToastContainer enableMultiContainer containerId={'B'} position={toast.POSITION.TOP_RIGHT} />
        </Row>
        <Row>
          <Col md={12} lg={12}>
            <Card>
              <CardBody>
                <div className="card__title">
                </div>
                <div>
                </div>
                <ValidatorForm
                  className="material-form" id="pform"
                  onSubmit={this.handleSubmit}
                >
                  <Row>
                    <Col md={2} lg={2}>
                      <h5><b>Code : </b></h5>
                    </Col>
                    <Col md={5} lg={5} className="mb-4">
                      <TextValidator
                        onChange={this.handleChangeCode}
                        name="code"
                        id="code"
                        // label="Code"
                        value={formData.code}
                        validators={['required']}
                        errorMessages={['this field is required']}
                        className="material-form__field mb-0"
                        placeholder="Code"
                      />
                      <span className="red-text">
                        {errors["code_error"]}
                      </span>
                    </Col>
                  </Row>
                  {/* <Row>
                    <Col md={2} lg={2}>
                      <h5><b>Type : </b></h5>
                    </Col>
                    <Col md={2} lg={2} style={{ maxWidth: '11.667%' }} >
                      <Input type="select" name="type" id="type" onChange={this.handleChangeType} className="width_200px ml-0" style={{ display: 'inline', marginLeft: '10px' }}>
                        <option value="">Select Type</option>
                        <option value={1}>{'Person'} </option>
                        <option value={2}>{'Animal'} </option>
                        <option value={3}>{'Things'} </option>
                      </Input>
                    </Col>
                  </Row> */}

                  <br />
                  <Button
                    color="primary"
                    variant="contained"
                    type="submit"
                    disabled={submitted}
                  >
                    {(submitted && 'Your form is submitted!') || (!submitted && 'Submit')}
                  </Button>
                  <Link className="btn btn-secondary" to="/forms/qrcode_list">Cancel</Link>

                </ValidatorForm>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    )
  }
}

export default reduxForm({
  form: 'floating_labels_form', // a unique identifier for this form
})(withTranslation('common')(Qrcode_add));
