import React, { Component } from 'react';
import { Col, Container, Row, Button, Card, CardBody, ButtonToolbar, Input } from 'reactstrap';
import { withTranslation } from 'react-i18next';
import * as myConstClass from '../../../constant.js';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Field, reduxForm } from 'redux-form';
import { ValidatorForm, TextValidator } from 'react-material-ui-form-validator';
import renderToggleButtonField from '../../../shared/components/form/StatusToggleButton';
import { Link } from 'react-router-dom';

class Tracker_Device_edit extends Component {
  constructor(props) {
    super(props);
    this.state = {
      edit_id: '',
      formData: {},
      submitted: false,
      errors: {},
    }
  }

  componentDidMount() {
    const answer_array = this.props.location.pathname.split('/');
    if (answer_array['3']) {
      this.state.edit_id = answer_array['3'];
      fetch(myConstClass.BASE_URL + '/tracker_device/getTrackerDeviceById/' + answer_array['3'])
        .then(response => response.json())
        .then(response => {
          this.setState({ formData: response })
        }).catch(err => console.log(err))
    }
    if (!localStorage.getItem('session_id')) {
      console.log('History:', this.props);
      this.props.history.push('/log_in');
    }
  }

  handleChange = (event) => {
    const { formData } = this.state;
    formData[event.target.name] = event.target.value;
    this.setState({ formData });
  }

  handleSubmit = () => {
    let form = document.querySelector('#pform');
    const errors = {};
    const formData = new FormData(form);
    if (localStorage.getItem('uid')) {
      formData.append('user_id', localStorage.getItem('uid'));
    }
    this.setState({ submitted: true }, () => {

      setTimeout(() => this.setState({ submitted: false }), 5000);
      fetch(myConstClass.BASE_URL + `/tracker_device/checkTrackerDeviceName/` + this.state.edit_id, {
        method: 'POST',
        body: formData,
      }).then(response => response.json()).then((response) => {

        if (response == 0) {
          setTimeout(() => this.setState({ submitted: false }), 5000);
          fetch(myConstClass.BASE_URL + `/tracker_device/UpdateTrackerDevice/` + this.state.edit_id, {
            method: 'POST',
            body: formData,
          }).then(response => response.json()).then((response) => {
            if (response == true) {
              this.props.history.push('/forms/tracker_device/');
              toast('Tracker Device Updated Successfully..!', { containerId: 'B', type: toast.TYPE.SUCCESS })
            } else {
              toast('Tracker Device Update Failed..!', { containerId: 'B', type: toast.TYPE.ERROR })
            }
          }).catch();
        } else {
          toast('Title already exists.', { containerId: 'B', type: toast.TYPE.ERROR })
        }
      }).catch();
    });
  }

  render() {
    const { formData, submitted } = this.state;

    return (
      <Container>
        <Row>
          <Col md={12}>
            <h3 className="page-title">Edit Tracker Device</h3>
          </Col>
        </Row>
        <Row>
          <Card>
            <CardBody>
              <div className="card__title">
              </div>
              <div>
                <ToastContainer enableMultiContainer containerId={'A'} position={toast.POSITION.BOTTOM_LEFT} />
                <ToastContainer enableMultiContainer containerId={'B'} position={toast.POSITION.TOP_RIGHT} />
              </div>
              <ValidatorForm
                className="material-form" id="pform"
                onSubmit={this.handleSubmit}
              >
                <Row>
                  <Col md={6} lg={6}>
                    <h6>Name</h6>
                    <TextValidator
                      onChange={this.handleChange}
                      name="device_name"
                      // label="Name"
                      value={formData.device_name}
                      validators={['required']}
                      errorMessages={['this field is required']}
                      className="material-form__field"
                    />
                    <div>
                    </div>
                  </Col>
                </Row>
                <Row>
                  <Col md={6} lg={6}>
                    <h6>IMEI</h6>
                    <TextValidator
                      onChange={this.handleChange}
                      name="device_imei"
                      // label="Name"
                      value={formData.device_imei}
                      validators={['required']}
                      errorMessages={['this field is required']}
                      className="material-form__field"
                    />
                    <div>
                    </div>
                  </Col>
                </Row>
                <br />
                <Row>
                  <Col md={6} lg={6}>
                    <br />
                    <h6>Created Date/Time</h6>
                    {formData.created_at}
                    <div>
                    </div>
                  </Col>
                </Row>
                <br />
                <Button
                  color="primary"
                  variant="contained"
                  type="submit"
                  disabled={submitted}
                >
                  {(submitted && 'Your form is submitted!') || (!submitted && 'Submit')}
                </Button>
                <Link className="btn btn-secondary" to="/forms/tracker_device">Cancel</Link>

              </ValidatorForm>
            </CardBody>
          </Card>
        </Row>
      </Container>
    )
  }
}

export default reduxForm({
  form: 'floating_labels_form', // a unique identifier for this form
})(withTranslation('common')(Tracker_Device_edit));
