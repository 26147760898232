import React, { Component } from 'react';
//import { MDBContainer, MDBAutocomplete } from "mdbreact";
import { Col, Container, Row, Button, Card, CardBody, ButtonToolbar, Table } from 'reactstrap';
import { withTranslation } from 'react-i18next';
import TextField from '@material-ui/core/TextField';
import MenuItem from '@material-ui/core/MenuItem';
import PropTypes from 'prop-types';
import AnimatedLineFormWithLabels from './components/AnimatedLineFormWithLabels';
import showResults from '../Show';
import * as myConstClass from '../../../constant.js';
import $ from 'jquery';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Field, reduxForm } from 'redux-form';
import { ValidatorForm, TextValidator } from 'react-material-ui-form-validator';
import renderRadioButtonField from '../../../shared/components/form/RadioButton';
import { DatePicker } from "material-ui-pickers";
import DateFnsUtils from '@date-io/date-fns';
import { MuiPickersUtilsProvider } from 'material-ui-pickers';
import { makeStyles } from '@material-ui/core/styles';
import InputLabel from '@material-ui/core/InputLabel';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import AutoComplete from 'material-ui/AutoComplete';
import MuiThemeProvider from 'material-ui/styles/MuiThemeProvider';
import { Link } from 'react-router-dom';
import '../../../antd.css';
import { Image } from 'antd';

const states = [
  "Alabama",
  "Alaska",
  "Arizona",
  "Arkansas",
  "California",
  "Colorado",
  "Connecticut",
  "Delaware",
  "Florida",
  "Georgia",
  "Hawaii",
  "Idaho",
  "Illnois",
  "Indiana",
  "Iowa",
  "Kansas",
  "Kentucky",
  "Louisiana",
  "Maine",
  "Maryland",
  "Massachusetts",
  "Michigan",
  "Minnesota",
  "Mississippi",
  "Missouri",
  "Montana",
  "Nebraska",
  "Nevada",
  "New Hampshire",
  "New Jersey",
  "New Mexico",
  "New York",
  "North Carolina",
  "North Dakota",
  "Ohio",
  "Oklahoma",
  "Oregon",
  "Pennsylvania",
  "Rhode Island",
  "South Carolina",
  "South Dakota",
  "Tennessee",
  "Texas",
  "Utah",
  "Vermont",
  "Virginia",
  "Washington",
  "West Virginia",
  "Wisconsin",
  "Wyoming"
];
const profile_image = `${process.env.PUBLIC_URL}/img/profile_image.png`;

const renderTextField = ({
  input, label, meta: { touched, error }, children, select,
}) => (
  <TextField
    className="material-form__field"
    label={label}
    error={touched && error}
    value={input.value}
    name={input.name}
    children={children}
    select={select}
    onChange={(e) => {
      e.preventDefault();
      input.onChange(e.target.value);
    }}
  />
);

renderTextField.propTypes = {
  input: PropTypes.shape().isRequired,
  label: PropTypes.string.isRequired,
  meta: PropTypes.shape({
    touched: PropTypes.bool,
    error: PropTypes.string,
  }),
  select: PropTypes.bool,
  children: PropTypes.arrayOf(PropTypes.element),
};

renderTextField.defaultProps = {
  meta: null,
  select: false,
  children: [],
};

// const useStyles = makeStyles(theme => ({
//   root: {
//     display: 'flex',
//     flexWrap: 'wrap',
//   },
//   formControl: {
//     margin: theme.spacing(1),
//     minWidth: 120,
//   },
//   selectEmpty: {
//     marginTop: theme.spacing(2),
//   },
// }));
// const classes = useStyles();
// const [values, setValues] = React.useState({
//   age: '',
//   name: 'hai',
// });
// const handleChange = event => {
//   setValues(oldValues => ({
//     ...oldValues,
//     [event.target.name]: event.target.value,
//   }));
// };

class FloatingLabelClass extends Component {

  constructor(props) {
    super(props);
    this.state = {
      date: Date.now(),
      user_id: '',
      posts: [],
      branch: [],
      codes: [],
      formData: {
        sapcode: '',
        firstname: '',
        middlename: '',
        lastname: '',
        email: '',
        mobile_no: '',
        designation: '',
        department: '',
        branch_name: '',
        vertical: '',
        blood_group: '',
        gender: '',
        mpin: '',
        date_of_birth: Date.now(),
        age: '',
        reporting_manager_sap_code: '',
        reporting_manager_name: '',
        reviewing_manager_sap_code: '',
        reviewing_manager_name: '',
        ho_poc_sap_code: '',
        ho_poc_name: '',
        department_head_sap_code: '',
        department_head_name: '',
        status: '',
        city: '',
        admin_select: '',
        plan_id: '',
      },
      submitted: false,
      session_tenant_id: '',
      AdminData: [],
      plan: [],
      user_tracker_device: [],
    }
    //this.handleSubmit = this.handleSubmit.bind(this);
  }
  componentDidMount() {

    const answer_array = this.props.location.pathname.split('/');
    if (answer_array['3']) {

      this.state.user_id = answer_array['3'];
      fetch(myConstClass.BASE_URL + '/user/getUpdateUserInfo/' + answer_array['3'])
        .then(response => response.json())
        .then(response => {
          let i = 1;
          response.user_tracker_device.forEach(element => {
            element.sr_no = i;
            i++;
          });
          this.setState({ formData: response, user_tracker_device: response.user_tracker_device })
        }).catch(err => console.log(err))

    } else {
      this.state.user_id = '';
    }

    fetch(myConstClass.BASE_URL + '/user/getBranch/')
      .then(response => response.json())
      .then(response => { this.setState({ branch: response }) }).catch(err => console.log(err))

    fetch(myConstClass.BASE_URL + '/user/getAllSAPCode/')
      .then(response => response.json())
      .then(response => { this.setState({ codes: response }) }).catch(err => console.log(err))

    if (!localStorage.getItem('session_id')) {
      console.log('History:', this.props);
      this.props.history.push('/log_in');
    }

    if (localStorage.getItem('uid')) {
      if (localStorage.getItem('uid') != 1) {
        this.setState({ session_tenant_id: localStorage.getItem('uid') })
      }
    }
    fetch(myConstClass.BASE_URL + '/user/getAdmin')
      .then(response => response.json())
      .then(response => {
        let i = 1;
        response.forEach(element => {
          i++;
        });
        this.setState({ AdminData: response })
      })
      .catch(err => console.log(err))

    fetch(myConstClass.BASE_URL + '/user/getPlans')
      .then(response => response.json())
      .then(response => { this.setState({ plan: response }) }).catch(err => console.log(err))
  }
  handleChange = (event) => {
    const { formData } = this.state;
    formData[event.target.name] = event.target.value;
    this.setState({ formData });
  }

  handleUpdateInput = (searchText) => {
    const { formData } = this.state;
    formData['reporting_manager_sap_code'] = searchText;
    this.setState({ formData });
  };

  handleNewRequest = (searchText) => {
    const { formData } = this.state;
    formData['reporting_manager_sap_code'] = searchText;
    this.setState({ formData });
  };


  handleNewRequest1 = (searchText) => {
    const { formData } = this.state;
    formData['reviewing_manager_sap_code'] = searchText;
    this.setState({ formData });
  };

  handleUpdateInput1 = (searchText) => {
    const { formData } = this.state;
    formData['reviewing_manager_sap_code'] = searchText;
    this.setState({ formData });
  };

  handleNewRequest2 = (searchText) => {
    const { formData } = this.state;
    formData['ho_poc_sap_code'] = searchText;
    this.setState({ formData });
  };

  handleUpdateInput2 = (searchText) => {
    const { formData } = this.state;
    formData['ho_poc_sap_code'] = searchText;
    this.setState({ formData });
  };

  handleNewRequest3 = (searchText) => {
    const { formData } = this.state;
    formData['department_head_sap_code'] = searchText;
    this.setState({ formData });
  };
  handleUpdateInput3 = (searchText) => {
    const { formData } = this.state;
    formData['department_head_sap_code'] = searchText;
    this.setState({ formData });
  };

  handleDateChange = (date) => {
    const { formData } = this.state;
    formData['date_of_birth'] = date;
    var ageDifMs = Date.now() - date.getTime();
    var ageDate = new Date(ageDifMs); // miliseconds from epoch
    var age = Math.abs(ageDate.getUTCFullYear() - 1970);//alert(age);
    formData['age'] = age;
    this.setState({ formData });
    //this.setState({ date });
  };

  handleSubmit = () => {
    this.setState({ submitted: true }, () => {
      setTimeout(() => this.setState({ submitted: false }), 5000);
      fetch(myConstClass.BASE_URL + `/user/add/` + this.state.user_id, {
        method: 'POST',
        body: JSON.stringify($('#pform').serializeArray()),
      }).then(response => response.json()).then((response) => {
        if (response == true) {
          toast('User Added Successfully..!', { containerId: 'B', type: toast.TYPE.SUCCESS })
          this.props.history.push('/forms/users/');
        } else {
          toast('User Insertion Failed..!', { containerId: 'B', type: toast.TYPE.ERROR })
        }
      })
        .catch();
    });
  }

  handleAdminChange = (event) => {
    this.setState({ admin_select: event.target.value });
    const { formData } = this.state;
    formData['tenant_id'] = event.target.value;
    this.setState({ formData });
  }

  render() {
    const { formData, submitted, branch, codes, user_tracker_device } = this.state;
    const { posts } = this.state;

    return (
      <Container>
        <Row>
          <Col md={12}>
            {/* {this.state.user_id ? <h3 className="page-title">Edit User</h3> : <h3 className="page-title">Add User</h3>} */}
            {this.state.user_id ? <span><h3 className="page-title" style={{ "display": "inline" }}>Edit User</h3>

              {/* <Link to={`/forms/user_device/${this.state.user_id}`} style={{float:'right'}}> <Button
                    color="primary"
                    variant="contained"
                    type="button"
                   
                >User Device</Button>
                </Link> */}
            </span> : <h3 className="page-title">Add User</h3>}

            {/* <h3 className="page-subhead subhead">Use this elements, if you want to show some hints or additional
              information
        </h3> */}
          </Col>
        </Row>
        <Row>

          <Card>
            <CardBody>
              {/* <div className="card__title"> */}
              {/* <h5 className="bold-text">{t('forms.floating_labels_form.animated_line_inputs')}</h5>
           */}
              {/* <h5 className="bold-text">Add Admin/Employee {posts.sap_code}</h5> */}
              {/* <h5 className="subhead">Material fields with a property <span className="red-text">label</span></h5> */}
              {/* </div> */}
              <div>
                <ToastContainer enableMultiContainer containerId={'A'} position={toast.POSITION.BOTTOM_LEFT} />
                <ToastContainer enableMultiContainer containerId={'B'} position={toast.POSITION.TOP_RIGHT} />
              </div>

              <ValidatorForm
                className="material-form" id="pform"
                onSubmit={this.handleSubmit}
              >

                <Row>
                  <Col md={2} lg={2} >
                    <TextValidator
                      onChange={this.handleChange}
                      name="firstname"
                      placeholder="First name"
                      label="First name"
                      className="material-form__field"
                      value={formData.firstname}
                      validators={['required']}
                      errorMessages={['this field is required']}
                    />
                  </Col>
                  <Col md={2} lg={2}>
                    <TextValidator
                      onChange={this.handleChange}
                      className="material-form__field"
                      name="middlename"
                      placeholder="Middle name"
                      label="Middle name"
                      value={formData.middlename}
                    />
                  </Col>
                  <Col md={2} lg={2}>
                    <TextValidator
                      onChange={this.handleChange}
                      className="material-form__field"
                      name="lastname"
                      placeholder="Last name"
                      label="Last name"
                      value={formData.lastname}
                      validators={['required']}
                      errorMessages={['this field is required']}
                    /></Col>

                  <Col md={2} lg={2}>
                    {formData.profile_image_thumb == null ?
                      <Image.PreviewGroup>
                        <span>
                          <Image
                            // width={100}
                            height={100}
                            alt="Profile image" title='Profile image'
                            src={profile_image}
                          /></span>
                      </Image.PreviewGroup>
                      : <Image.PreviewGroup>
                        <span>
                          <Image
                            // width={100}
                            height={100}
                            alt="Profile image" title='Profile image'
                            src={formData.profile_image_thumb}
                          /></span>
                      </Image.PreviewGroup>}
                  </Col>
                  <Col md={2} lg={2}></Col>
                  <Col md={2} lg={2}></Col>
                </Row>

                <Row>
                  <Col md={2} lg={2}>
                    <span className='text_light_b'>Tenant Name:</span>
                  </Col>
                  <Col md={4} lg={4}>
                    <div>
                      {this.state.session_tenant_id == "" ?
                        <div>
                          <FormControl className="material-form__field ">
                            <Select className='fs_14' onChange={this.handleAdminChange} inputProps={{ name: 'admin_select', id: 'admin_select' }} value={formData.tenant_id} >
                              {this.state.AdminData.map((number) =>
                                <MenuItem value={number.uid}>{number.first_name}</MenuItem>
                              )}
                            </Select>
                          </FormControl>
                        </div>
                        : ""}
                    </div>
                  </Col>
                </Row>

                <Row>
                  <Col md={3} lg={3}>
                    {formData.licence_key != "" ?
                      <div>
                        <FormControl className="material-form__field">
                          <span className='text_light'><b>Licence Key - </b>{formData.licence_key}</span>
                        </FormControl>
                      </div>
                      : <FormControl className="material-form__field"><span className='text_light_b'>Licence not available</span></FormControl>}
                  </Col>
                  <Col md={3} lg={3}>
                    {formData.plan_id != "" ?
                      <div className='text_right '>
                        <FormControl className="material-form__field">
                          {formData.plan_title != "" ?
                            <span>
                              <span className='text_light_b'>Plan Id - </span><span>{formData.plan_id}</span>
                              &nbsp; &nbsp;&nbsp;
                              <span className='text_light_b'>Plan Name - </span> {formData.plan_title}</span>
                            : ""}
                        </FormControl>
                      </div>
                      : <FormControl className="material-form__field text_right"><span className='text_light_b'>Plan not available</span></FormControl>}
                  </Col>
                  <Col md={4} lg={4}>
                    {formData.plan_id != "" ?
                      <div className='  '>
                        <FormControl className="material-form__field">
                          {formData.plan_title != "" ?
                            <span>
                              <span className='text_light_b'>Start Date - </span><span>{formData.start_date}</span>
                              &nbsp; &nbsp;&nbsp;
                              <span className='text_light_b'>End Date - </span> {formData.end_date}</span>
                            : ""}
                        </FormControl>
                      </div>
                      : <FormControl className="material-form__field text_right"><span className='text_light_b'>Plan not available</span></FormControl>}
                  </Col>
                </Row>

                <Row>
                  <Col md={6} lg={6}>
                    <Table className="table--bordered table--head-accent"  >
                      <thead>
                        <tr>
                          <th>#</th>
                          <th>Feature</th>
                          <th>Remaining count</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>1</td>
                          <td>SOS remaining count </td>
                          <td>{formData.sos_remaining_count}</td>
                        </tr>
                        <tr>
                          <td>2</td>
                          <td>Follow Me remaining count </td>
                          <td>{formData.follow_me_remaining_count}</td>
                        </tr>
                        <tr>
                          <td>3</td>
                          <td>POSH remaining count </td>
                          <td>{formData.posh_remaining_count}</td>
                        </tr>
                      </tbody>
                    </Table>
                  </Col>
                  <Col md={6} lg={6}>
                    <Table className="table--bordered table--head-accent"  >
                      <thead>
                        <tr>
                          <th>#</th>
                          <th>Feature</th>
                          <th>Remaining count</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>4</td>
                          <td>Ambulance remaining count </td>
                          <td>{formData.ambulance_remaining_count}</td>
                        </tr>
                        <tr>
                          <td>5</td>
                          <td>Road side assistance remaining count </td>
                          <td>{formData.road_side_assistance_remaining_count}</td>
                        </tr>
                        <tr>
                          <td>6</td>
                          <td>Accidental insurance remaining count </td>
                          <td>{formData.accidental_insurance_remaining_count}</td>
                        </tr>
                      </tbody>
                    </Table>
                  </Col>
                </Row>
                <br />

                {this.state.user_tracker_device != "" ?
                  <Row>
                    <Col md={12} lg={12}>
                      <Table className="table--bordered table--head-accent"  >
                        <thead>
                          <tr>
                            <th>#</th>
                            <th>Name</th>
                            <th>Device Name</th>
                            <th>Device EMEI</th>
                            <th>Created</th>
                          </tr>
                        </thead>
                        <tbody>
                          {this.state.user_tracker_device.map((element) =>
                            <tr>
                              <td>{element.sr_no}</td>
                              <td>{element.device_name}</td>
                              <td>{element.tracker_device_name}</td>
                              <td>{element.device_imei}</td>
                              <td>{element.created_at}</td>
                            </tr>
                          )}
                        </tbody>
                      </Table>
                    </Col>
                    <br />
                    <br />
                  </Row>
                  : ''}

                <Row>
                  <Col md={12} lg={12}>
                    {formData.device_info != null ?
                      <div>
                        <FormControl className="material-form__field">
                          <span className='text_light'><b>Device info - </b>{formData.device_info}</span>
                        </FormControl>
                      </div>
                      : ""}
                  </Col>
                </Row>
                <br />
                <Row>

                  <Col md={6} lg={6}>
                    <TextValidator
                      onChange={this.handleChange}
                      className="material-form__field"
                      name="email"
                      placeholder="Email"
                      label="Email"
                      value={formData.email}
                      validators={['required', 'isEmail']}
                      errorMessages={['this field is required', 'Enter valid email address']}
                    />

                    <TextValidator
                      onChange={this.handleChange}
                      className="material-form__field"
                      name="mobile_no"
                      placeholder="Mobile No"
                      label="Mobile No"
                      value={formData.mobile_no}
                      validators={['required', 'matchRegexp:^[0-9]{10}$']}
                      errorMessages={['this field is required', 'Enter 10 digit Number..']}
                    />

                    <TextValidator
                      onChange={this.handleChange}
                      className="material-form__field"
                      name="city"
                      placeholder="Address"
                      label="Address"
                      value={formData.city}
                    />
                    <div>
                      <FormControl className="material-form__field">
                        <InputLabel htmlFor="age-simple">Status</InputLabel>
                        <Select
                          value={formData.status}
                          onChange={this.handleChange}
                          inputProps={{
                            name: 'status',
                            id: 'status',
                          }}
                        >
                          <MenuItem value={1}>Active</MenuItem>
                          <MenuItem value={2}>Inactive</MenuItem>
                          <MenuItem value={3}>Suspended</MenuItem>
                        </Select>
                      </FormControl>
                    </div>
                    {/* <FormControl  className="material-form__field">
        <InputLabel htmlFor="blood_group">Branch Name</InputLabel>
        <Select
          value={formData.branch_name}
          onChange={this.handleChange}
          inputProps={{
            name: 'branch_name',
            id: 'branch_name',
          }}
        >
           {branch.map((cell, i) => {
                 return(<MenuItem value={cell.id}>{cell.name}</MenuItem>)
            })}

        </Select>
      </FormControl> */}

                    {/* <TextValidator
            onChange={this.handleChange}
            className="material-form__field"
              name="branch_name"
              placeholder="Branch Name"
              label="Branch Name"
              value={formData.branch_name}
             validators={['required']}
             errorMessages={['this field is required']}
            />
             <br/> */}


                  </Col>

                  <Col md={6} lg={6}>
                    {/* <TextValidator
            onChange={this.handleChange}
            className="material-form__field"
              name="designation"
              placeholder="Designation"
              label="Designation"
              value={formData.designation}
             validators={['required']}
             errorMessages={['this field is required']}
            />
          <br/>
            <TextValidator
            onChange={this.handleChange}
            className="material-form__field"
              name="department"
              placeholder="Department"
              label="Department"
              value={formData.department}
             validators={['required']}
             errorMessages={['this field is required']}
            /> */}

                    {/* <TextValidator
            onChange={this.handleChange}
            className="material-form__field"
              name="vertical"
              placeholder="Vertical"
              label="Vertical"
              value={formData.vertical}
             validators={['required']}
             errorMessages={['this field is required']}
            /> */}

                    <div>
                      <FormControl className="material-form__field">
                        <InputLabel htmlFor="blood_group">Blood Group</InputLabel>
                        <Select
                          value={formData.blood_group}
                          onChange={this.handleChange}
                          inputProps={{
                            name: 'blood_group',
                            id: 'blood_group',
                          }}
                        >
                          <MenuItem value={1}>A+</MenuItem>
                          <MenuItem value={2}>B+</MenuItem>
                          <MenuItem value={3}>O+</MenuItem>
                          <MenuItem value={4}>AB+</MenuItem>
                          <MenuItem value={5}>A-</MenuItem>
                          <MenuItem value={6}>B-</MenuItem>
                          <MenuItem value={7}>O-</MenuItem>
                          <MenuItem value={8}>AB-</MenuItem>
                        </Select>
                      </FormControl>

                    </div>
                    <div>
                      <FormControl className="material-form__field">
                        <InputLabel htmlFor="gender">Gender</InputLabel>
                        <Select
                          value={formData.gender}
                          onChange={this.handleChange}
                          inputProps={{
                            name: 'gender',
                            id: 'gender',
                          }}
                        >
                          <MenuItem value={1}>Male</MenuItem>
                          <MenuItem value={2}>Female</MenuItem>
                          <MenuItem value={3}>Others</MenuItem>
                        </Select>
                      </FormControl>
                    </div>
                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                      <DatePicker
                        className="material-form__field"
                        onChange={this.handleDateChange}
                        name="date_of_birth"
                        placeholder="Date of Birth"
                        label="Date of Birth"
                        value={formData.date_of_birth}
                        format="dd-MM-yyyy"
                      />
                    </MuiPickersUtilsProvider>

                    <TextValidator
                      onChange={this.handleChange}
                      className="material-form__field"
                      name="age"
                      placeholder="Age"
                      label="Age"
                      value={formData.age}
                      validators={['required', 'matchRegexp:^[0-9]{1,3}$']}
                      errorMessages={['this field is required', 'Enter maximum 3 digit Number..']}
                    />


                    {/* <TextValidator
            onChange={this.handleChange}
            className="material-form__field"
              name="reporting_manager_name"
              placeholder="Reporting Manager Name"
              label="Reporting Manager Name"
              value={formData.reporting_manager_name}
             validators={['required']}
             errorMessages={['this field is required']}
            />
          <br/> */}


                    {/* <TextValidator
            onChange={this.handleChange}
            className="material-form__field"
              name="reviewing_manager_name"
              placeholder="Reviewing Manager Name"
              label="Reviewing Manager Name"
              value={formData.reviewing_manager_name}
             validators={['required']}
             errorMessages={['this field is required']}
            />
          <br/> */}

                    {/* <TextValidator
            onChange={this.handleChange}
            className="material-form__field"
              name="ho_poc_name"
              placeholder="HO POC Name"
              label="HO POC Name"
              value={formData.ho_poc_name}
              validators={['required']}
             errorMessages={['this field is required']}
            />
          <br/> */}

                    {/* <TextValidator
            onChange={this.handleChange}
            className="material-form__field"
              name="department_head_name"
              placeholder="Department Head Name"
              label="Department Head Name"
              value={formData.department_head_name}
             validators={['required']}
             errorMessages={['this field is required']}
            /> */}

                    {/* <TextValidator
            onChange={this.handleChange}
            className="material-form__field"
              name="mpin"
              placeholder="MPin"
              label="MPin"
              value={formData.mpin}
             validators={['required']}
             errorMessages={['this field is required']}
            /> */}

                  </Col>
                </Row>

                <Row>
                  <Col md={6} lg={6}>
                    <label>Emergency Number 1</label>
                    <MuiThemeProvider >

                      <AutoComplete
                        searchText={formData.reporting_manager_sap_code}
                        onUpdateInput={this.handleUpdateInput}
                        onNewRequest={this.handleNewRequest}
                        fullWidth={true}
                        hintText="Emergency Contact Number"
                        name="reporting_manager_sap_code"

                        dataSource={codes}

                      />
                    </MuiThemeProvider>
                  </Col>
                  <Col md={6} lg={6}>
                    <label>Emergency Number 2</label>
                    <MuiThemeProvider >
                      <AutoComplete
                        searchText={formData.reviewing_manager_sap_code}
                        onUpdateInput={this.handleUpdateInput1}
                        onNewRequest={this.handleNewRequest1}
                        fullWidth={true}
                        hintText="Emergency Contact Number"
                        name="reviewing_manager_sap_code"

                        dataSource={codes}
                      />
                    </MuiThemeProvider>
                  </Col>
                </Row>
                <br />
                <Row>

                  <Col md={6} lg={6}>

                    <label>Emergency Number 3</label>
                    <MuiThemeProvider >

                      <AutoComplete
                        searchText={formData.ho_poc_sap_code}
                        onUpdateInput={this.handleUpdateInput2}
                        onNewRequest={this.handleNewRequest2}
                        fullWidth={true}
                        hintText="Emergency Contact Number"
                        name="ho_poc_sap_code"

                        dataSource={codes}
                      />
                    </MuiThemeProvider>
                  </Col>
                  <Col md={6} lg={6}>

                    <label>Emergency Number 4</label>
                    <MuiThemeProvider >
                      <AutoComplete
                        searchText={formData.department_head_sap_code}
                        onUpdateInput={this.handleUpdateInput3}
                        onNewRequest={this.handleNewRequest3}
                        fullWidth={true}
                        hintText="Emergency Contact Number"
                        name="department_head_sap_code"
                        dataSource={codes}
                      />
                    </MuiThemeProvider>
                  </Col>
                </Row>
                <br />
                <Button
                  color="primary"
                  variant="contained"
                  type="submit"
                  disabled={submitted}
                >
                  {
                    (submitted && 'Your form is submitted!')
                    || (!submitted && 'Submit')
                  }
                </Button>

                <Link className="btn btn-secondary" to="/forms/users">Cancel</Link>
              </ValidatorForm>
            </CardBody>
          </Card>

          {/* <AnimatedLineFormWithLabels onSubmit={showResults} posts={posts}/> */}
        </Row>
      </Container>
    )
  }
}

export default reduxForm({
  form: 'floating_labels_form', // a unique identifier for this form
})(withTranslation('common')(FloatingLabelClass));
