import React, { Component } from 'react';
import { Card, CardBody, Col, Container, Row, Button } from 'reactstrap';
import { withTranslation } from 'react-i18next';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import moment from 'moment';
import * as myConstClass from '../../../constant.js';
import 'font-awesome/css/font-awesome.min.css';
import BootstrapTable from "react-bootstrap-table-next";
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";
import paginationFactory from "react-bootstrap-table2-paginator";

const { SearchBar } = Search;
const tableColumns = [
  {
    dataField: "sr_no",
    text: "#",
    sort: true,
    headerStyle: { width: "5%" }
  },
  {
    dataField: "ref_no",
    text: "Ref no",
    sort: true,
    headerStyle: { width: "10%" }
  },
  {
    dataField: "otp",
    text: "OTP",
    sort: true,
    headerStyle: { width: "10%" }
  },
  {
    dataField: "purpose_title",
    text: "Purpose",
    sort: true,
    headerStyle: { width: "10%" }
  },
  {
    dataField: "active_platform",
    text: "Active Platform",
    sort: true,
    headerStyle: { width: "10%" }
  },
  {
    dataField: "created_on",
    text: "Date/Time",
    sort: true,
    headerStyle: { width: "10%" }
  },
];

class User_otp_list extends Component {
  constructor(props) {
    super(props);
    this.state = {
      formData: [],
      first_name: '',
      last_name: '',
      mobile_no: '',
      user_id: '',
    }
    this.flag = 0;
    this.customLabels = {
      first: '<<',
      last: '>>',
      prev: '<',
      next: '>',
      show: 'Display',
      entries: 'rows',
      noResults: 'There is no data to be displayed',
    };
  }

  onSortFunction = {
    date(columnValue) {
      // Convert the string date format to UTC timestamp
      // So the table could sort it by number instead of by string
      return moment(columnValue, 'Do MMMM YYYY').valueOf();
    },
  };

  componentDidMount() {
    const answer_array = this.props.location.pathname.split('/');
    if (answer_array['3']) {
      var id = answer_array['3'];
      var user_id = answer_array['4'];
      fetch(myConstClass.BASE_URL + '/user/getUserOtp/' + id + '/' + user_id)
        .then(response => response.json())
        .then(response => {
          let key = 1;
          response.result.forEach(element => {
            element.sr_no = key;
            var purpose_title = "";
            if (element.purpose == "1") {
              purpose_title = "Login";
            }
            if (element.purpose == "2") {
              purpose_title = "OTP";
            }
            if (element.purpose == "3") {
              purpose_title = "Mpin";
            }
            if (element.purpose == "4") {
              purpose_title = "QR Code";
            }
            element.purpose_title = purpose_title;
            key++;
          });
          this.setState({ first_name: response.first_name })
          this.setState({ last_name: response.last_name })
          this.setState({ mobile_no: response.mobile_no })
          this.setState({ user_id: response.user_id })
          this.setState({ formData: response.result })
        })
        .catch(err => console.log(err))
    }
    if (!localStorage.getItem('session_id')) {
      console.log('History:', this.props);
      this.props.history.push('/log_in');
    }
  }

  render() {
    const { formData, first_name, last_name, mobile_no, user_id } = this.state;
    return (
      <Container>
        <Row>
          <Col md={12}>
            <h3 className="page-title">User OTP</h3>
          </Col>
          <ToastContainer enableMultiContainer containerId={'A'} position={toast.POSITION.BOTTOM_LEFT} />
          <ToastContainer enableMultiContainer containerId={'B'} position={toast.POSITION.TOP_RIGHT} />
        </Row>
        <Row>
          <Col md={12} lg={12}>
            <Card>
              <ToolkitProvider
                keyField="name"
                data={this.state.formData}
                columns={tableColumns}
                exportCSV
                search
              >
                {props => (
                  <div>
                    <CardBody>
                      <div><span className='text_light_b'>User Id - </span>{user_id}<span className='text_light_b ml-15'>User Name - </span>{first_name} {last_name}<span className='text_light_b ml-15'>Mobile No - </span> {mobile_no}</div>
                      <br />
                      <SearchBar {...props.searchProps} />
                      <BootstrapTable
                        {...props.baseProps}
                        bootstrap4
                        bordered={false}
                        pagination={paginationFactory({
                          // sizePerPage: 25,
                          sizePerPage: 10,
                          sizePerPageList: [5, 10, 25, 50]
                        })}
                      />
                    </CardBody>
                  </div>
                )}
              </ToolkitProvider>
            </Card>
          </Col>
        </Row>
      </Container>)
  }
}
export default withTranslation('common')(User_otp_list);
