import React, { Component } from 'react';
import { Card, CardBody, CardTitle, CardSubtitle, CardText, Col, Container, Row, Button } from 'reactstrap';
import { withTranslation } from 'react-i18next';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import * as myConstClass from '../../../constant.js';
import 'font-awesome/css/font-awesome.min.css';
import BootstrapTable from "react-bootstrap-table-next";
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";
import paginationFactory from "react-bootstrap-table2-paginator";
import { ButtonToolbar, Modal } from 'reactstrap';

const { SearchBar } = Search;
const tableColumns = [
  {
    dataField: "sr_no",
    text: "#",
    sort: true,
    headerStyle: { width: "10%" }
  },
  {
    dataField: "title",
    text: "Title",
    sort: true,
    headerStyle: { width: "20%" }
  },
  {
    dataField: "redirection_url",
    text: "Redirection url",
    sort: true,
    headerStyle: { width: "20%" }
  },
  {
    dataField: "created_at",
    text: "Date/Time",
    sort: true,
    headerStyle: { width: "10%" }
  },
  {
    dataField: "action",
    text: "Action",
    headerStyle: { width: "10%" },
    csvExport: false
  },
];

class Banner extends Component {
  constructor(props) {
    super(props);
    this.state = {
      formData: [],
      delete_modal: false,
      delete_id: '',
    }
    this.toggleClose = this.toggleClose.bind(this);
    this.customLabels = {
      first: '<<',
      last: '>>',
      prev: '<',
      next: '>',
      show: 'Display',
      entries: 'rows',
      noResults: 'There is no data to be displayed',
    };
  }

  view(e) {
    this.props.history.push('/forms/banner_view/' + e);
  }

  add_banner() {
    this.props.history.push('/forms/add_banner');
  }

  edit(e) {
    this.props.history.push('/forms/edit_banner/' + e);
  }

  remove(e) {
    this.setState({ delete_modal: false });
    this.setState({ delete_id: '' });

    var tid = 1;
    if (localStorage.getItem('uid')) {
      tid = localStorage.getItem('uid');
    }

    fetch(myConstClass.BASE_URL + `/banner/deleteBanner`, {
      method: 'POST',
      body: e,
    }).then(response => response.json()).then((response) => {
      if (response == true) {
        toast('Banner Deleted Successfully..!', { containerId: 'B', type: toast.TYPE.SUCCESS })
        fetch(myConstClass.BASE_URL + '/banner/getBanner/' + tid)
          .then(response => response.json())
          .then(response => {
            response.forEach(element => {
              let id = element.id;
              element.action = <div><center><i className="fa fa-remove ptr_css" onClick={() => this.deleteToggleModel(id)}></i>&nbsp;&nbsp;<i id={id} className="fa fa-edit ptr_css" onClick={() => this.edit(id)}></i></center></div>;
            });
            this.setState({ formData: response })
          }).catch(err => console.log(err))
      } else {
        toast('Failed to delete.', { containerId: 'B', type: toast.TYPE.ERROR })
      }
    }).catch();
  }

  componentDidMount() {
    var tid = 1;
    if (localStorage.getItem('uid')) {
      tid = localStorage.getItem('uid');
    }
    fetch(myConstClass.BASE_URL + '/Banner/getBanner/' + tid)
      .then(response => response.json())
      .then(response => {
        response.forEach(element => {
          var id = element.id;
          element.action = <div><center><i className="fa fa-remove ptr_css" onClick={() => this.deleteToggleModel(id)}></i>&nbsp;&nbsp;<i id={id} className="fa fa-edit ptr_css" onClick={() => this.edit(id)}></i></center></div>;
        });
        this.setState({ formData: response })
      }).catch(err => console.log(err))
    if (!localStorage.getItem('session_id')) {
      this.props.history.push('/log_in');
    }
  }

  deleteToggleModel(id) {
    this.setState({ delete_modal: true });
    this.setState({ delete_id: id });
  }

  toggleClose() {
    this.setState({ delete_modal: false });
    this.setState({ delete_id: '' });
  }

  render() {
    const { delete_id } = this.state;
    return (
      <Container>
        <Row>
          <Col md={12}>
            <Modal style={{ position: 'relative', margin: '0 auto', top: '28%', width: '385px' }}
              isOpen={this.state.delete_modal}
              modalClassName={`ltr-support`}
              className={`modal-dialog--primary modal-dialog--header`} >
              <div className="modal__header"  >
                <button className="lnr lnr-cross modal__close-btn" type="button" onClick={this.toggleClose} style={{ marginBottom: '5px' }} />
                <h4 className="text-modal  modal__title" style={{ color: '#fff' }}>{"Banner delete confirmation"}</h4>
              </div>
              <div className="modal__body">
                <div className="form__form-group">
                  <div className="form__form-group-field">
                    <span className="form__form-group-label typography-message" >Are you sure you want to delete banner? </span>&nbsp;&nbsp;&nbsp;
                  </div>
                </div>
              </div>
              <ButtonToolbar className="modal__footer">
                <Button color="primary" type="submit" onClick={() => this.remove(delete_id)}  >YES</Button>
                <Button className="modal_cancel" onClick={this.toggleClose}>NO</Button>
              </ButtonToolbar>
            </Modal>

            <h3 className="page-title"> Banner</h3>
          </Col>
          <ToastContainer enableMultiContainer containerId={'A'} position={toast.POSITION.BOTTOM_LEFT} />
          <ToastContainer enableMultiContainer containerId={'B'} position={toast.POSITION.TOP_RIGHT} />
        </Row>
        <Row>
          <Col md={12} lg={12}>
            <Card>
              <ToolkitProvider
                keyField="name"
                data={this.state.formData}
                columns={tableColumns}
                exportCSV
                search
              >
                {props => (
                  <div>
                    <CardBody>
                      <Row></Row>
                      <Button color="primary" style={{ float: 'right' }} onClick={() => this.add_banner()} > Add Banner</Button>
                      <SearchBar {...props.searchProps} />
                      <BootstrapTable
                        {...props.baseProps}
                        bootstrap4
                        bordered={false}
                        pagination={paginationFactory({
                          sizePerPage: 10,
                          sizePerPageList: [5, 10, 25, 50]
                        })}
                      />
                    </CardBody>
                  </div>
                )}
              </ToolkitProvider>
            </Card>
          </Col>
        </Row>
      </Container>)
  }
}

export default withTranslation('common')(Banner);
