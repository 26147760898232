import React, { Component } from 'react';
import { Card, CardBody, Col, Container, Row, Button } from 'reactstrap';
import { Link } from 'react-router-dom';
import { withTranslation } from 'react-i18next';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import * as myConstClass from '../../../constant.js';
import 'font-awesome/css/font-awesome.min.css';
import BootstrapTable from "react-bootstrap-table-next";
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";
import paginationFactory from "react-bootstrap-table2-paginator";
import { CSVLink, CSVDownload } from "react-csv";

const { SearchBar } = Search;
const tableColumns = [
  {
    dataField: "sr_no",
    text: "#",
    sort: true,
    headerStyle: { width: "5%" }
  },
  {
    dataField: "id",
    text: "Plan Id",
    sort: true,
    headerStyle: { width: "10%" }
  },
  {
    dataField: "title",
    text: "Title",
    sort: true,
    headerStyle: { width: "20%" }
  },
  {
    dataField: "duration",
    text: "Duration",
    sort: true,
    headerStyle: { width: "10%" }
  },
  {
    dataField: "price",
    text: "Price",
    sort: true,
    headerStyle: { width: "10%" }
  },
  {
    dataField: "status",
    text: "Status",
    sort: true,
    headerStyle: { width: "5%" }
  },
  {
    dataField: "created_at",
    text: "Date/Time",
    sort: true,
    headerStyle: { width: "10%" }
  },
  {
    dataField: "is_changed",
    text: "Plan Changed",
    sort: true,
    headerStyle: { width: "10%" }
  },
  {
    dataField: "is_deleted",
    text: "Plan Deleted",
    sort: true,
    headerStyle: { width: "10%" }
  },
  {
    dataField: "action",
    text: "Action",
    sort: true,
    headerStyle: { width: "10%" }
  },
];
class Plan_log_list extends Component {
  constructor(props) {
    super(props);
    this.state = {
      formData: [],
    }
    this.customLabels = {
      first: '<<',
      last: '>>',
      prev: '<',
      next: '>',
      show: 'Display',
      entries: 'rows',
      noResults: 'There is no data to be displayed',
    };
  }

  componentDidMount() {
    var tenant_id = '';
    if (localStorage.getItem('uid')) {
      tenant_id = localStorage.getItem('uid');
    }
    fetch(myConstClass.BASE_URL + '/user/getAllPlans/' + tenant_id)
      .then(response => response.json())
      .then(response => {
        let key = 1;
        response.forEach(element => {
          var id = element.id;
          element.sr_no = key;
          element.status = element.status == '1' ? "Active" : "Inactive";
          element.is_changed = element.is_changed == '1' ? "Changed" : "";
          element.is_deleted = element.is_deleted == '1' ? "Deleted" : "";
          element.action = <div><i id={id} className="fa fa-eye ptr_css" onClick={() => this.view(id)}></i></div>;
          key++;
        });
        this.setState({ formData: response })
      })
      .catch(err => console.log(err))
    if (!localStorage.getItem('session_id')) {
      console.log('History:', this.props);
      this.props.history.push('/log_in');
    }
  }

  view(e) {
    this.props.history.push('/forms/view_plan/' + e);
  }

  render() {
    const csv_headers = [
      { label: "#", key: "id" },
      { label: "Title", key: "title" },
      { label: "Duration", key: "duration" },
      { label: "Status", key: "status" },
      { label: "Date/Time", key: "created_at" },
    ];
    return (
      <Container>
        <Row>
          <Col md={12}>
            <h3 className="page-title">Plan Log List
            </h3>
            <div style={{ float: 'right' }}>

              <Link to="/forms/plan_list" style={{ paddingRight: '10px' }}><Button
                color="primary"
                variant="contained"
                type="button"
              >Plan</Button>
              </Link>

              {/* {this.state.formData ?
                <CSVLink filename={"Plan.csv"}
                  className="btn btn-primary" style={{}} data={this.state.formData} headers={csv_headers}>Export</CSVLink>
                : ''} */}
            </div>
          </Col>
          <ToastContainer enableMultiContainer containerId={'A'} position={toast.POSITION.BOTTOM_LEFT} />
          <ToastContainer enableMultiContainer containerId={'B'} position={toast.POSITION.TOP_RIGHT} />
        </Row>
        <Row>
          <Col md={12} lg={12}>
            <Card>
              <ToolkitProvider
                keyField="name"
                data={this.state.formData}
                columns={tableColumns}
                exportCSV
                search
              >
                {props => (
                  <div>
                    <CardBody>
                      <SearchBar {...props.searchProps} />
                      <BootstrapTable
                        {...props.baseProps}
                        bootstrap4
                        bordered={false}
                        pagination={paginationFactory({
                          // sizePerPage: 25,
                          sizePerPage: 10,
                          sizePerPageList: [5, 10, 25, 50]
                        })}
                      />
                    </CardBody>
                  </div>
                )}
              </ToolkitProvider>
            </Card>
          </Col>
        </Row>
      </Container>)
  }
}

export default withTranslation('common')(Plan_log_list);
