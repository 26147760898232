import React, { Component } from 'react';
import { Col, Container, Row, Button, Card, CardBody, ButtonToolbar, } from 'reactstrap';
import { withTranslation } from 'react-i18next';
import TextField from '@material-ui/core/TextField';
import MenuItem from '@material-ui/core/MenuItem';
import PropTypes from 'prop-types';
import * as myConstClass from '../../../constant.js';
import $ from 'jquery';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Field, reduxForm } from 'redux-form';
import { ValidatorForm, TextValidator } from 'react-material-ui-form-validator';
import renderRadioButtonField from '../../../shared/components/form/RadioButton';
import { DatePicker } from "material-ui-pickers";
import DateFnsUtils from '@date-io/date-fns';
import { MuiPickersUtilsProvider } from 'material-ui-pickers';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import JoditEditor from '../../../components/jodit/JoditEditor'

const renderTextField = ({
  input, label, meta: { touched, error }, children, select,
}) => (
  <TextField
    className="material-form__field"
    label={label}
    error={touched && error}
    value={input.value}
    name={input.name}
    children={children}
    select={select}
    onChange={(e) => {
      e.preventDefault();
      input.onChange(e.target.value);
    }}
  />
);

renderTextField.propTypes = {
  input: PropTypes.shape().isRequired,
  label: PropTypes.string.isRequired,
  meta: PropTypes.shape({
    touched: PropTypes.bool,
    error: PropTypes.string,
  }),
  select: PropTypes.bool,
  children: PropTypes.arrayOf(PropTypes.element),
};

renderTextField.defaultProps = {
  meta: null,
  select: false,
  children: [],
};

class Admin extends Component {

  constructor(props) {
    super(props);
    this.state = {
      date: Date.now(),
      posts: [],
      formData: {
        title: '',
        duration: '',
        price: '',
        status: '',
        sos: '',
        follow_me: '',
        posh: '',
        ambulance: '',
        road_side_assistance: '',
        accidental_insurance: '',
        sos_description: '',
        follow_me_description: '',
        posh_description: '',
        ambulance_description: '',
        road_side_assistance_description: '',
        accidental_insurance_description: '',
        user_id: '',
      },
      submitted: false,
    }
  }
  componentDidMount() {
    var user_id = '';
    if (localStorage.getItem('uid')) {
      user_id = localStorage.getItem('uid');
    }
    const { formData } = this.state;
    formData['tenant_id'] = user_id;
    this.setState({ formData });
  }

  handleSubmit = () => {
    this.setState({ submitted: true }, () => {
      setTimeout(() => this.setState({ submitted: false }), 5000);
      fetch(myConstClass.BASE_URL + `/user/addPlan`, {
        method: 'POST',
        body: JSON.stringify($('#pform').serializeArray()),
      }).then(response => response.json()).then((response) => {
        if (response == true) {
          toast('Plan Added Successfully..!', { containerId: 'B', type: toast.TYPE.SUCCESS })
          this.props.history.push('/forms/plan_list/');
        } else {
          toast('Plan Insertion Failed..!', { containerId: 'B', type: toast.TYPE.ERROR })
        }
      })
        .catch();
    });
  }

  handleStartDateChange = (date) => {
    const { formData } = this.state;
    formData['start_date'] = date;
    this.setState({ formData });
  };
  handleChange = (event) => {
    const { formData } = this.state;
    formData[event.target.name] = event.target.value;
    this.setState({ formData });
  }

  render() {
    const { formData, submitted } = this.state;
    const { posts } = this.state;
    return (
      <Container>
        <Row>
          <Col md={12}>
            <h3 className="page-title">Add Plan</h3>
          </Col>
        </Row>
        <Row>
          <Card>
            <CardBody>
              <div className="card__title"></div>
              <div>
                <ToastContainer enableMultiContainer containerId={'A'} position={toast.POSITION.BOTTOM_LEFT} />
                <ToastContainer enableMultiContainer containerId={'B'} position={toast.POSITION.TOP_RIGHT} />
              </div>
              <ValidatorForm
                className="material-form" id="pform"
                onSubmit={this.handleSubmit}
              >
                <input type="hidden" name="tenant_id" value={formData.tenant_id} />
                <Row>
                  <Col md={6} lg={6}>
                    <TextValidator
                      onChange={this.handleChange}
                      name="title"
                      placeholder="Title"
                      label="Title"
                      className="material-form__field"
                      value={formData.title}
                      validators={['required']}
                      errorMessages={['this field is required']}
                    />

                    <TextValidator
                      onChange={this.handleChange}
                      className="material-form__field"
                      name="duration"
                      placeholder="Duration in days"
                      label="Duration in days"
                      value={formData.duration}
                      validators={['required', 'matchRegexp:^[0-9]{1,6}$']}
                      errorMessages={['this field is required', 'Enter maximum 6 digit Number..']}
                    />

                    <TextValidator
                      onChange={this.handleChange}
                      className="material-form__field"
                      name="price"
                      placeholder="Price"
                      label="Price"
                      value={formData.price}
                      validators={['required', 'matchRegexp:^[0-9]{1,6}$']}
                      errorMessages={['this field is required', 'Enter maximum 6 digit Number..']}
                    />
                    <div>
                      <FormControl className="material-form__field">
                        <InputLabel htmlFor="age-simple">Status</InputLabel>
                        <Select
                          value={formData.status}
                          onChange={this.handleChange}
                          inputProps={{
                            name: 'status',
                            id: 'status',
                          }}
                        >
                          <MenuItem value={1}>Active</MenuItem>
                          <MenuItem value={2}>Inactive</MenuItem>
                        </Select>
                      </FormControl>
                    </div>
                    <br />

                    <h5>Plan Description</h5>
                    <JoditEditor
                      value={formData.plan_description}
                      name="plan_description"
                      id="plan_description"
                      tabIndex={2}
                    />
                    <hr />
                    <div>
                    </div>
                  </Col>

                  <Col md={1} lg={1}>
                    {/* <InputLabel htmlFor="pre_licence">Plan features</InputLabel> */}
                    <br />
                    <TextValidator
                      onChange={this.handleChange}
                      className="material-form__field"
                      name="sos"
                      placeholder="SOS"
                      label="SOS"
                      value={formData.sos}
                      validators={['required']}
                      errorMessages={['this field is required']}
                    />
                    <TextValidator
                      onChange={this.handleChange}
                      className="material-form__field"
                      name="follow_me"
                      placeholder="Follow Me"
                      label="Follow Me"
                      value={formData.follow_me}
                      validators={['required']}
                      errorMessages={['this field is required']}
                    />

                    <TextValidator
                      onChange={this.handleChange}
                      className="material-form__field"
                      name="posh"
                      placeholder="POSH"
                      label="POSH"
                      value={formData.posh}
                      validators={['required']}
                      errorMessages={['this field is required']}
                    />

                    <TextValidator
                      onChange={this.handleChange}
                      className="material-form__field"
                      name="ambulance"
                      placeholder="Ambulance"
                      label="Ambulance"
                      value={formData.ambulance}
                      validators={['required']}
                      errorMessages={['this field is required']}
                    />

                    <TextValidator
                      onChange={this.handleChange}
                      className="material-form__field"
                      name="road_side_assistance"
                      placeholder="Road side assistance"
                      label="Road side assistance"
                      value={formData.road_side_assistance}
                      validators={['required']}
                      errorMessages={['this field is required']}
                    />

                    <TextValidator
                      onChange={this.handleChange}
                      className="material-form__field"
                      name="accidental_insurance"
                      placeholder="Accidental insurance"
                      label="Accidental insurance"
                      value={formData.accidental_insurance}
                      validators={['required']}
                      errorMessages={['this field is required']}
                    />
                  </Col>
                  <Col md={5} lg={5}>
                    <InputLabel htmlFor="pre_licence">Plan features</InputLabel>
                    <br />
                    <TextValidator
                      onChange={this.handleChange}
                      className="material-form__field"
                      name="sos_description"
                      placeholder="SOS description"
                      label="SOS description"
                      value={formData.sos_description}
                      validators={['required']}
                      errorMessages={['this field is required']}
                    />
                    <TextValidator
                      onChange={this.handleChange}
                      className="material-form__field"
                      name="follow_me_description"
                      placeholder="Follow Me description"
                      label="Follow Me description"
                      value={formData.follow_me_description}
                      validators={['required']}
                      errorMessages={['this field is required']}
                    />
                    <TextValidator
                      onChange={this.handleChange}
                      className="material-form__field"
                      name="posh_description"
                      placeholder="POSH description"
                      label="POSH description"
                      value={formData.posh_description}
                      validators={['required']}
                      errorMessages={['this field is required']}
                    />
                    <TextValidator
                      onChange={this.handleChange}
                      className="material-form__field"
                      name="ambulance_description"
                      placeholder="Ambulance description"
                      label="Ambulance description"
                      value={formData.ambulance_description}
                      validators={['required']}
                      errorMessages={['this field is required']}
                    />
                    <TextValidator
                      onChange={this.handleChange}
                      className="material-form__field"
                      name="road_side_assistance_description"
                      placeholder="Road side assistance description"
                      label="Road side assistance description"
                      value={formData.road_side_assistance_description}
                      validators={['required']}
                      errorMessages={['this field is required']}
                    />

                    <TextValidator
                      onChange={this.handleChange}
                      className="material-form__field"
                      name="accidental_insurance_description"
                      placeholder="Accidental insurance description"
                      label="Accidental insurance description"
                      value={formData.accidental_insurance_description}
                      validators={['required']}
                      errorMessages={['this field is required']}
                    />

                  </Col>
                </Row>
                <br />

                <Button
                  color="primary"
                  variant="contained"
                  type="submit"
                  disabled={submitted}
                >
                  {(submitted && 'Your form is submitted!') || (!submitted && 'Submit')}
                </Button>
              </ValidatorForm>
            </CardBody>
          </Card>
        </Row>
      </Container>
    )
  }
}

export default reduxForm({
  form: 'admin', // a unique identifier for this form
})(withTranslation('common')(Admin));
