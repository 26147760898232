import React, { Component } from 'react';
import { Col, Container, Row, Button, Card, CardBody, ButtonToolbar, Input } from 'reactstrap';
import { FormGroup, Label } from 'reactstrap';
import { withTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import showResults from '../Show';
import { Field, reduxForm } from 'redux-form';
import $ from 'jquery';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import * as myConstClass from '../../../constant.js';
import CsvDownloader from 'react-csv-downloader';
import BootstrapTable from "react-bootstrap-table-next";
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";
import paginationFactory from "react-bootstrap-table2-paginator";
import DatePicker1 from 'react-datepicker';

const { SearchBar } = Search;
const columns = [{
  id: 'id',
  displayName: 'ID'
}, {
  id: 'code',
  displayName: 'Code'
}, {
  id: 'action',
  displayName: 'Action'
}, {
  id: 'error',
  displayName: 'Error'
}];

const tableColumns = [
  {
    dataField: "sr_no",
    text: "#",
    sort: true,
    headerStyle: { width: "10%" }
  },
  {
    dataField: "code",
    text: 'Code',
    sort: true,
    headerStyle: { width: "10%" }
  },
  {
    dataField: "action",
    text: "Action",
    headerStyle: { width: "10%" },
    csvExport: false
  }, {
    dataField: "error",
    text: "Error",
    headerStyle: { width: "10%" },
    csvExport: false
  },
];

const file_link = `${process.env.PUBLIC_URL}/QR CODE SAMPLE File.csv`;
const loader = `${process.env.PUBLIC_URL}/loader.gif`;
const rolling = `${process.env.PUBLIC_URL}/img/Rolling.gif`;

class Bulk_import extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedFile: null,
      posts: [],
      dusers: '',
      uusers: '',
      posts_icnt: 0,
      posts_ucnt: 0,
      posts_ecnt: 0,
      bulk_uploade_session: '',
      formData: [],
    }
    this.flag = 0;
    this.customLabels = {
      first: '<<',
      last: '>>',
      prev: '<',
      next: '>',
      show: 'Display',
      entries: 'rows',
      noResults: 'There is no data to be displayed',
    };
    this.handleSubmit = this.handleSubmit.bind(this);
  }
  componentDidMount() {
    // session uid
    if (localStorage.getItem('uid')) {
      if (localStorage.getItem('uid') != 1) {

      }
    }
    if (!localStorage.getItem('session_id')) {
      this.props.history.push('/log_in');
    }
  }

  handleSubmit = () => {
    alert(JSON.stringify($('#pform').serializeArray()));
  }

  onChangeHandler = event => {
    event.persist();
    let suppotedVideoFormat = ["application/vnd.ms-excel", "text/csv", "application/csv", "text/comma-separated-values"];
    if (suppotedVideoFormat.includes(event.target.files[0].type)) {
      this.setState({
        selectedFile: event.target.files[0],
        loaded: 0,
      })
    } else {
      alert("Unsupported File Format, Please Add Csv File");
    }
  }

  onClickHandler = () => {
    if (this.state.selectedFile == null) {
      alert("Please select file")
    } else {
      var bulk_uploade_session = localStorage.getItem('session_id') + Number(new Date());
      this.setState({ bulk_uploade_session: bulk_uploade_session })
      const data = new FormData()
      data.append('file', this.state.selectedFile);
      data.append('session_id', bulk_uploade_session);
      if (localStorage.getItem('uid')) {
        data.append('user_id', localStorage.getItem('uid'));
      }
      $('#loader').show();
      fetch(myConstClass.BASE_URL + `/qrcode/qrcodeBulkUpload`, {
        method: 'POST',
        body: data,
      }).then(response => response.json())
        .then(response => {
          this.setState({ posts_icnt: response['icnt'] });
          this.setState({ posts_ucnt: response['ucnt'] });
          this.setState({ posts_ecnt: response['ecnt'] });
          this.setState({ posts: response['rows'] });

          $('#loader').hide();

          fetch(myConstClass.BASE_URL + `/qrcode/getDeclinedBulkQrcode`, {
            method: 'POST',
            body: data,
          }).then(response => response.json())
            .then(response => this.setState({ dusers: response }))

          fetch(myConstClass.BASE_URL + `/qrcode/getUpdatedBulkQrcode`, {
            method: 'POST',
            body: data,
          }).then(response => response.json())
            .then(response => this.setState({ uusers: response }))
        })
    }
  }

  onSaveClickHandler = () => {
    const data = new FormData()
    data.append('file', this.state.selectedFile);
    data.append('session_id', this.state.bulk_uploade_session);
    fetch(myConstClass.BASE_URL + `/qrcode/saveQrcodeBulkImportData`, {
      method: 'POST',
      body: data,
    }).then(response => response.json()).then((response) => {
      if (response) {
        toast('QR Code Added Successfully..!', { containerId: 'B', type: toast.TYPE.SUCCESS })
        this.props.history.push('/forms/qrcode_list/');
      } else {
        toast('QR Code Insertion Failed..!', { containerId: 'B', type: toast.TYPE.ERROR })
      }
    }).catch();
  }

  render() {
    // const {      } = this.state;
    return (
      <Container>
        <Row>
          <ToastContainer enableMultiContainer containerId={'A'} position={toast.POSITION.BOTTOM_LEFT} />
          <ToastContainer enableMultiContainer containerId={'B'} position={toast.POSITION.TOP_RIGHT} />
          <Col md={12}>
            <h3 className="page-title">QR Code Bulk Upload</h3>
          </Col>
        </Row>
        <Card  >
          <CardBody>
            <h5>
              <b>Import CSV file only</b>
              <a href={file_link} style={{ float: 'right' }}>
                <Button color="primary" type="button" className="previous">Download Sample File</Button>
              </a>
              <br /><br />
              1. The first line in downloaded csv file should remain as it is. Please do not change the order of columns in csv file.
              <br />
              2. The correct column order is (QR Code(*))
              <br />
              3. You must follow the csv file, otherwise your data will not be saved.
              <br />
              4. (*) indicate mandatory fields in csv file.
            </h5>
            <hr />
            {this.state.posts && this.state.posts.length ?
              <div>
                <Row>
                  <Col md={8}>
                    <h5>Insert : {this.state.posts_icnt} &nbsp;&nbsp; Update : {this.state.posts_ucnt} &nbsp;&nbsp; Error : {this.state.posts_ecnt}</h5>
                  </Col>
                  <Col md={4} className="csv_div" >
                    <CsvDownloader
                      filename="Updated QR Code list"
                      separator=","
                      columns={columns}
                      datas={this.state.uusers}
                      className="btn btn-primary btn-block cmargin"
                      text="Updated QR Code list" />
                    &nbsp;&nbsp;&nbsp;&nbsp;
                    <CsvDownloader
                      filename="QR Code Error list"
                      separator=","
                      columns={columns}
                      datas={this.state.dusers}
                      className="btn btn-primary btn-block"
                      text="QR Code Error list" />
                  </Col>
                </Row>
                <hr />
              </div>
              :
              <div className='mt-4'>
                <form className="form" id="pform" onSubmit={this.handleSubmit} style={{ display: "inline" }}>
                  <Row>
                    <Col md={3}>
                      <input type="file" name="file" onChange={this.onChangeHandler} />
                    </Col>
                    <Col md={6}>
                      <button type="button" className="btn btn-primary btn-block col-md-4" onClick={this.onClickHandler}>Upload</button>
                      <img src={loader} id="loader" style={{ width: '12%', display: 'none' }} />
                    </Col>
                    <Col md={3}></Col>
                  </Row>
                </form>
              </div>
            }
            {this.state.posts && this.state.posts.length ?
              <Row>
                <Col md={12} lg={12}>
                  <Card>
                    <ToolkitProvider
                      keyField="name"
                      data={this.state.posts}
                      columns={tableColumns}
                      exportCSV
                      search
                    >
                      {props => (
                        <div>
                          <CardBody>
                            <BootstrapTable
                              {...props.baseProps}
                              bootstrap4
                              bordered={false}
                              pagination={paginationFactory({
                                sizePerPage: 10,
                                sizePerPageList: [5, 10, 25, 50]
                              })}
                            />
                          </CardBody>
                        </div>
                      )}
                    </ToolkitProvider>
                    <div className='card-body'>
                      <button type="button" className="btn btn-primary btn-block col-md-2" onClick={this.onSaveClickHandler}>Save</button>
                    </div>
                  </Card>
                </Col>
              </Row>
              : ''}
          </CardBody>
        </Card>
      </Container>
    )
  }
}

Bulk_import.propTypes = {
  reset: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired,
};
export default reduxForm({
  form: 'Bulk_import',
})(withTranslation('common')(Bulk_import));
