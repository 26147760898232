//export const BASE_URL = "https://zimanb2c.citysmile.in/backend/";
 ///export const BASE_URL = "https://captainindia.anekalabs.com/backend/index.php";
// export const BASE_URL = "https://localhost/ziman-b2c/backend";
//export const BASE_URL = "http://localhost/ziman_b2c/EasyDevReact/";

// export const BASE_URL = "http://localhost/captainindia/backend/index.php";
// export const BASE_URL = "http://localhost/captain-india-phase-2/backend/index.php";
  // export const BASE_URL = "https://captainindia.anekalabs.com/backend/index.php";
  export const BASE_URL = "https://captainindiatest2.anekalabs.com/backend/index.php";
// export const BASE_URL = "https://uat.captainindia.anekalabs.com/backend/index.php";
//  export const BASE_URL = "http://uat1.captainindia.anekalabs.com/backend/index.php";