import React, { Component } from 'react';
import PropTypes from 'prop-types';
import SidebarLink from './SidebarLink';
import SidebarCategory from './SidebarCategory';

class SidebarContent extends Component {
  static propTypes = {
    changeToDark: PropTypes.func.isRequired,
    changeToLight: PropTypes.func.isRequired,
    onClick: PropTypes.func.isRequired,
  };

  hideSidebar = () => {
    const { onClick } = this.props;
    onClick();
  };

  render() {
    const { changeToLight, changeToDark } = this.props;

    return (
      <div className="sidebar__content">
        <ul className="sidebar__block">
          <SidebarLink title="Dashboard" icon="home" route="/dashboard_default" onClick={this.hideSidebar} />
          <SidebarCategory title="Manage Users" icon="users">
            {/* <SidebarLink title="Add Employee" route="/forms/add" onClick={this.hideSidebar} />
            <SidebarLink title="Add Bulk Employee" route="/forms/addbulk" onClick={this.hideSidebar} /> */}
            {localStorage.getItem('utype') == '1' ?
              <SidebarLink title="Add User" route="/forms/add_user" onClick={this.hideSidebar} />
              : ''}
            <SidebarLink title="User List" route="/forms/users" onClick={this.hideSidebar} />
            {localStorage.getItem('utype') == '1' ?
              <SidebarLink title="Bulk upload" route="/forms/user_bulk_upload" onClick={this.hideSidebar} />
              : ''}
          </SidebarCategory>
          {/* {localStorage.getItem('utype') == '1' ? <SidebarCategory title="Manage Admin" icon="user">        
            <SidebarLink title="Add Admin" route="/forms/add_admin" onClick={this.hideSidebar} />
            <SidebarLink title="List Admin" route="/forms/admin_list" onClick={this.hideSidebar} />
          </SidebarCategory>:''} */}
          {localStorage.getItem('utype') == '1' ? <SidebarCategory title="Manage Tenant" icon="user">
            <SidebarLink title="Add Tenant" route="/forms/add_admin" onClick={this.hideSidebar} />
            <SidebarLink title="List Tenant" route="/forms/admin_list" onClick={this.hideSidebar} />
            <SidebarLink title="Keyword List" route="/forms/tenant_keyword_list" onClick={this.hideSidebar} />
          </SidebarCategory> : ''}

          <SidebarLink icon="eye" title="Panic Evidence" route="/forms/evidence_list" onClick={this.hideSidebar} />
          <SidebarLink icon="eye" title="Posh Requests" route="/forms/posh_evidence_list" onClick={this.hideSidebar} />
          {/* <SidebarLink icon="user" title="Citizen Cop" route="/forms/cop_list" onClick={this.hideSidebar} /> */}
          <SidebarCategory title="Manage Locations" icon="map-marker">
            {/* <SidebarLink title="Add Bulk Location" route="/forms/addLocation" onClick={this.hideSidebar} />
            <SidebarLink title="List Location" route="/forms/locations" onClick={this.hideSidebar} />
            <SidebarLink title="Group Location" route="/forms/group_locations" onClick={this.hideSidebar} /> */}
            <SidebarLink title="Tracking" route="/forms/tracking" onClick={this.hideSidebar} />
            <SidebarLink title="Live Tracking" route="/forms/live_tracking" onClick={this.hideSidebar} />
            <SidebarLink title="Trips" route="/forms/trip_list" onClick={this.hideSidebar} />
            <SidebarLink title="Tracker Device" route="/forms/tracker_device" onClick={this.hideSidebar} />

            {/* <SidebarLink title="Follow Me" route="/forms/follow_me" onClick={this.hideSidebar} /> */}
            {/* <SidebarLink title="All Tracking Devices" route="/forms/device_list" onClick={this.hideSidebar} /> */}

          </SidebarCategory>
          {/* {localStorage.getItem('utype') == '1' ? 
          <div> */}
          {/* <SidebarLink icon="alarm" title="Notifications" route="/forms/notifications" onClick={this.hideSidebar} /> */}
          <SidebarLink icon="alarm" title="Travel Safe" route="/forms/pre_trigger_notifications" onClick={this.hideSidebar} />
          <SidebarCategory title="Notifications" icon="alarm">
            <SidebarLink title="Regular Notifications" route="/forms/notifications" onClick={this.hideSidebar} />
            {/* <SidebarLink title="Morning Greetings" route="/forms/morning_greetings" onClick={this.hideSidebar} />
            <SidebarLink title="Birthday Greetings" route="/forms/birthday_greetings" onClick={this.hideSidebar} />
            <SidebarLink title="Safety Tips" route="/forms/safety_tips_msg" onClick={this.hideSidebar} /> */}
            {/* <SidebarLink title="Bulletin News" route="/forms/bulletin_news" onClick={this.hideSidebar} /> */}
          </SidebarCategory>
          <SidebarCategory title="News Board" icon="alarm">
            <SidebarLink title="News Board" route="/forms/bulletin_news" onClick={this.hideSidebar} />
            <SidebarLink title="News Board Tag" route="/forms/bulletin_tag" onClick={this.hideSidebar} />
          </SidebarCategory>
          <SidebarLink icon="alarm" title="Banner" route="/forms/banner" onClick={this.hideSidebar} />
          {/* <SidebarLink icon="alarm" title="Pre Trigger Notifications" route="/forms/pre_trigger_notifications" onClick={this.hideSidebar} /> */}
          {/* <SidebarLink icon="envelope" title="View Queries" route="/forms/queries" onClick={this.hideSidebar} /> */}
          {localStorage.getItem('utype') == '1' ?
          <SidebarCategory title="CMS Pages" icon="file-empty">
          <SidebarLink title="Help" route="/forms/help" onClick={this.hideSidebar} />
          <SidebarLink title="Privacy Policy" route="/forms/privacy_policy" onClick={this.hideSidebar} />
          <SidebarLink title="Terms and Conditions" route="/forms/terms" onClick={this.hideSidebar} />
          <SidebarLink title="About Us" route="/forms/about_us" onClick={this.hideSidebar} />
          {/* <SidebarLink title="Tutorial" route="/forms/tutorial" onClick={this.hideSidebar} /> */}
          </SidebarCategory>
          : ''}


          {/* </div> :''} */}

          {/* {localStorage.getItem('utype') == '1' ? */}
          <SidebarCategory title="Plan" icon="alarm">
            <SidebarLink title="Add Plan" route="/forms/add_plan" onClick={this.hideSidebar} />
            <SidebarLink title="Plan List" route="/forms/plan_list" onClick={this.hideSidebar} />
          </SidebarCategory>
          {/* : ''} */}

          {localStorage.getItem('utype') == '1' ?
          <SidebarLink icon="briefcase" title="Payment Transaction" route="/forms/payment_list" onClick={this.hideSidebar} />
          : ''}
          <SidebarLink icon="wheelchair" title="Ambulance Booking" route="/forms/ambulance_list" onClick={this.hideSidebar} />
          <SidebarLink icon="car" title="RSA Booking" route="/forms/rsa_list" onClick={this.hideSidebar} />

          {/* OTP */}
          {localStorage.getItem('utype') == '1' ?
            <SidebarLink icon="envelope" title="OTP" route="/forms/otp_list" onClick={this.hideSidebar} />
            : ''}

          {/* QR Code */}
          {/* {localStorage.getItem('utype') == '1' ?
            <SidebarLink icon="menu" title="QR Code" route="/forms/qrcode_list" onClick={this.hideSidebar} />
            : ''} */}

          {/* QR Code Missing alert */}
          {/* {localStorage.getItem('utype') == '1' ?
            <SidebarLink icon="menu" title="QR Code Raise Alert" route="/forms/qrcode_missing_alert_list" onClick={this.hideSidebar} />
            : ''} */}
          {localStorage.getItem('utype') == '1' ?
            <SidebarCategory title="QR Code" icon="alarm">
              <SidebarLink title="QR Code List" route="/forms/qrcode_list" onClick={this.hideSidebar} />
              <SidebarLink title="Missing QR Code" route="/forms/missing_status_list" onClick={this.hideSidebar} />
              <SidebarLink title="Missing QR Notification" route="/forms/missing_status_notification" onClick={this.hideSidebar} />
              <SidebarLink title="Raise Alerts" route="/forms/qrcode_missing_alert_list" onClick={this.hideSidebar} />
              <SidebarLink title="Scan Alerts" route="/forms/qrcode_scan_alert_list" onClick={this.hideSidebar} />
            </SidebarCategory>
            : ''}

        </ul>

      </div>
    );
  }
}

export default SidebarContent;
