import React, { Component } from 'react';
import { Card, CardBody, Col, Container, Row, Button, ButtonToolbar, Modal } from 'reactstrap';
import { Link } from 'react-router-dom';
import { withTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import * as myConstClass from '../../../constant.js';
import 'font-awesome/css/font-awesome.min.css';
import moment from 'moment';
import BootstrapTable from "react-bootstrap-table-next";
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";
import paginationFactory from "react-bootstrap-table2-paginator";

const { SearchBar } = Search;
const tableColumns = [
  {
    dataField: "id",
    text: "#",
    sort: true,
    headerStyle: { width: "5%" }
  }, {
    dataField: "keyword",
    text: "Title",
    sort: true,
    headerStyle: { width: "25%" }
  }, {
    dataField: "action",
    text: "Action",
    headerStyle: { width: "10%" },
    csvExport: false
  },
];
class Tenant_Keyword_view extends Component {
  constructor(props) {
    super(props);
    this.state = {
      tenant_keyword: [],
      formData: [],
      admin_data: '',
      delete_modal: false,
      delete_id: '',
    }
    this.flag = 0;
    this.customLabels = {
      first: '<<',
      last: '>>',
      prev: '<',
      next: '>',
      show: 'Display',
      entries: 'rows',
      noResults: 'There is no data to be displayed',
    };
    this.toggleClose = this.toggleClose.bind(this);
  }

  onSortFunction = {
    date(columnValue) {
      // Convert the string date format to UTC timestamp
      // So the table could sort it by number instead of by string
      return moment(columnValue, 'Do MMMM YYYY').valueOf();
    },
  };
  componentDidMount() {
    const answer_array = this.props.location.pathname.split('/');
    fetch(myConstClass.BASE_URL + '/user/viewTenantKeywordByTenantId/' + answer_array['3'])
      .then(response => response.json())
      .then(response => {
        var admin_result = response.admin_result
        var tenant_keyword_result = response.tenant_keyword_result
        let i = 1;
        tenant_keyword_result.forEach(element => {
          var id = element.id;
          element.action = <div><center><i className="fa fa-remove ptr_css" onClick={() => this.deleteToggleModel(id)}></i>&nbsp;&nbsp;</center></div>;
          element.id = i;
          i++;
        });
        this.setState({ formData: tenant_keyword_result })
        this.setState({ admin_data: admin_result })
      }).catch(err => console.log(err))

    if (!localStorage.getItem('session_id')) {
      this.props.history.push('/log_in');
    }
  }

  deleteToggleModel(id) {
    this.setState({ delete_modal: true });
    this.setState({ delete_id: id });
  }

  toggleClose() {
    this.setState({ delete_modal: false });
    this.setState({ delete_id: '' });
  }

  remove(e) {
    this.setState({ delete_modal: false });
    this.setState({ delete_id: '' });

    fetch(myConstClass.BASE_URL + `/user/deleteTenantKeyword`, {
      method: 'POST',
      body: e,
    }).then(response => response.json()).then((response) => {
      if (response == true) {
        toast('Keyword Deleted Successfully..!', { containerId: 'B', type: toast.TYPE.SUCCESS })
        const answer_array = this.props.location.pathname.split('/');
        fetch(myConstClass.BASE_URL + '/user/viewTenantKeywordByTenantId/' + answer_array['3'])
          .then(response => response.json())
          .then(response => {
            var admin_result = response.admin_result
            var tenant_keyword_result = response.tenant_keyword_result
            let i = 1;
            tenant_keyword_result.forEach(element => {
              var id = element.id;
              element.action = <div><center><i className="fa fa-remove ptr_css" onClick={() => this.deleteToggleModel(id)}></i>&nbsp;&nbsp;</center></div>;
              element.id = i;
              i++;
            });
            this.setState({ formData: tenant_keyword_result })
            this.setState({ admin_data: admin_result })
          }).catch(err => console.log(err))
      } else {
        toast('Failed to delete.', { containerId: 'B', type: toast.TYPE.ERROR })
      }
    }).catch();
  }

  add_tenant_keyword() {
    const answer_array = this.props.location.pathname.split('/');
    if (answer_array['3']) {
      this.props.history.push('/forms/add_tenant_keyword/' + answer_array['3']);
    } else {
      this.props.history.push('/forms/add_tenant_keyword');
    }
  }

  render() {
    const { admin_data, delete_modal, delete_id } = this.state;

    return (
      <Container>
        <Modal style={{ position: 'relative', margin: '0 auto', top: '28%', width: '385px' }} isOpen={delete_modal} modalClassName={`ltr-support`} className={`modal-dialog--primary modal-dialog--header`} >
          <div className="modal__header"  >
            <button className="lnr lnr-cross modal__close-btn" type="button" onClick={this.toggleClose} style={{ marginBottom: '5px' }} />
            <h4 className="text-modal modal__title" style={{ color: '#fff' }}>{"Keyword delete confirmation"}</h4>
          </div>
          <div className="modal__body">
            <div className="form__form-group">
              <div className="form__form-group-field">
                <span className="form__form-group-label typography-message" >Are you sure you want to delete keyword? </span>&nbsp;&nbsp;&nbsp;
              </div>
            </div>
          </div>
          <ButtonToolbar className="modal__footer">
            <Button color="primary" type="submit" onClick={() => this.remove(delete_id)}  >YES</Button>
            <Button className="modal_cancel" onClick={this.toggleClose}>NO</Button>
          </ButtonToolbar>
        </Modal>
        <Row>
          <Col md={12}><h3 className="page-title">Tenant Keyword Detail</h3></Col>
          <ToastContainer enableMultiContainer containerId={'A'} position={toast.POSITION.BOTTOM_LEFT} />
          <ToastContainer enableMultiContainer containerId={'B'} position={toast.POSITION.TOP_RIGHT} />
        </Row>

        <Row>
          <Col md={12} lg={12}>
            <Card>
              <CardBody>
                <Row>
                  <Col md={2} lg={2}>
                    <h5><b>Tenant Name : </b></h5>
                  </Col>
                  <Col md={6} lg={6}>
                    <h5>{admin_data.first_name} {admin_data.last_name}</h5>
                  </Col>
                  <Col md={2} lg={2}>
                    <Button color="primary" style={{ float: 'right' }} onClick={() => this.add_tenant_keyword()} > Add Keyword</Button>
                  </Col>
                  <Col md={2} lg={2}>
                    <Link className="btn btn-secondary" to="/forms/tenant_keyword_list">Cancel</Link>
                  </Col>
                </Row>

                {/*
                <br />
                 <Row>
                  <Col md={2} lg={2}>
                    <h5><b>Keyword : </b></h5>
                  </Col>
                  <Col md={6} lg={6}>
                    <h5>{admin_data.keyword}</h5>
                  </Col>
                </Row>
                <br /> */}

                <ToolkitProvider keyField="name" data={this.state.formData} columns={tableColumns} exportCSV search >
                  {props => (
                    <div>
                      <CardBody>
                        <Row></Row>
                        <SearchBar {...props.searchProps} />
                        <BootstrapTable
                          {...props.baseProps}
                          bootstrap4
                          bordered={false}
                          pagination={paginationFactory({
                            sizePerPage: 10,
                            sizePerPageList: [5, 10, 25, 50]
                          })}
                        />
                      </CardBody>
                    </div>
                  )}
                </ToolkitProvider>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>)
  }
}

export default withTranslation('common')(Tenant_Keyword_view);
