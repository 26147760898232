import React, { Component } from 'react';
import { Card, CardBody, Col, Container, Row, Button } from 'reactstrap';
import { withTranslation } from 'react-i18next';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import moment from 'moment';
import * as myConstClass from '../../../constant.js';
import 'font-awesome/css/font-awesome.min.css';
import BootstrapTable from "react-bootstrap-table-next";
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";
import paginationFactory from "react-bootstrap-table2-paginator";
import { CSVLink, CSVDownload } from "react-csv";

const { SearchBar } = Search;
const tableColumns = [
  {
    dataField: "sr_no",
    text: "#",
    sort: true,
    headerStyle: { width: "5%" }
  },
  {
    dataField: "user_full_name",
    text: "User Full Name",
    sort: true,
    headerStyle: { width: "20%" }
  },
  {
    dataField: "code",
    text: "Code",
    sort: true,
    headerStyle: { width: "10%" }
  },
  {
    dataField: "type_title",
    text: "Type",
    sort: true,
    headerStyle: { width: "10%" }
  },
  {
    dataField: "created_at",
    text: "Date/Time",
    sort: true,
    headerStyle: { width: "10%" }
  },
  {
    dataField: "action",
    text: "Action",
    sort: true,
    headerStyle: { width: "5%" }
  },
];
class Qr_Code_list extends Component {
  constructor(props) {
    super(props);
    this.state = {
      formData: [],
      unused_qrcode: [],
    }
    this.getQrCodeList = this.getQrCodeList.bind(this);
    this.customLabels = {
      first: '<<',
      last: '>>',
      prev: '<',
      next: '>',
      show: 'Display',
      entries: 'rows',
      noResults: 'There is no data to be displayed',
    };
  }

  onSortFunction = {
    date(columnValue) {
      // Convert the string date format to UTC timestamp
      // So the table could sort it by number instead of by string
      return moment(columnValue, 'Do MMMM YYYY').valueOf();
    },
  };

  componentDidMount() {
    if (!localStorage.getItem('session_id')) {
      console.log('History:', this.props);
      this.props.history.push('/log_in');
    }
    this.getQrCodeList();
    this.getUnusedQrCodeList();
  }

  viewQrCode(e, user_id) {
    this.props.history.push('/forms/view_qrcode/' + e);
  }

  add_bulk_upload() {
    this.props.history.push('/forms/qrcode_bulk_upload');
  }

  add_qr_code() {
    this.props.history.push('/forms/add_qrcode');
  }

  getQrCodeList() {
    fetch(myConstClass.BASE_URL + '/qrcode/getQrCode')
      .then(response => response.json())
      .then(response => {
        let key = 1;
        response.forEach(element => {
          var id = element.id;
          element.sr_no = key;
          var user_full_name = "-";
          if (element.first_name != null) {
            user_full_name = element.first_name;
          }
          if (element.last_name != null) {
            user_full_name += " " + element.last_name;
          }
          element.user_full_name = user_full_name;
          var missing_status = element.missing_status;
          var missing_status_button = "";
          if (missing_status == '1') {
            missing_status_button = <i className="fa fa-toggle-off ptr_css" onClick={() => this.changeMissingStatus(id, missing_status)}></i>;
          }
          if (missing_status == '2') {
            missing_status_button = <i className="fa fa-toggle-on ptr_css" onClick={() => this.changeMissingStatus(id, missing_status)}></i>;
          }

          element.action = <div style={{ fontSize: '20px' }}><i id={id} className="fa fa-eye ptr_css" onClick={() => this.viewQrCode(id, element.user_id)}></i>{missing_status_button}</div>;
          key++;
        });
        this.setState({ formData: response })
      })
      .catch(err => console.log(err))
  }

  getUnusedQrCodeList() {
    fetch(myConstClass.BASE_URL + '/qrcode/getUnusedQrCode')
      .then(response => response.json())
      .then(response => {
        let key = 1;
        response.forEach(element => {
          var id = element.id;
          element.sr_no = key;
          key++;
        });
        this.setState({ unused_qrcode: response })
      })
      .catch(err => console.log(err))
  }

  changeMissingStatus(id, missing_status) {
    const formData = new FormData();
    formData.append('id', id);
    formData.append('missing_status', missing_status);
    fetch(myConstClass.BASE_URL + `/qrcode/changeMissingStatus`, {
      method: 'POST',
      body: formData,
    }).then(response => response.json()).then((response) => {
      if (response == true) {
        toast('Missing status changed.', { containerId: 'B', type: toast.TYPE.SUCCESS })
        this.getQrCodeList();
      } else {
        toast('Failed to change Missing status.', { containerId: 'B', type: toast.TYPE.ERROR })
      }
    }).catch();
  }

  render() {
    const { formData } = this.state;
    const csv_headers = [
      { label: "#", key: "id" },
      { label: "Code", key: "code" },
      { label: "Type", key: "type_title" },
      { label: "Date/Time", key: "created_at" },
    ];
    return (
      <Container>
        <Row>
          <Col md={12}>
            <h3 className="page-title">QR Code List</h3>
            <div style={{ float: 'right' }}></div>
          </Col>
          <ToastContainer enableMultiContainer containerId={'A'} position={toast.POSITION.BOTTOM_LEFT} />
          <ToastContainer enableMultiContainer containerId={'B'} position={toast.POSITION.TOP_RIGHT} />
        </Row>
        <Row>
          <Col md={12} lg={12}>
            <Card>
              <ToolkitProvider
                keyField="name"
                data={this.state.formData}
                columns={tableColumns}
                exportCSV
                search
              >
                {props => (
                  <div>
                    <CardBody>
                      <Button color="primary" style={{ float: 'right' }} onClick={() => this.add_bulk_upload()} > Add Bulk QR Code</Button>
                      <Button color="primary" style={{ float: 'right' }} onClick={() => this.add_qr_code()} > Add QR Code</Button>
                      {this.state.formData ?
                        <CSVLink filename={"Unused QR Code.csv"}
                          className="btn btn-primary" style={{ float: 'right' }} data={this.state.unused_qrcode} headers={csv_headers}>Export Unused QR</CSVLink>
                        : ''}
                      <SearchBar {...props.searchProps} />
                      <BootstrapTable
                        {...props.baseProps}
                        bootstrap4
                        bordered={false}
                        pagination={paginationFactory({
                          // sizePerPage: 25,
                          sizePerPage: 10,
                          sizePerPageList: [5, 10, 25, 50]
                        })}
                      />
                    </CardBody>
                  </div>
                )}
              </ToolkitProvider>
            </Card>
          </Col>
        </Row>
      </Container>)
  }
}

export default withTranslation('common')(Qr_Code_list);
