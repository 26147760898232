import React, { Component } from 'react';
import { Card, CardBody, Col, Container, Row, Button } from 'reactstrap';
import { Link } from 'react-router-dom';
import { withTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import showResults from '../Show';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import moment from 'moment';
import Datatable from 'react-bs-datatable';
import { MDBDataTable } from 'mdbreact';
import * as myConstClass from '../../../constant.js';
import 'font-awesome/css/font-awesome.min.css';

import BootstrapTable from "react-bootstrap-table-next";
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";
import paginationFactory from "react-bootstrap-table2-paginator";
import '../../../style_css.css';
const { SearchBar } = Search;
const tableColumns = [
  {
    dataField: "sr_no",
    text: "#",
    sort: true,
    headerStyle: { width: "5%" }
  },
  {
    dataField: "alert_id",
    text: "Alert Id",
    sort: true,
    headerStyle: { width: "5%" }
  },
  {
    dataField: "first_name",
    text: "Name",
    sort: true,
    headerStyle: { width: "10%" }
  },
  {
    dataField: "email",
    text: "Email",
    sort: true,
    headerStyle: { width: "10%" }
  },
  {
    dataField: "mobile_no",
    text: "Mobile",
    sort: true,
    headerStyle: { width: "10%" }
  },
  {
    dataField: "user_lat",
    text: "Latitude",
    sort: true,
    headerStyle: { width: "10%" }
  },
  {
    dataField: "user_long",
    text: "Longitude",
    sort: true,
    headerStyle: { width: "10%" }
  },
  {
    dataField: "timestamp",
    text: "Date/Time",
    sort: true,
    headerStyle: { width: "10%" }
  },
  {
    dataField: "panic_type_value",
    text: "Panic type",
    sort: true,
    headerStyle: { width: "10%" }
  },
  {
    dataField: "tenant_name",
    text: "Tenant",
    sort: true,
    headerStyle: { width: "10%" }
  },
  {
    dataField: "action",
    text: "Panic Evidences",
    headerStyle: { width: "10%" },
    csvExport: false
  },
];
const tableColumnsTenant = [
  {
    dataField: "sr_no",
    text: "#",
    sort: true,
    headerStyle: { width: "5%" }
  },
  {
    dataField: "alert_id",
    text: "Alert Id",
    sort: true,
    headerStyle: { width: "5%" }
  },
  {
    dataField: "first_name",
    text: "Name",
    sort: true,
    headerStyle: { width: "10%" }
  },
  {
    dataField: "email",
    text: "Email",
    sort: true,
    headerStyle: { width: "10%" }
  },
  {
    dataField: "mobile_no",
    text: "Mobile",
    sort: true,
    headerStyle: { width: "10%" }
  },
  {
    dataField: "user_lat",
    text: "Latitude",
    sort: true,
    headerStyle: { width: "10%" }
  },
  {
    dataField: "user_long",
    text: "Longitude",
    sort: true,
    headerStyle: { width: "10%" }
  },
  {
    dataField: "timestamp",
    text: "Date/Time",
    sort: true,
    headerStyle: { width: "10%" }
  },
  {
    dataField: "panic_type_value",
    text: "Panic type",
    sort: true,
    headerStyle: { width: "10%" }
  },
  {
    dataField: "action",
    text: "Panic Evidences",
    headerStyle: { width: "10%" },
    csvExport: false
  },
];

const image = `${process.env.PUBLIC_URL}/img/map-location.png`;
class Evidence_list extends Component {
  constructor(props) {
    super(props);
    this.state = {
      posts: [],
      formData: [],
      tid: "",
      curr_page_id: '',
      prev_page_id: '',
      next_page_id: '',
      isLoaded: false,
    }
    this.flag = 0;
    this.header = [
      { title: 'Sr. No.', prop: 'idd', filterable: true },
      { title: 'Employee Name', prop: 'first_name', filterable: true },
      { title: 'Employee SAP Code', prop: 'sap_code' },
      { title: 'Employee Mobile No.', prop: 'mobile_no' },
      { title: 'Employee Email', prop: 'email' },
      { title: 'Latitude', prop: 'user_lat' },
      { title: 'Longitude', prop: 'user_long' },
      { title: 'Date', prop: 'date' },
      { title: 'Time', prop: 'time' },
      { title: 'Panic Evidence', prop: 'action', cell: (row) => <div><center><i id={row.id} className="fa fa-eye ptr_css" onClick={() => this.view(row.id)}></i>&nbsp;&nbsp;&nbsp;&nbsp;<a target="_blank" href={"https://www.google.com/maps/search/?api=1&query=" + row.user_lat + "," + row.user_long}><img id={row.id} src={image} style={{ width: '20px', height: '16px' }} /></a></center></div> },
    ];

    this.customLabels = {
      first: '<<',
      last: '>>',
      prev: '<',
      next: '>',
      show: 'Display',
      entries: 'rows',
      noResults: 'There is no data to be displayed',
    };
  }

  view(e) {
    this.props.history.push('/forms/evidence_view/' + e);
  }

  edit(e) {
    this.props.history.push('/forms/add/' + e);
  }

  onSortFunction = {
    date(columnValue) {
      // Convert the string date format to UTC timestamp
      // So the table could sort it by number instead of by string
      return moment(columnValue, 'Do MMMM YYYY').valueOf();
    },
  };

  downloadpdf(e, content_type) {
    toast('PDF file is generating.', { containerId: 'B', type: toast.TYPE.SUCCESS })
    const data = new FormData()
    data.append('e_id', e);
    data.append('content_type', content_type);
    fetch(myConstClass.BASE_URL + `/user/downloadpdf`, {
      method: 'POST',
      body: data,
    }).then(response => response.json()).then((response) => {
      if (response) {
        //  toast('Pdf is generating', {containerId: 'B',type: toast.TYPE.SUCCESS})
        window.open(response.pdf_url, "_blank");
      } else {
        //toast('Pdf generating Failed..!', {containerId: 'B',type: toast.TYPE.ERROR})
      }
    }).catch();
  }

  downloadzip(e) {
    toast('Zip file is generating.', { containerId: 'B', type: toast.TYPE.SUCCESS })
    const data = new FormData()
    data.append('e_id', e);
    fetch(myConstClass.BASE_URL + `/user/downloadzip`, {
      method: 'POST',
      body: data,
    }).then(response => response.json()).then((response) => {
      if (response) {
        //  toast('Pdf is generating', {containerId: 'B',type: toast.TYPE.SUCCESS})
        window.open(response.zip_url, 'Download');
      } else {
        //toast('Zip file generating Failed..!', {containerId: 'B',type: toast.TYPE.ERROR})
      }
    }).catch();
  }
  viewFollowMeEvidence(e) {
    this.props.history.push('/forms/followme_evidence_list/' + e);
  }

  componentDidMount() {
    var tid = 1;
    if (localStorage.getItem('uid')) {
      tid = localStorage.getItem('uid');
      this.setState({ tid: tid })
    }
    fetch(myConstClass.BASE_URL + '/user/getUserEvidence/' + tid+ '/' + 1)
      .then(response => response.json())
      .then(response => {
        let i = 1;
        response.forEach(element => {
          var id = element.id;
          var followme_id = element.followme_id;
          var content_type = element.content_type;
          //  element.action = <div><center><i id={id} className="fa fa-eye ptr_css" onClick={() =>this.view(id)}></i>&nbsp;&nbsp;&nbsp;&nbsp;<a target="_blank" href={"https://www.google.com/maps/search/?api=1&query=" + element.user_lat +","+element.user_long}><img id={id} src={image} style={{width: '20px',height: '16px'}} /></a></center></div>;
          if (element.content_type != 4) {
            element.action = <div><center><i id={id} className="fa fa-eye ptr_css" onClick={() => this.view(id)}></i>&nbsp;&nbsp;<a target="_blank" href={"https://www.google.com/maps/search/?api=1&query=" + element.user_lat + "," + element.user_long}><img id={id} src={image} style={{ width: '20px', height: '16px' }} /></a>&nbsp;&nbsp;<i id={id} className="fa fa-file-pdf-o ptr_css" onClick={() => this.downloadpdf(id, content_type)}></i>&nbsp;
              <i id={id} className="fa fa-download ptr_css" onClick={() => this.downloadzip(id)}></i>
              &nbsp;&nbsp;
            </center></div>;
          } else {
            element.action = <div><center><i id={id} className="fa fa-eye ptr_css" onClick={() => this.view(id)}></i>&nbsp;&nbsp;<a target="_blank" href={"https://www.google.com/maps/search/?api=1&query=" + element.user_lat + "," + element.user_long}><img id={id} src={image} style={{ width: '20px', height: '16px' }} /></a>&nbsp;&nbsp;<i id={id} className="fa fa-file-pdf-o ptr_css" onClick={() => this.downloadpdf(id, content_type)}></i>&nbsp;
              &nbsp;&nbsp;
              <i id={id} className="fa fa-code-fork ptr_css" onClick={() => this.viewFollowMeEvidence(followme_id)}></i>
            </center></div>;
          }
          element.id = i;
          element.alert_id = id;
          if (element.is_read == "1") {
            element.id = <span className='panic-text-highlight'>{element.id}</span>
            element.alert_id = <span className='panic-text-highlight'>{element.alert_id}</span>
            element.first_name = <span className='panic-text-highlight'>{element.first_name}</span>
            element.email = <span className='panic-text-highlight'>{element.email}</span>
            element.mobile_no = <span className='panic-text-highlight'>{element.mobile_no}</span>
            element.user_lat = <span className='panic-text-highlight'>{element.user_lat}</span>
            element.user_long = <span className='panic-text-highlight'>{element.user_long}</span>
            element.timestamp = <span className='panic-text-highlight'>{element.timestamp}</span>
            element.panic_type_value = <span className='panic-text-highlight'>{element.panic_type_value}</span>
            element.tenant_name = <span className='panic-text-highlight'>{element.tenant_name}</span>
          }
          i++;
        });
        this.setState({ formData: response })
        this.setState({ curr_page_id: response[0]['curr_page_id'] });
        this.setState({ prev_page_id: response[0]['prv_page_id'] });
        this.setState({ next_page_id: response[0]['nxt_page_id'] });
      })
      .catch(err => console.log(err))

    if (!localStorage.getItem('session_id')) {
      console.log('History:', this.props);
      this.props.history.push('/log_in');
    }

    // this.interval = setInterval(() => this.getUpdatedRecord(), 50000);
  }

  getUpdatedRecord() {
    var tid = 1;
    if (localStorage.getItem('uid')) {
      tid = localStorage.getItem('uid');
      this.setState({ tid: tid })
    }
    fetch(myConstClass.BASE_URL + '/user/getUserEvidence/' + tid)
      .then(response => response.json())
      .then(response => {
        let i = 1;
        response.forEach(element => {
          var id = element.id;
          var followme_id = element.followme_id;
          var content_type = element.content_type;
          //  element.action = <div><center><i id={id} className="fa fa-eye ptr_css" onClick={() =>this.view(id)}></i>&nbsp;&nbsp;&nbsp;&nbsp;<a target="_blank" href={"https://www.google.com/maps/search/?api=1&query=" + element.user_lat +","+element.user_long}><img id={id} src={image} style={{width: '20px',height: '16px'}} /></a></center></div>;
          if (element.content_type != 4) {
            element.action = <div><center><i id={id} className="fa fa-eye ptr_css" onClick={() => this.view(id)}></i>&nbsp;&nbsp;<a target="_blank" href={"https://www.google.com/maps/search/?api=1&query=" + element.user_lat + "," + element.user_long}><img id={id} src={image} style={{ width: '20px', height: '16px' }} /></a>&nbsp;&nbsp;<i id={id} className="fa fa-file-pdf-o ptr_css" onClick={() => this.downloadpdf(id, content_type)}></i>&nbsp;
              <i id={id} className="fa fa-download ptr_css" onClick={() => this.downloadzip(id)}></i>
              &nbsp;&nbsp;
            </center></div>;
          } else {
            element.action = <div><center><i id={id} className="fa fa-eye ptr_css" onClick={() => this.view(id)}></i>&nbsp;&nbsp;<a target="_blank" href={"https://www.google.com/maps/search/?api=1&query=" + element.user_lat + "," + element.user_long}><img id={id} src={image} style={{ width: '20px', height: '16px' }} /></a>&nbsp;&nbsp;<i id={id} className="fa fa-file-pdf-o ptr_css" onClick={() => this.downloadpdf(id, content_type)}></i>&nbsp;
              &nbsp;&nbsp;
              <i id={id} className="fa fa-code-fork ptr_css" onClick={() => this.viewFollowMeEvidence(followme_id)}></i>
            </center></div>;
          }
          element.id = i;
          element.alert_id = id;
          if (element.is_read == "1") {
            element.id = <span className='panic-text-highlight'>{element.id}</span>
            element.alert_id = <span className='panic-text-highlight'>{element.alert_id}</span>
            element.first_name = <span className='panic-text-highlight'>{element.first_name}</span>
            element.email = <span className='panic-text-highlight'>{element.email}</span>
            element.mobile_no = <span className='panic-text-highlight'>{element.mobile_no}</span>
            element.user_lat = <span className='panic-text-highlight'>{element.user_lat}</span>
            element.user_long = <span className='panic-text-highlight'>{element.user_long}</span>
            element.timestamp = <span className='panic-text-highlight'>{element.timestamp}</span>
            element.panic_type_value = <span className='panic-text-highlight'>{element.panic_type_value}</span>
            element.tenant_name = <span className='panic-text-highlight'>{element.tenant_name}</span>
          }
          i++;
        });
        this.setState({ formData: response })
      })
      .catch(err => console.log(err))
  }
  GetPageRecords(next_page_id) {
    this.setState({ isLoaded: false })
    var tid = 1;
    if (localStorage.getItem('uid')) {
      tid = localStorage.getItem('uid');
      this.setState({ tid: tid })
    }
    fetch(myConstClass.BASE_URL + '/user/getUserEvidence/' + tid + '/' + next_page_id)
      .then(response => response.json())
      .then(response => {
        let i = 1;
        response.forEach(element => {
          var id = element.id;
          var followme_id = element.followme_id;
          var content_type = element.content_type;
          //  element.action = <div><center><i id={id} className="fa fa-eye ptr_css" onClick={() =>this.view(id)}></i>&nbsp;&nbsp;&nbsp;&nbsp;<a target="_blank" href={"https://www.google.com/maps/search/?api=1&query=" + element.user_lat +","+element.user_long}><img id={id} src={image} style={{width: '20px',height: '16px'}} /></a></center></div>;
          if (element.content_type != 4) {
            element.action = <div><center><i id={id} className="fa fa-eye ptr_css" onClick={() => this.view(id)}></i>&nbsp;&nbsp;<a target="_blank" href={"https://www.google.com/maps/search/?api=1&query=" + element.user_lat + "," + element.user_long}><img id={id} src={image} style={{ width: '20px', height: '16px' }} /></a>&nbsp;&nbsp;<i id={id} className="fa fa-file-pdf-o ptr_css" onClick={() => this.downloadpdf(id, content_type)}></i>&nbsp;
              <i id={id} className="fa fa-download ptr_css" onClick={() => this.downloadzip(id)}></i>
              &nbsp;&nbsp;
            </center></div>;
          } else {
            element.action = <div><center><i id={id} className="fa fa-eye ptr_css" onClick={() => this.view(id)}></i>&nbsp;&nbsp;<a target="_blank" href={"https://www.google.com/maps/search/?api=1&query=" + element.user_lat + "," + element.user_long}><img id={id} src={image} style={{ width: '20px', height: '16px' }} /></a>&nbsp;&nbsp;<i id={id} className="fa fa-file-pdf-o ptr_css" onClick={() => this.downloadpdf(id, content_type)}></i>&nbsp;
              &nbsp;&nbsp;
              <i id={id} className="fa fa-code-fork ptr_css" onClick={() => this.viewFollowMeEvidence(followme_id)}></i>
            </center></div>;
          }
          element.id = i;
          element.alert_id = id;
          if (element.is_read == "1") {
            element.id = <span className='panic-text-highlight'>{element.id}</span>
            element.alert_id = <span className='panic-text-highlight'>{element.alert_id}</span>
            element.first_name = <span className='panic-text-highlight'>{element.first_name}</span>
            element.email = <span className='panic-text-highlight'>{element.email}</span>
            element.mobile_no = <span className='panic-text-highlight'>{element.mobile_no}</span>
            element.user_lat = <span className='panic-text-highlight'>{element.user_lat}</span>
            element.user_long = <span className='panic-text-highlight'>{element.user_long}</span>
            element.timestamp = <span className='panic-text-highlight'>{element.timestamp}</span>
            element.panic_type_value = <span className='panic-text-highlight'>{element.panic_type_value}</span>
            element.tenant_name = <span className='panic-text-highlight'>{element.tenant_name}</span>
          }
          i++;
        });
        this.setState({ formData: response, isLoaded: true })
        this.setState({ curr_page_id: response[0]['curr_page_id'] });
        this.setState({ prev_page_id: response[0]['prv_page_id'] });
        this.setState({ next_page_id: response[0]['nxt_page_id'] });
      })
      .catch(err => console.log(err))
  }
  render() {
    const { tid, curr_page_id, prev_page_id, next_page_id, isLoaded } = this.state;

    return (
      <Container>
        <Row>
          <Col md={12}>
            <h3 className="page-title">All Evidences
            </h3>
          </Col>
          <ToastContainer enableMultiContainer containerId={'A'} position={toast.POSITION.BOTTOM_LEFT} />
          <ToastContainer enableMultiContainer containerId={'B'} position={toast.POSITION.TOP_RIGHT} />
        </Row>
        <Row>
          <Col md={12} lg={12}>
            <Card>
              {tid == 1 ? (
                <ToolkitProvider
                  keyField="name"
                  data={this.state.formData}
                  columns={tableColumns}
                  exportCSV
                  search
                >
                  {props => (
                    <div>
                      <CardBody>
                        <div style={{ float: 'right' }}  > Abbreviation - 1.NA: No Action, 2.NO: NO</div>
                        <SearchBar {...props.searchProps} />
                        <BootstrapTable
                          {...props.baseProps}
                          bootstrap4
                          bordered={false}
                          // pagination={paginationFactory({
                          //   // sizePerPage: 25,
                          //   sizePerPage: 10,
                          //   sizePerPageList: [5, 10, 25, 50]
                          // })}
                        />
                         <div style={{ justifyContent: "center", display: "flex", marginTop: '10px' }}>
                              {this.state.prev_page_id == 0 ? (null
                              ) : <button class="page-link" onClick={() => this.GetPageRecords(prev_page_id)} >{"<<"}   Previous Page </button>}
                              &nbsp;&nbsp;&nbsp;
                              <button class=" table_active_page"  >{curr_page_id} </button>
                              &nbsp;&nbsp;&nbsp;
                              {this.state.next_page_id == 0 ? (null
                              ) : <button class="page-link" onClick={() => this.GetPageRecords(next_page_id)} >  Next Page {">>"} </button>}
                            </div>
                      </CardBody>
                    </div>
                  )}
                </ToolkitProvider>
              ) : (
                <ToolkitProvider
                  keyField="name"
                  data={this.state.formData}
                  columns={tableColumnsTenant}
                  exportCSV
                  search
                >
                  {props => (
                    <div>
                      <CardBody>
                        <div style={{ float: 'right' }}  > Abbreviation - 1.NA: No Action, 2.NO: NO</div>
                        <SearchBar {...props.searchProps} />
                        <BootstrapTable
                          {...props.baseProps}
                          bootstrap4
                          bordered={false}
                          pagination={paginationFactory({
                            // sizePerPage: 25,
                            sizePerPage: 10,
                            sizePerPageList: [5, 10, 25, 50]
                          })}
                        />
                      </CardBody>
                    </div>
                  )}
                </ToolkitProvider>
              )}
              {/* <CardBody> */}

              {/* <Datatable
  tableHeader={this.header}
  tableBody={this.state.posts}
  keyName="userTable"
  tableClass="striped hover responsive"
  rowsPerPage={10}
  rowsPerPageOption={[5, 10, 15, 20]}
  initialSort={{prop: "`title`", isAscending: true}}
  onSort={this.onSortFunction}
  labels={this.customLabels}
  
/> */}
              {/* <MDBDataTable
          striped
          hover
          data={this.state.posts}
        /> */}

              {/* </CardBody> */}
            </Card>
          </Col>
        </Row>
      </Container>)
  }
}

export default withTranslation('common')(Evidence_list);
