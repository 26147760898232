import React, { Component } from 'react';
import { Col, Container, Row, Button, Card, CardBody, ButtonToolbar, Input } from 'reactstrap';
import { withTranslation } from 'react-i18next';
import TextField from '@material-ui/core/TextField';
import PropTypes from 'prop-types';
import * as myConstClass from '../../../constant.js';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Field, reduxForm } from 'redux-form';
import { ValidatorForm, TextValidator } from 'react-material-ui-form-validator';
import InputLabel from '@material-ui/core/InputLabel';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControl from '@material-ui/core/FormControl';
import { DatePicker } from "material-ui-pickers";
import DateFnsUtils from '@date-io/date-fns';
import { MuiPickersUtilsProvider } from 'material-ui-pickers';
import { Editor } from 'react-draft-wysiwyg';
import { EditorState, convertToRaw, convertFromRaw } from 'draft-js';
import draftToHtml from 'draftjs-to-html';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';

import renderRadioButtonField from '../../../shared/components/form/RadioButton';
import { MultipleSelect } from "react-select-material-ui";

const MAX_LENGTH = 500;

const renderTextField = ({
  input, label, meta: { touched, error }, children, select,
}) => (
  <TextField
    className="material-form__field"
    label={label}
    error={touched && error}
    value={input.value}
    name={input.name}
    children={children}
    select={select}
    onChange={(e) => {
      e.preventDefault();
      alert('in..' + e.target.value);
      input.onChange(e.target.value);
    }}
  />
);

renderTextField.propTypes = {
  input: PropTypes.shape().isRequired,
  label: PropTypes.string.isRequired,
  meta: PropTypes.shape({
    touched: PropTypes.bool,
    error: PropTypes.string,
  }),
  select: PropTypes.bool,
  children: PropTypes.arrayOf(PropTypes.element),
};

renderTextField.defaultProps = {
  meta: null,
  select: false,
  children: [],
};

class Bulletin_news_add extends Component {
  constructor(props) {
    super(props);
    this.state = {
      date: Date.now(),
      formData: {
        news_date: Date.now(),
        selectedImage: '',
        selectedVideo: '',
        selectedAudio: '',
      },
      submitted: false,
      isButtonDisabled: true,
      isButtonDisabled1: true,
      editorState: EditorState.createEmpty(),
      textremaining: 500,
      errors: {},
      type: 1,
      bulletin_tag: [],
    }
  }
  onEditorStateChange = (editorState) => {
    const currentContent = this.state.editorState.getCurrentContent();
    const currentContentLength = currentContent.getPlainText('').length;
    this.setState({ textremaining: 500 - currentContentLength })
    this.setState({
      editorState,
    });
    this.editor_description = JSON.stringify(convertToRaw(this.state.editorState.getCurrentContent()));
  };
  componentDidMount() {
    var tid = 1;
    if (localStorage.getItem('uid')) {
      tid = localStorage.getItem('uid');
    }
    fetch(myConstClass.BASE_URL + '/bulletin_news/getAllBulletinTagTitle/' + tid)
      .then(response => response.json())
      .then(response => { this.setState({ bulletin_tag: response }) }).catch(err => console.log(err))

    if (!localStorage.getItem('session_id')) {
      this.props.history.push('/log_in');
    }
  }

  handleChange = (event) => {
    const { formData } = this.state;
    if (event.target.name == "image") {
      formData[event.target.name] = event.target.files[0];
    } else {
      formData[event.target.name] = event.target.value;
    }
    this.setState({ formData });
  }

  onImageFileChange = event => {
    const errors = {};
    const size = (event.target.files[0].size / 1024).toFixed(2);
    const fsize = (event.target.files[0].size).toFixed(2);
    const current_file_size = Math.round((fsize / 1024));

    // if (size > 500) {
    if (current_file_size > 5120) {
      alert("The file must be less than 5 mb");
      toast('The file must be less than 5 mb', { containerId: 'B', type: toast.TYPE.ERROR })
      errors["image_error"] = "The file must be less than 5 mb";
    } else {
      errors["image_error"] = "";
    }
    this.setState({ selectedImage: event.target.files[0] });
    this.setState({ errors: errors });
  };

  onImageFileChange2 = (event) => {
    const { formData } = this.state;
    formData['image'] = event.target.value;
    this.setState({ formData });

    if (this.state.selectedFile == "") {
      toast('Please select file', { containerId: 'B', type: toast.TYPE.ERROR })
    } else {
      // Create an object of formData 
      const formData = new FormData();
      // Update the formData object 
      formData.append(
        "morning_greetings_file",
        this.state.selectedFile,
        this.state.selectedFile.name
      );
    }
  }

  handleDateChange = (news_date) => {
    const { formData } = this.state;
    formData['news_date'] = news_date;
    this.setState({ formData });
  };

  handleSubmit = () => {
    let form = document.querySelector('#pform');
    const errors = {};

    const formData = new FormData(form);
    if (this.state.selectedImage !== undefined) {


      if (typeof this.state.selectedImage != 'undefined' || this.state.selectedImage != "") {
        const size = (this.state.selectedImage.size / 1024).toFixed(2);
        //if (size > 500) {

        const fsize = (this.state.selectedImage.size).toFixed(2);
        const current_file_size = Math.round((fsize / 1024));

        if (current_file_size > 5120) {
          toast('The file must be less than 5 mb', { containerId: 'B', type: toast.TYPE.ERROR })
          errors["image_error"] = "The file must be less than 5 mb";
          this.setState({ errors: errors });
        } else {
          errors["image_error"] = "";
          this.setState({ errors: errors });

          formData.append(
            "selectedImage",
            this.state.selectedImage,
            this.state.selectedImage.name
          );
        }
      }
    }
    //selectedVideo
    if (this.state.selectedVideo !== undefined) {
      if (typeof this.state.selectedVideo != 'undefined' || this.state.selectedVideo != "") {
        const size = (this.state.selectedVideo.size / 1024 / 1024).toFixed(2);

        const fsize = (this.state.selectedVideo.size).toFixed(2);
        const current_file_size = Math.round((fsize / 1024));

        // if (size > 2) { 
        if (current_file_size > 51200) {
          toast('The file must be less than 50 mb', { containerId: 'B', type: toast.TYPE.ERROR })
          errors["video_error"] = "The file must be less than 50 mb";
          this.setState({ errors: errors });
        } else {
          errors["video_error"] = "";
          this.setState({ errors: errors });

          formData.append(
            "selectedVideo",
            this.state.selectedVideo,
            this.state.selectedVideo.name
          );
        }
      }
    }
    //selectedAudio
    if (this.state.selectedAudio !== undefined) {
      if (typeof this.state.selectedAudio != 'undefined' || this.state.selectedAudio != "") {
        const size = (this.state.selectedAudio.size / 1024 / 1024).toFixed(2);

        const fsize = (this.state.selectedAudio.size).toFixed(2);
        const current_file_size = Math.round((fsize / 1024));

        // if (size > 2) { 
        if (current_file_size > 5120) {
          toast('The file must be less than 5 mb', { containerId: 'B', type: toast.TYPE.ERROR })
          errors["audio_error"] = "The file must be less than 5 mb";
          this.setState({ errors: errors });
        } else {
          errors["audio_error"] = "";
          this.setState({ errors: errors });

          formData.append(
            "selectedAudio",
            this.state.selectedAudio,
            this.state.selectedAudio.name
          );
        }
      }
    }
    if (this.state.video_thumbnail_selected_image !== undefined) {
      if (typeof this.state.video_thumbnail_selected_image != 'undefined' || this.state.video_thumbnail_selected_image != "") {
        const size = (this.state.video_thumbnail_selected_image.size / 1024).toFixed(2);
        if (size > 500) {
          toast('The file must be less than 500 kb', { containerId: 'B', type: toast.TYPE.ERROR })
          errors["video_thumbnail_image_error"] = "The file must be less than 500 kb";
          this.setState({ errors: errors });
        } else {
          errors["video_thumbnail_image_error"] = "";
          this.setState({ errors: errors });
          formData.append(
            "video_thumbnail_selected_image",
            this.state.video_thumbnail_selected_image,
            this.state.video_thumbnail_selected_image.name
          );
        }
      }
    }
    if (this.state.audio_thumbnail_selected_image !== undefined) {
      if (typeof this.state.audio_thumbnail_selected_image != 'undefined' || this.state.audio_thumbnail_selected_image != "") {
        const size = (this.state.audio_thumbnail_selected_image.size / 1024).toFixed(2);
        if (size > 500) {
          toast('The file must be less than 500 kb', { containerId: 'B', type: toast.TYPE.ERROR })
          errors["audio_thumbnail_image_error"] = "The file must be less than 500 kb";
          this.setState({ errors: errors });
        } else {
          errors["audio_thumbnail_image_error"] = "";
          this.setState({ errors: errors });
          formData.append(
            "audio_thumbnail_selected_image",
            this.state.audio_thumbnail_selected_image,
            this.state.audio_thumbnail_selected_image.name
          );
        }
      }
    }
    this.setState({ submitted: true }, () => {
      if (localStorage.getItem('uid')) {
        formData.append('user_id', localStorage.getItem('uid'));
      }

      setTimeout(() => this.setState({ submitted: false }), 5000);
      fetch(myConstClass.BASE_URL + `/bulletin_news/AddBulletinNewsInfo`, {
        method: 'POST',
        body: formData,
        // body: JSON.stringify($('#pform').serializeArray()),
      }).then(response => response.json()).then((response) => {
        if (response == true) {
          this.props.history.push('/forms/bulletin_news/');
          toast('News Board Added Successfully..!', { containerId: 'B', type: toast.TYPE.SUCCESS })
        } else {
          toast('News Board Insertion Failed..!', { containerId: 'B', type: toast.TYPE.ERROR })
        }
      }).catch();
    });
  }

  _handleBeforeInput = () => {
    const currentContent = this.state.editorState.getCurrentContent();
    const currentContentLength = currentContent.getPlainText('').length
    if (currentContentLength > MAX_LENGTH - 1) {
      console.log('you can type max ten characters');
      return 'handled';
    }
  }

  _handlePastedText = (pastedText) => {
    const currentContent = this.state.editorState.getCurrentContent();
    const currentContentLength = currentContent.getPlainText('').length
    if (currentContentLength + pastedText.length > MAX_LENGTH) {
      console.log('you can type max ten characters');
      return 'handled';
    }
  }
  handleRadioChange = (values) => {

    this.setState({ type: values });
    const { formData } = this.state;
    formData['type'] = values;
    this.setState({ formData });
    //alert(this.state.formData.type);
  };
  onVideoFileChange = event => {
    const errors = {};
    //   const size = (event.target.files[0].size / 1024).toFixed(2);
    const size = event.target.files[0].size / 1024 / 1024; // in MiB
    const fsize = event.target.files[0].size;
    const current_file_size = Math.round((fsize / 1024));

    // if (size > 2) { 
    if (current_file_size > 51200) {
      alert("The file must be less than 50 mb");
      toast('The file must be less than 50 mb', { containerId: 'B', type: toast.TYPE.ERROR })
      errors["video_error"] = "The file must be less than 50 mb";
    } else {
      errors["video_error"] = "";
    }
    this.setState({ selectedVideo: event.target.files[0] });
    this.setState({ errors: errors });
  };
  onAudioFileChange = event => {
    const errors = {};
    //   const size = (event.target.files[0].size / 1024).toFixed(2);
    const size = event.target.files[0].size / 1024 / 1024; // in MiB
    const fsize = event.target.files[0].size;
    const current_file_size = Math.round((fsize / 1024));

    if (current_file_size > 5120) {
      alert("The file must be less than 5 mb");
      toast('The file must be less than 5 mb', { containerId: 'B', type: toast.TYPE.ERROR })
      errors["audio_error"] = "The file must be less than 5 mb";
    } else {
      errors["audio_error"] = "";
    }

    event.persist();
    let suppotedVideoFormat = ["audio/mpeg"];
    if (suppotedVideoFormat.includes(event.target.files[0].type)) {
      this.setState({ selectedAudio: event.target.files[0] });
    } else {
      //this.setState({ errCsvEmpty: true, errCsvText: "Unsupported File Format, Please Add Csv File" });
      alert("Unsupported File Format, Please Add audio File");
      errors["audio_error"] = "Unsupported File Format, Please Add audio File";
    }
    this.setState({ errors: errors });
  };

  handleBulletinTagChange = (value) => {
    const { formData } = this.state;
    formData['bulletin_tag'] = value;
    this.setState({ formData });
  };


  onVideoThumbnailImageFileChange = event => {
    const errors = {};
    const size = (event.target.files[0].size / 1024).toFixed(2);
    if (size > 500) {
      alert("The file must be less than 500 kb");
      toast('The file must be less than 500 kb', { containerId: 'B', type: toast.TYPE.ERROR })
      errors["video_thumbnail_image_error"] = "The file must be less than 500 kb";
    } else {
      errors["video_thumbnail_image_error"] = "";
    }
    this.setState({ video_thumbnail_selected_image: event.target.files[0] });
    this.setState({ errors: errors });
  };

  onAudioThumbnailImageFileChange = event => {
    const errors = {};
    const size = (event.target.files[0].size / 1024).toFixed(2);
    if (size > 500) {
      alert("The file must be less than 500 kb");
      toast('The file must be less than 500 kb', { containerId: 'B', type: toast.TYPE.ERROR })
      errors["audio_thumbnail_image_error"] = "The file must be less than 500 kb";
    } else {
      errors["audio_thumbnail_image_error"] = "";
    }
    this.setState({ audio_thumbnail_selected_image: event.target.files[0] });
    this.setState({ errors: errors });
  };
  render() {
    const { formData, submitted, isButtonDisabled, isButtonDisabled1, editorState, type, bulletin_tag } = this.state;
    return (
      <Container>
        <Row>
          <Col md={12}>
            <h3 className="page-title">Add News Board</h3>
          </Col>
        </Row>
        <Row>
          <Card>
            <CardBody>
              <div className="card__title">
              </div>
              <div>
                <ToastContainer enableMultiContainer containerId={'A'} position={toast.POSITION.BOTTOM_LEFT} />
                <ToastContainer enableMultiContainer containerId={'B'} position={toast.POSITION.TOP_RIGHT} />
              </div>
              <ValidatorForm
                className="material-form" id="pform"
                onSubmit={this.handleSubmit}
              >
                <Row>
                  <Col md={1} lg={1}>  <h6>Type :</h6></Col>
                  <Col md={5} lg={5}>

                    <Field
                      name="type"
                      component={renderRadioButtonField}
                      label="Text"
                      radioValue="1"
                      value="1"
                      onChange={this.handleRadioChange}
                      defaultChecked
                    />
                    <Field
                      name="type"
                      component={renderRadioButtonField}
                      label="Image"
                      radioValue="2"
                      value="2"
                      onChange={this.handleRadioChange}
                    />

                    <Field
                      name="type"
                      component={renderRadioButtonField}
                      label="Video"
                      radioValue="3"
                      value="3"
                      onChange={this.handleRadioChange}
                    />

                    <Field
                      name="type"
                      component={renderRadioButtonField}
                      label="Audio"
                      radioValue="4"
                      value="4"
                      onChange={this.handleRadioChange}
                    />
                    <div>
                    </div>
                  </Col>
                </Row>
                <Row>
                  <Col md={6} lg={6}>
                    <TextValidator
                      onChange={this.handleChange}
                      name="title"
                      label="Title"
                      value={formData.title}
                      validators={['required']}
                      errorMessages={['this field is required']}
                      className="material-form__field"
                    />
                    <div>
                    </div>
                  </Col>
                </Row>

                <Row>
                  <Col md={6} lg={6}>
                    <h6> Description</h6>
                    <Editor
                      editorState={editorState}
                      toolbarClassName="toolbarClassName"
                      wrapperClassName="wrapperClassName"
                      editorClassName="editorClassName"
                      onEditorStateChange={this.onEditorStateChange}
                      handleBeforeInput={this._handleBeforeInput}
                      handlePastedText={this._handlePastedText}
                    />
                    <Input type="hidden" name="description" value={draftToHtml(convertToRaw(editorState.getCurrentContent()))} />
                    <Input type="hidden" name="editor_description" value={this.editor_description} />

                    <hr />
                    <div>
                    </div>
                  </Col>
                </Row>

                <Row>
                  <Col md={6} lg={6} style={{ color: 'red', textAlign: 'right' }}>
                    ({this.state.textremaining} / 500)
                  </Col>
                </Row>


                <Row>
                  <Col md={6} lg={6}>
                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                      <DatePicker
                        className="material-form__field"
                        onChange={this.handleDateChange}
                        name="news_date"
                        placeholder="News Date"
                        label="News Date (yyyy-mm-dd)"
                        value={formData.news_date}
                        format="yyyy-MM-dd"
                      />
                    </MuiPickersUtilsProvider>
                    <div>
                    </div>
                  </Col>
                </Row>
                <Row>
                  <Col md={6} lg={6}>
                    {/* <h6  >Source of news</h6> */}
                    <TextValidator
                      onChange={this.handleChange}
                      name="news_source"
                      label="News Source"
                      value={formData.news_source}
                      validators={['required']}
                      errorMessages={['this field is required']}
                      className="material-form__field"
                    />
                    <div>
                    </div>
                  </Col>
                </Row>

                {type == 2 ? (
                  <Row>
                    <Col md={6} lg={6}>
                      <h6>Select Image</h6>
                      <input type="file"
                        // onChange={this.handleChange}
                        onChange={this.onImageFileChange}
                        name="image"
                        value={formData.image}
                      />
                      <br />
                      <span className="">
                        (Note: The file must be less than 5 mb)
                      </span>
                      <br />
                      <span className="red-text">
                        {this.state.errors["image_error"]}
                      </span>
                      <div>
                      </div>
                    </Col>
                  </Row>
                ) : (""
                )}

                {type == 3 ? (
                  <Row>
                    <Col md={6} lg={6}>
                      <TextValidator
                        onChange={this.handleChange}
                        name="video_path"
                        label="Youtube video URL"
                        value={formData.video_path}
                        validators={['required']}
                        errorMessages={['this field is required']}
                        className="material-form__field"
                      />
                      <div>
                      </div>
                    </Col>

                    {/* <Col md={6} lg={6}>
                      <h6>Select Video</h6>
                      <input type="file"
                        onChange={this.onVideoFileChange}
                        name="video"
                        value={formData.video}
                      />
                      <br />
                      <span className="">
                        (Note: The file must be less than 50 mb)
                      </span>
                      <br />
                      <span className="red-text">
                        {this.state.errors["video_error"]}
                      </span>
                      <div>
                      </div>
                    </Col> */}
                    {/* <Col md={6} lg={6}>
                      <h6>Select Video Thumbnail Image</h6>
                      <input type="file"
                        onChange={this.onVideoThumbnailImageFileChange}
                        name="video_thumbnail_image"
                      />
                      <br />
                      <span className="">
                        (Note: The file must be less than 500 kb)
                      </span>
                      <br />
                      <span className="red-text">
                        {this.state.errors["video_thumbnail_image_error"]}
                      </span>
                      <div>
                      </div>
                    </Col> */}
                  </Row>
                ) : (""
                )}

                {type == 4 ? (
                  <Row>
                    <Col md={6} lg={6}>
                      <h6>Select Audio</h6>
                      <input type="file"
                        onChange={this.onAudioFileChange}
                        name="audio"
                        value={formData.audio}
                      />
                      <br />
                      <span className="">
                        (Note: The file must be less than 5 mb)
                      </span>
                      <br />
                      <span className="red-text">
                        {this.state.errors["audio_error"]}
                      </span>
                      <div>
                      </div>
                    </Col>
                    <Col md={6} lg={6}>
                      <h6>Select Audio Thumbnail Image</h6>
                      <input type="file"
                        onChange={this.onAudioThumbnailImageFileChange}
                        name="audio_thumbnail_image"
                      />
                      <br />
                      <span className="">
                        (Note: The file must be less than 500 kb)
                      </span>
                      <br />
                      <span className="red-text">
                        {this.state.errors["audio_thumbnail_image_error"]}
                      </span>
                      <div>
                      </div>
                    </Col>
                  </Row>
                ) : (""
                )}
                <br />
                <Row>
                  <Col md={6} lg={6}>
                    <br />
                    <h6>News Board Tag</h6>
                    <MultipleSelect
                      label="Choose tag"
                      values={formData.bulletin_tag}
                      options={bulletin_tag}
                      onChange={this.handleBulletinTagChange}
                      helperText="You can search tag"
                      SelectProps={{
                        isCreatable: true,
                        msgNoOptionsAvailable: "All tag are selected",
                        msgNoOptionsMatchFilter: "No tag name matches the filter"
                      }}
                    />
                    <Input type="hidden" name="bulletin_tag" value={formData.bulletin_tag} />

                    <div>
                    </div>
                  </Col>
                </Row>
                <br />
                <Button
                  color="primary"
                  variant="contained"
                  type="submit"
                  disabled={submitted}
                >
                  {
                    (submitted && 'Your form is submitted!')
                    || (!submitted && 'Submit')
                  }
                </Button>
              </ValidatorForm>
            </CardBody>
          </Card>
        </Row>
      </Container>
    )
  }
}

export default reduxForm({
  form: 'floating_labels_form', // a unique identifier for this form
})(withTranslation('common')(Bulletin_news_add));
