import React, { Component } from 'react';
import { Col, Container, Row, Button, Card, CardBody, ButtonToolbar, Input } from 'reactstrap';
import { withTranslation } from 'react-i18next';
import * as myConstClass from '../../../constant.js';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Field, reduxForm } from 'redux-form';
import { ValidatorForm, TextValidator } from 'react-material-ui-form-validator';
import renderToggleButtonField from '../../../shared/components/form/StatusToggleButton';

class Bulletin_tag_add extends Component {
  constructor(props) {
    super(props);
    this.state = {
      formData: {},
      submitted: false,
      errors: {},
    }
  }

  componentDidMount() {
    if (!localStorage.getItem('session_id')) {
      this.props.history.push('/log_in');
    }
  }

  handleChange = (event) => {
    const { formData } = this.state;
    formData[event.target.name] = event.target.value;
    this.setState({ formData });
  }

  handleSubmit = () => {
    let form = document.querySelector('#pform');
    const errors = {};
    const formData = new FormData(form);
    if (localStorage.getItem('uid')) {
      formData.append('user_id', localStorage.getItem('uid'));
    }
    this.setState({ submitted: true }, () => {

      setTimeout(() => this.setState({ submitted: false }), 5000);
      fetch(myConstClass.BASE_URL + `/bulletin_news/checkBulletinTagTitle`, {
        method: 'POST',
        body: formData,
      }).then(response => response.json()).then((response) => {

        if (response == 0) {
          setTimeout(() => this.setState({ submitted: false }), 5000);
          fetch(myConstClass.BASE_URL + `/bulletin_news/AddBulletinTag`, {
            method: 'POST',
            body: formData,
          }).then(response => response.json()).then((response) => {

            if (response == true) {
              this.props.history.push('/forms/bulletin_tag/');
              toast('Bulletin Tag Added Successfully..!', { containerId: 'B', type: toast.TYPE.SUCCESS })
            } else {
              toast('Bulletin Tag Insertion Failed..!', { containerId: 'B', type: toast.TYPE.ERROR })
            }
          }).catch();

        } else {
          toast('Title already exists.', { containerId: 'B', type: toast.TYPE.ERROR })
        }
      }).catch();

    });
  }

  render() {
    const { formData, submitted } = this.state;
    return (
      <Container>
        <Row>

          <Col md={12}>
            <h3 className="page-title">Add News Board Tag</h3>
          </Col>
        </Row>
        <Row>
          <Card>
            <CardBody>
              <div className="card__title">
              </div>
              <div>
                <ToastContainer enableMultiContainer containerId={'A'} position={toast.POSITION.BOTTOM_LEFT} />
                <ToastContainer enableMultiContainer containerId={'B'} position={toast.POSITION.TOP_RIGHT} />
              </div>
              <ValidatorForm
                className="material-form" id="pform"
                onSubmit={this.handleSubmit}
              >
                <Row>
                  <Col md={6} lg={6}>
                    <h6> Title</h6>
                    <TextValidator
                      onChange={this.handleChange}
                      name="title"
                      // label="Title"
                      value={formData.title}
                      validators={['required']}
                      errorMessages={['this field is required']}
                      className="material-form__field"
                    />
                    <div>
                    </div>
                  </Col>
                </Row>
                <Row>
                  <Col md={2} lg={2} style={{ maxWidth: '11.667%' }} >
                    <Field
                      name="status"
                      component={renderToggleButtonField}
                      value={formData.status}
                    />
                  </Col>
                </Row>
                <br />
                <Button
                  color="primary"
                  variant="contained"
                  type="submit"
                  disabled={submitted}
                >
                  {(submitted && 'Your form is submitted!') || (!submitted && 'Submit')}
                </Button>
              </ValidatorForm>
            </CardBody>
          </Card>
        </Row>
      </Container>
    )
  }
}

export default reduxForm({
  form: 'floating_labels_form', // a unique identifier for this form
})(withTranslation('common')(Bulletin_tag_add));
