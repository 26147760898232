import React, { Component } from 'react';
import { Col, Container, Row, Button, Card, CardBody, ButtonToolbar, } from 'reactstrap';
import { withTranslation } from 'react-i18next';
import TextField from '@material-ui/core/TextField';
import MenuItem from '@material-ui/core/MenuItem';
import PropTypes from 'prop-types';

import showResults from '../Show';
import * as myConstClass from '../../../constant.js';
import $ from 'jquery';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Field, reduxForm } from 'redux-form';
import { ValidatorForm, TextValidator } from 'react-material-ui-form-validator';
import renderRadioButtonField from '../../../shared/components/form/RadioButton';
import { DatePicker } from "material-ui-pickers";
import DateFnsUtils from '@date-io/date-fns';
import { MuiPickersUtilsProvider } from 'material-ui-pickers';
import { makeStyles } from '@material-ui/core/styles';
import InputLabel from '@material-ui/core/InputLabel';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import { Link } from 'react-router-dom';
import { Modal } from 'reactstrap';

const renderTextField = ({
  input, label, meta: { touched, error }, children, select,
}) => (
  <TextField
    className="material-form__field"
    label={label}
    error={touched && error}
    value={input.value}
    name={input.name}
    children={children}
    select={select}
    onChange={(e) => {
      e.preventDefault();
      input.onChange(e.target.value);
    }}
  />
);

renderTextField.propTypes = {
  input: PropTypes.shape().isRequired,
  label: PropTypes.string.isRequired,
  meta: PropTypes.shape({
    touched: PropTypes.bool,
    error: PropTypes.string,
  }),
  select: PropTypes.bool,
  children: PropTypes.arrayOf(PropTypes.element),
};

renderTextField.defaultProps = {
  meta: null,
  select: false,
  children: [],
};

class Admin extends Component {

  constructor(props) {
    super(props);
    this.state = {
      date: Date.now(),
      posts: [],
      user_id: '',
      formData: {
        sapcode: '',
        firstname: '',
        middlename: '',
        lastname: '',
        email: '',
        mobile_no: '',
        designation: '',
        department: '',
        branch_name: '',
        username: '',
        password: '',
        vertical: '',
        date_of_birth: '',
        age: '',
        gender: '',
        blood_group: '',
        reporting_manager_sap_code: '',
        reporting_manager_name: '',
        reviewing_manager_sap_code: '',
        reviewing_manager_name: '',
        ho_poc_sap_code: '',
        ho_poc_name: '',
        department_head_sap_code: '',
        department_head_name: '',
        status: '',
        tenant_code: '',
        address: '',
        chatbot_url: '',
      },
      submitted: false,
      dashboard_menu: [],
      checked_menu: [],
      errors: {},
      modal: false,
      curr_check_value: '',
      curr_check_status: '',
      ftr_psd: '',
    }
    //this.handleSubmit = this.handleSubmit.bind(this);
    this.togglePasswordModal = this.togglePasswordModal.bind(this);
    this.handlePasswordSubmit = this.handlePasswordSubmit.bind(this);
    this.checkCheckboxValues = this.checkCheckboxValues.bind(this);
  }
  componentDidMount() {

    const answer_array = this.props.location.pathname.split('/');
    if (answer_array['3']) {
      // alert(answer_array['3']);
      this.state.user_id = answer_array['3'];
      fetch(myConstClass.BASE_URL + '/user/getUpdateAdminInfo/' + answer_array['3'])
        .then(response => response.json())
        .then(response => { this.setState({ formData: response }) }).catch(err => console.log(err))
      // get dashboard menu
      var edit_tenant_id = answer_array['3'];
      fetch(myConstClass.BASE_URL + '/user/getDashboardMenu/' + edit_tenant_id)
        .then(response => response.json())
        .then(response => {
          response.forEach(element => {
            if (element.is_checked == "1") {
              this.setState({
                checked_menu: [...this.state.checked_menu, element.default_dashboard_menu_id]
              });
            }
          });
          this.setState({ dashboard_menu: response })
        }).catch(err => console.log(err))

    } else {
      fetch(myConstClass.BASE_URL + '/user/getDashboardMenu')
        .then(response => response.json())
        .then(response => { this.setState({ dashboard_menu: response }) }).catch(err => console.log(err))

    }

    fetch(myConstClass.BASE_URL + '/user/getSettingById/2')
      .then(response => response.json())
      .then(response => {
        this.setState({ ftr_psd: atob(response.result) });
      }).catch(err => console.log(err))

    if (!localStorage.getItem('session_id')) {
      console.log('History:', this.props);
      this.props.history.push('/log_in');
    }

  }
  handleChange = (event) => {
    const { formData } = this.state;
    formData[event.target.name] = event.target.value;
    this.setState({ formData });
  }
  handleDateChange = (date) => {

    const { formData } = this.state;
    formData['date_of_birth'] = date;
    var ageDifMs = Date.now() - date.getTime();
    var ageDate = new Date(ageDifMs); // miliseconds from epoch
    var age = Math.abs(ageDate.getUTCFullYear() - 1970);//alert(age);
    formData['age'] = age;
    this.setState({ formData });
  };

  handleSubmit = () => {
    const errors = {};
    var checked_menu = this.state.checked_menu;
    if (checked_menu.length <= 0) {
      errors["privileges_check_error"] = "Please check at least one feature.";
    } else {
      errors["privileges_check_error"] = "";
      const { formData } = this.state;
      var checked_menu = this.state;
      formData['checked_menu'] = checked_menu;
      this.setState({ formData });

      this.setState({ submitted: true }, () => {

        setTimeout(() => this.setState({ submitted: false }), 5000);
        fetch(myConstClass.BASE_URL + `/user/add_admin/` + this.state.user_id, {
          method: 'POST',
          body: JSON.stringify($('#pform').serializeArray()),
        }).then(response => response.json()).then((response) => {
          if (response == true) {
            toast('User Added Successfully..!', { containerId: 'B', type: toast.TYPE.SUCCESS })
            this.props.history.push('/forms/admin_list/');
          } else {
            toast('User Insertion Failed..!', { containerId: 'B', type: toast.TYPE.ERROR })
          }
        })
          .catch();
      });
    }
    this.setState({ errors: errors });
  }

  handleStartDateChange = (date) => {
    const { formData } = this.state;
    formData['start_date'] = date;
    this.setState({ formData });
  };

  handleCheckboxChange = (event) => {
    var password_check = event.target.getAttribute("password_check");
    this.setState({ curr_check_value: '', curr_check_status: '' });
    var event_checked = false;
    if (password_check == 1 && this.state.ftr_psd != "") {
      if (event.target.checked == false) {
        event_checked = false;
        event.target.checked = true;
      } else {
        event_checked = true;
        event.target.checked = false;
      }
      this.setState({ curr_check_value: event.target.value, curr_check_status: event_checked });
      this.togglePasswordModal();
    } else {

      if (event.target.checked == true) {
        this.setState({
          checked_menu: [
            ...this.state.checked_menu,
            event.target.value
          ]
        });
      }
      if (event.target.checked == false) {
        var array = [...this.state.checked_menu];
        var index = array.indexOf(event.target.value)
        if (index !== -1) {
          array.splice(index, 1);
          this.setState({ checked_menu: array });
        }
      }
    }
  }

  togglePasswordModal() {
    this.setState(prevState => ({ modal: !prevState.modal }));
  }

  handlePasswordSubmit() {
    var password = $("#password").val();
    if (password == this.state.ftr_psd) {
      var curr_check_value = $("#curr_check_value").val();
      var curr_check_status = $("#curr_check_status").val();
      if (curr_check_status == "true") {
        $("#check_" + curr_check_value).prop('checked', true);
      } else {
        $("#check_" + curr_check_value).prop('checked', false);
      }
      this.checkCheckboxValues();
    } else {
      toast('Incorrect Password.', { containerId: 'B', type: toast.TYPE.ERROR })
    }
  }

  checkCheckboxValues() {
    var curr_check_value = $("#curr_check_value").val();
    var curr_check_status = $("#curr_check_status").val();
    console.log(curr_check_value);
    console.log(curr_check_status);
    if (curr_check_status == "true") {
      this.setState({
        checked_menu: [
          ...this.state.checked_menu,
          curr_check_value
        ]
      });
    }
    if (curr_check_status == "false") {
      var array = [...this.state.checked_menu];
      var index = array.indexOf(curr_check_value)
      if (index !== -1) {
        array.splice(index, 1);
        this.setState({ checked_menu: array });
      }
    }
    this.setState({ curr_check_value: '', curr_check_status: '' });
    this.setState(prevState => ({ modal: !prevState.modal }));
  }

  render() {
    const { formData, submitted } = this.state;
    const { posts, dashboard_menu, checked_menu, modal, curr_check_value, curr_check_status } = this.state;

    return (
      <Container>
        <Row>
          <Col md={12}>
            <Modal style={{ position: 'relative', margin: '0 auto', top: '28%', width: '450px' }}
              isOpen={modal}
              toggle={this.toggle}
              modalClassName={`ltr-support`}
              className={`modal-dialog--primary modal-dialog--header`}  >
              <div className="modal__header">
                <button className="lnr lnr-cross modal__close-btn" type="button" onClick={this.toggle} style={{ marginBottom: '5px' }} />
                <h4 className="modal__title white_text">{"Enter Password"}</h4>
              </div>
              <div className="modal__body">
                <div className="form">
                  <ValidatorForm className="material-form" id="lform" style={{ textAlign: "center" }}    >
                    <table className="table">
                      <tr>
                        <td>Enter Password</td>
                        <td><input
                          style={{ width: "auto" }}
                          type="text"
                          placeholder=""
                          required
                          onChange={this.handleChange}
                          name="password"
                          id="password"
                          validators={['required']}
                          errorMessages={['this field is required']}
                        />
                        </td>
                      </tr>
                    </table>
                  </ValidatorForm>
                </div>
              </div>
              <ButtonToolbar className="modal__footer">
                <input type="hidden" name="curr_check_value" id="curr_check_value" value={curr_check_value} />
                <input type="hidden" name="curr_check_status" id="curr_check_status" value={curr_check_status} />
                <Button color="primary" type="submit" onClick={this.handlePasswordSubmit}>Submit</Button>
                <Button className="modal_cancel" onClick={this.togglePasswordModal} id="cancel_btn_id">Cancel</Button>
              </ButtonToolbar>
            </Modal>
          </Col></Row>
        <Row>
          <Col md={12}>
            {/* {this.state.user_id?<h3 className="page-title">Edit Admin{posts.sap_code}</h3>:<h3 className="page-title">Add Admin{posts.sap_code}</h3>} */}
            {this.state.user_id ? <h3 className="page-title">Edit Tenant{posts.sap_code}</h3> : <h3 className="page-title">Add Tenant{posts.sap_code}</h3>}
            {/* <h3 className="page-subhead subhead">Use this elements, if you want to show some hints or additional
              information
        </h3> */}
          </Col>
        </Row>
        <Row>

          <Card>
            <CardBody>
              <div className="card__title">
                {/* <h5 className="bold-text">{t('forms.floating_labels_form.animated_line_inputs')}</h5>*/}
                {/* <h5 className="bold-text">Add Admin/Employee {posts.sap_code}</h5> */}
                {/* <h5 className="subhead">Material fields with a property <span className="red-text">label</span></h5> */}
              </div>
              <div>
                <ToastContainer enableMultiContainer containerId={'A'} position={toast.POSITION.BOTTOM_LEFT} />
                <ToastContainer enableMultiContainer containerId={'B'} position={toast.POSITION.TOP_RIGHT} />

              </div>

              <ValidatorForm
                className="material-form" id="pform"
                onSubmit={this.handleSubmit}
              >
                <Row>
                  <Col md={6} lg={6}>
                    <TextValidator
                      onChange={this.handleChange}
                      name="firstname"
                      placeholder="First name"
                      label="First name"
                      className="material-form__field"
                      value={formData.firstname}
                      validators={['required']}
                      errorMessages={['this field is required']}
                    />
                    <br />
                    <TextValidator
                      onChange={this.handleChange}
                      className="material-form__field"
                      name="middlename"
                      placeholder="Middle name"
                      label="Middle name"
                      value={formData.middlename}
                      validators={['required']}
                      errorMessages={['this field is required']}
                    />
                    <br />
                    <TextValidator
                      onChange={this.handleChange}
                      className="material-form__field"
                      name="lastname"
                      placeholder="Last name"
                      label="Last name"
                      value={formData.lastname}
                      validators={['required']}
                      errorMessages={['this field is required']}
                    />

                    <br />
                    <TextValidator
                      onChange={this.handleChange}
                      className="material-form__field"
                      name="email"
                      placeholder="Email"
                      label="Email"
                      value={formData.email}
                      validators={['required', 'isEmail']}
                      errorMessages={['this field is required', 'Enter valid email address']}
                    />

                    <br />
                    <TextValidator
                      onChange={this.handleChange}
                      className="material-form__field"
                      name="address"
                      placeholder="Address"
                      label="Address"
                      value={formData.address}
                      validators={['required']}
                      errorMessages={['this field is required']}
                    />

                    <br />
                    <TextValidator
                      onChange={this.handleChange}
                      className="material-form__field"
                      name="username"
                      placeholder="Username"
                      label="Username"
                      value={formData.username}
                      validators={['required']}
                      errorMessages={['this field is required']}
                    />

                    <br />
                    <TextValidator
                      onChange={this.handleChange}
                      className="material-form__field"
                      name="password"
                      placeholder="Password"
                      label="Password"
                      value={formData.password}
                      // validators={['required', 'matchRegexp:^[0-9]{6}$']}
                      // errorMessages={['this field is required', 'Enter 6 digit Number..']}
                      validators={['required']}
                      errorMessages={['this field is required']}
                    />


                    <TextValidator
                      onChange={this.handleChange}
                      className="material-form__field"
                      name="mobile_no"
                      placeholder="Mobile No"
                      label="Mobile No"
                      value={formData.mobile_no}
                      validators={['required', 'matchRegexp:^[0-9]{10}$']}
                      errorMessages={['this field is required', 'Enter 10 digit Number..']}
                    />
                    <br />
                    <FormControl className="material-form__field">
                      <InputLabel htmlFor="age-simple">Status</InputLabel>
                      <Select
                        value={formData.status}
                        onChange={this.handleChange}
                        inputProps={{
                          name: 'status',
                          id: 'status',
                        }}
                      >
                        <MenuItem value={1}>Active</MenuItem>
                        <MenuItem value={2}>Inactive</MenuItem>
                        <MenuItem value={3}>Suspended</MenuItem>
                      </Select>
                    </FormControl>
                    <br />
                    <TextValidator
                      onChange={this.handleChange}
                      className="material-form__field"
                      name="tenant_code"
                      placeholder="Tenant code"
                      label="Tenant code"
                      value={formData.tenant_code}
                      validators={['required', 'matchRegexp:^[0-9]{1,6}$']}
                      errorMessages={['this field is required', 'Enter maximum 6 digit Number..']}
                    />
                    <br />
                    {/* added on - 3-aug-2022 */}
                    {this.state.user_id ?
                      <InputLabel htmlFor="pre_licence">Total Licence - 0</InputLabel>
                      : ""}

                    {this.state.user_id ?
                      "" : <MuiPickersUtilsProvider utils={DateFnsUtils}>
                        <DatePicker
                          className="material-form__field"
                          onChange={this.handleStartDateChange}
                          name="start_date"
                          placeholder="Licence Start Date"
                          label="Licence Start Date"
                          // value={this.state.date}
                          format="dd-MM-yyyy"
                        />
                      </MuiPickersUtilsProvider>
                    }
                    <br />
                    {this.state.user_id ?
                      "" : <div>
                        <TextValidator
                          onChange={this.handleChange}
                          className="material-form__field"
                          name="licence"
                          placeholder="Licence"
                          label="Licence"
                          // value={formData.licence}
                          validators={['matchRegexp:^[0-9]{1,6}$']}
                          errorMessages={['Enter maximum 6 digit Number..']}
                        /></div>
                    }
                    <br />

                    <br />
                    <div>
                        <TextValidator
                          onChange={this.handleChange}
                          className="material-form__field"
                          name="chatbot_url"
                          placeholder="Chatbot URL"
                          label="Chatbot URL"
                          value={formData.chatbot_url}
                          // validators={['required']}
                          // errorMessages={['Enter maximum 6 digit Number..']}
                        /></div>
                    
                  </Col>

                  <Col md={6} lg={6}>
                    {/* <TextValidator
                      onChange={this.handleChange}
                      className="material-form__field"
                      name="mobile_no"
                      placeholder="Mobile No"
                      label="Mobile No"
                      value={formData.mobile_no}
                      validators={['required', 'matchRegexp:^[0-9]{10}$']}
                      errorMessages={['this field is required', 'Enter 10 digit Number..']}
                    />
                    <br /> */}
                    {/* <div>
                      <FormControl className="material-form__field">
                        <InputLabel htmlFor="gender">Gender</InputLabel>
                        <Select
                          value={formData.gender}
                          onChange={this.handleChange}
                          inputProps={{
                            name: 'gender',
                            id: 'gender',
                          }}
                        >
                          <MenuItem value={1}>Male</MenuItem>
                          <MenuItem value={2}>Female</MenuItem>
                          <MenuItem value={3}>Others</MenuItem>
                        </Select>
                      </FormControl>
                    </div> */}
                    {/* <div>
                      <FormControl className="material-form__field">
                        <InputLabel htmlFor="blood_group">Blood Group</InputLabel>
                        <Select
                          value={formData.blood_group}
                          onChange={this.handleChange}
                          inputProps={{
                            name: 'blood_group',
                            id: 'blood_group',
                          }}
                        >
                          <MenuItem value={1}>A+</MenuItem>
                          <MenuItem value={2}>B+</MenuItem>
                          <MenuItem value={3}>O+</MenuItem>
                          <MenuItem value={4}>AB+</MenuItem>
                          <MenuItem value={5}>A-</MenuItem>
                          <MenuItem value={6}>B-</MenuItem>
                          <MenuItem value={7}>O-</MenuItem>
                          <MenuItem value={8}>AB-</MenuItem>
                        </Select>
                      </FormControl>
                    </div> */}
                    {/* <MuiPickersUtilsProvider utils={DateFnsUtils}>
                      <DatePicker
                        className="material-form__field"
                        onChange={this.handleDateChange}
                        name="date_of_birth"
                        placeholder="Date of Birth"
                        label="Date of Birth"
                        value={this.state.date}
                        format="dd-MM-yyyy"
                      />
                    </MuiPickersUtilsProvider>

                    <TextValidator
                      onChange={this.handleChange}
                      className="material-form__field"
                      name="age"
                      placeholder="Age"
                      label="Age"
                      value={formData.age}
                      validators={['required', 'matchRegexp:^[0-9]{1,3}$']}
                      errorMessages={['this field is required', 'Enter maximum 3 digit Number..']}
                    />
                    <br /> */}

                    <div>
                      {/* <FormControl className="material-form__field">
                        <InputLabel htmlFor="age-simple">Status</InputLabel>
                        <Select
                          value={formData.status}
                          onChange={this.handleChange}
                          inputProps={{
                            name: 'status',
                            id: 'status',
                          }}
                        >
                          <MenuItem value={1}>Active</MenuItem>
                          <MenuItem value={2}>Inactive</MenuItem>
                          <MenuItem value={3}>Suspended</MenuItem>
                        </Select>
                      </FormControl> */}

                      {/* added on - 5-may-2022 */}
                      {/* <TextValidator
                        onChange={this.handleChange}
                        className="material-form__field"
                        name="tenant_code"
                        placeholder="Tenant code"
                        label="Tenant code"
                        value={formData.tenant_code}
                        validators={['required', 'matchRegexp:^[0-9]{1,6}$']}
                        errorMessages={['this field is required', 'Enter maximum 6 digit Number..']}
                      /> */}
                      {/* <br /> */}

                    </div>

                    {/*
                    <br />
                    <br />
                    <br />
                    <br />
                    <div></div>
                    */}
                    {/* {this.state.user_id ?
                      "" : <MuiPickersUtilsProvider utils={DateFnsUtils}>
                        <DatePicker
                          className="material-form__field"
                          onChange={this.handleStartDateChange}
                          name="start_date"
                          placeholder="Licence Start Date"
                          label="Licence Start Date"
                          // value={this.state.date}
                          format="dd-MM-yyyy"
                        />
                      </MuiPickersUtilsProvider>
                    } */}
                    <InputLabel htmlFor="pre_licence">Privileges</InputLabel>
                    <br />

                    {this.state.dashboard_menu.map((index) =>
                      <div>
                        {index.is_checked == "1" ? (
                          <div>
                            <InputLabel> <input
                              className="toggle-btn__input"
                              type="checkbox"
                              value={index.default_dashboard_menu_id}
                              onChange={this.handleCheckboxChange}
                              // checked={index.dashboard_menu_id}
                              defaultChecked={true}
                              id={"check_" + index.default_dashboard_menu_id}
                              password_check={index.password_check}
                            /> {index.menu_name}</InputLabel>
                          </div>
                        ) : (<div>
                          <InputLabel> <input
                            className="toggle-btn__input"
                            type="checkbox"
                            value={index.default_dashboard_menu_id}
                            onChange={this.handleCheckboxChange}
                            id={"check_" + index.default_dashboard_menu_id}
                            password_check={index.password_check}
                          /> {index.menu_name}</InputLabel>
                        </div>
                        )}
                      </div>
                    )}
                    <input type="hidden" name="checked_menu" value={checked_menu} />
                    <span className="err-red-text">
                      {this.state.errors["privileges_check_error"]}
                    </span>
                  </Col>
                </Row>
                <br />

                <Button
                  color="primary"
                  variant="contained"
                  type="submit"
                  disabled={submitted}
                >
                  {
                    (submitted && 'Your form is submitted!')
                    || (!submitted && 'Submit')
                  }
                </Button>
                <Link className="btn btn-secondary" to="/forms/admin_list">Cancel</Link>

              </ValidatorForm>
            </CardBody>
          </Card>

          {/* <AnimatedLineFormWithLabels onSubmit={showResults} posts={posts}/> */}
        </Row>
      </Container>
    )
  }
}

export default reduxForm({
  form: 'admin', // a unique identifier for this form
})(withTranslation('common')(Admin));
