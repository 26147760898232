import React, { Component } from 'react';
import { Card, CardBody, Col, Container, Row, Button } from 'reactstrap';
import { withTranslation } from 'react-i18next';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import moment from 'moment';
import * as myConstClass from '../../../constant.js';
import 'font-awesome/css/font-awesome.min.css';
import BootstrapTable from "react-bootstrap-table-next";
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";
import paginationFactory from "react-bootstrap-table2-paginator";
const image = `${process.env.PUBLIC_URL}/img/map-location.png`;

const { SearchBar } = Search;
const tableColumns = [
  {
    dataField: "sr_no",
    text: "#",
    sort: true,
    headerStyle: { width: "5%" }
  },
  {
    dataField: "code",
    text: "Code",
    sort: true,
    headerStyle: { width: "10%" }
  },
  {
    dataField: "latitude",
    text: "Latitude",
    sort: true,
    headerStyle: { width: "10%" }
  },
  {
    dataField: "longitude",
    text: "Longitude",
    sort: true,
    headerStyle: { width: "10%" }
  },
  {
    dataField: "created_at",
    text: "Date/Time",
    sort: true,
    headerStyle: { width: "10%" }
  },
  {
    dataField: "action",
    text: "Action",
    headerStyle: { width: "5%" },
    csvExport: false
  },
];

class Qr_Code_list extends Component {
  constructor(props) {
    super(props);
    this.state = {
      formData: [],
      unused_qrcode: [],
    }
    this.getQrCodeMissingAlertList = this.getQrCodeMissingAlertList.bind(this);
    this.customLabels = {
      first: '<<',
      last: '>>',
      prev: '<',
      next: '>',
      show: 'Display',
      entries: 'rows',
      noResults: 'There is no data to be displayed',
    };
  }

  onSortFunction = {
    date(columnValue) {
      // Convert the string date format to UTC timestamp
      // So the table could sort it by number instead of by string
      return moment(columnValue, 'Do MMMM YYYY').valueOf();
    },
  };

  componentDidMount() {
    if (!localStorage.getItem('session_id')) {
      console.log('History:', this.props);
      this.props.history.push('/log_in');
    }
    this.getQrCodeMissingAlertList();
  }

  getQrCodeMissingAlertList() {
    fetch(myConstClass.BASE_URL + '/qrcode/getQrCodeScanAlertList')
      .then(response => response.json())
      .then(response => {
        let key = 1;
        response.forEach(element => {
          var id = element.id;
          var map = "-";
          if (element.latitude != null && element.longitude != null) {
            map = <a target="_blank" href={"https://www.google.com/maps/search/?api=1&query=" + element.latitude + "," + element.longitude}><img id={id} src={image} style={{ width: '20px', height: '16px' }} /></a>
          }
          element.action = <div><center>{map}</center></div>;
          element.sr_no = key;
          key++;
        });
        this.setState({ formData: response })
      })
      .catch(err => console.log(err))
  }

  render() {
    return (
      <Container>
        <Row>
          <Col md={12}>
            <h3 className="page-title">QR Code Scan Alerts</h3>
            <div style={{ float: 'right' }}></div>
          </Col>
          <ToastContainer enableMultiContainer containerId={'A'} position={toast.POSITION.BOTTOM_LEFT} />
          <ToastContainer enableMultiContainer containerId={'B'} position={toast.POSITION.TOP_RIGHT} />
        </Row>
        <Row>
          <Col md={12} lg={12}>
            <Card>
              <ToolkitProvider
                keyField="name"
                data={this.state.formData}
                columns={tableColumns}
                exportCSV
                search
              >
                {props => (
                  <div>
                    <CardBody>
                      <SearchBar {...props.searchProps} />
                      <BootstrapTable
                        {...props.baseProps}
                        bootstrap4
                        bordered={false}
                        pagination={paginationFactory({
                          // sizePerPage: 25,
                          sizePerPage: 10,
                          sizePerPageList: [5, 10, 25, 50]
                        })}
                      />
                    </CardBody>
                  </div>
                )}
              </ToolkitProvider>
            </Card>
          </Col>
        </Row>
      </Container>)
  }
}

export default withTranslation('common')(Qr_Code_list);
