import React, { Component } from 'react';
import { Col, Container, Row, Button, Card, CardBody, ButtonToolbar, Input } from 'reactstrap';
import { withTranslation } from 'react-i18next';
import TextField from '@material-ui/core/TextField';
import PropTypes from 'prop-types';
import * as myConstClass from '../../../constant.js';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Field, reduxForm } from 'redux-form';
import { ValidatorForm, TextValidator } from 'react-material-ui-form-validator';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import $ from 'jquery';

const renderTextField = ({
  input, label, meta: { touched, error }, children, select,
}) => (
  <TextField
    className="material-form__field"
    label={label}
    error={touched && error}
    value={input.value}
    name={input.name}
    children={children}
    select={select}
    onChange={(e) => {
      e.preventDefault();
      alert('in..' + e.target.value);
      input.onChange(e.target.value);
    }}
  />
);

renderTextField.propTypes = {
  input: PropTypes.shape().isRequired,
  label: PropTypes.string.isRequired,
  meta: PropTypes.shape({
    touched: PropTypes.bool,
    error: PropTypes.string,
  }),
  select: PropTypes.bool,
  children: PropTypes.arrayOf(PropTypes.element),
};

renderTextField.defaultProps = {
  meta: null,
  select: false,
  children: [],
};

class Tenant_Keyword_add extends Component {
  constructor(props) {
    super(props);
    this.state = {
      formData: {
        admin_select_id: 1,
        keyword: '',
      },
      keyword_val: '',
      submitted: false,
      isButtonDisabled: true,
      errors: {},
      admin_data: [],
      admin_select_id: 1,
    }
  }

  componentDidMount() {
    var tid = 1;
    if (localStorage.getItem('uid')) {
      tid = localStorage.getItem('uid');
    }

    fetch(myConstClass.BASE_URL + '/user/getAdmin')
      .then(response => response.json())
      .then(response => {
        let i = 1;
        response.forEach(element => {
          i++;
        });
        this.setState({ admin_data: response })
      })
      .catch(err => console.log(err))

    const answer_array = this.props.location.pathname.split('/');
    if (answer_array['3']) {
      this.setState({ admin_select_id: answer_array['3'] })
    }
    if (!localStorage.getItem('session_id')) {
      this.props.history.push('/log_in');
    }
  }

  handleChange = (event) => {
    const { formData } = this.state;

    var key_val = event.target.value.toLowerCase();
    formData[event.target.name] = key_val;
    if (event.target.name == "keyword") {
      this.setState({ keyword_val: key_val });
    }
    this.setState({ formData });
  }

  handleSubmit = () => {
    let form = document.querySelector('#pform');
    const errors = {};
    const formData = new FormData(form);
    if (this.state.keyword_val == "") {
      toast('Please add keyword', { containerId: 'B', type: toast.TYPE.ERROR })
    } else {
      this.setState({ submitted: true }, () => {
        setTimeout(() => this.setState({ submitted: false }), 5000);
        fetch(myConstClass.BASE_URL + `/user/checkTenantKeyword`, {
          method: 'POST',
          body: JSON.stringify($('#pform').serializeArray()),
        }).then(response => response.json()).then((response) => {
          if (response == 0) {
            fetch(myConstClass.BASE_URL + `/user/addTenantKeyword`, {
              method: 'POST',
              body: formData,
            }).then(response => response.json()).then((response) => {
              if (response == true) {
                this.props.history.push('/forms/tenant_keyword_list/');
                toast('Keyword Added Successfully..!', { containerId: 'B', type: toast.TYPE.SUCCESS })
              } else {
                toast('Keyword Insertion Failed..!', { containerId: 'B', type: toast.TYPE.ERROR })
              }
            }).catch();
          } else {
            toast('Keyword already exists.', { containerId: 'B', type: toast.TYPE.ERROR })
          }
        }).catch();
      });
    }
  }

  handleAdminChange = (event) => {
    this.setState({ admin_select_id: event.target.value });
    const { formData } = this.state;
    formData['admin_select_id'] = event.target.value;
    this.setState({ formData });
  }

  render() {
    const { formData, submitted, isButtonDisabled, keyword_arr } = this.state;

    return (
      <Container>
        <Row>
          <Col md={12}>
            <h3 className="page-title">Add Tenant Keyword</h3>
          </Col>
        </Row>
        <Row>
          <Card>
            <CardBody>
              <div className="card__title"></div>
              <div>
                <ToastContainer enableMultiContainer containerId={'A'} position={toast.POSITION.BOTTOM_LEFT} />
                <ToastContainer enableMultiContainer containerId={'B'} position={toast.POSITION.TOP_RIGHT} />
              </div>
              <ValidatorForm className="material-form" id="pform" onSubmit={this.handleSubmit} >

                <Row>
                  <Col md={6} lg={6}>
                    <div>
                      <div>
                        <FormControl className="material-form__field">
                          <InputLabel htmlFor="admin_select_id">Select Tenant</InputLabel>
                          <Select onChange={this.handleAdminChange} inputProps={{
                            name: 'admin_select_id',
                            id: 'admin_select_id',
                          }} value={this.state.admin_select_id} >
                            {/* <MenuItem value={0}>Select Tenant</MenuItem> */}
                            {this.state.admin_data.map((number) =>
                              <MenuItem value={number.uid}>{number.first_name}</MenuItem>
                            )}
                          </Select>
                        </FormControl>
                      </div>
                    </div>
                  </Col>
                </Row>
                <br />

                <Row>
                  <Col md={6} lg={6}>
                    <br />
                    <h6>Keyword</h6>
                    <div>
                      <TextValidator
                        onChange={this.handleChange}
                        className="material-form__field"
                        name="keyword"
                        placeholder="Keyword"
                        // label="Keyword"
                        value={formData.keyword}
                      // validators={['required']}
                      // errorMessages={['Enter maximum 6 digit Number..']}
                      /></div>
                    <div>
                    </div>
                  </Col>
                </Row>
                <br />
                <Button color="primary" variant="contained" type="submit" disabled={submitted} >
                  {(submitted && 'Your form is submitted!') || (!submitted && 'Submit')}
                </Button>
              </ValidatorForm>
            </CardBody>
          </Card>
        </Row>
      </Container>
    )
  }
}

export default reduxForm({
  form: 'floating_labels_form', // a unique identifier for this form
})(withTranslation('common')(Tenant_Keyword_add));
