import React, { PureComponent } from 'react';
import { withTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import Panel from '../../../../shared/components/Panel';
import ToDo from '../../ECommerce/components/ToDo';
import { Progress, Table } from 'reactstrap';
import * as myConstClass from '../../../../constant.js';
import '../../../../antd.css';
import { Image } from 'antd';
const profile_image = `${process.env.PUBLIC_URL}/img/profile_image.png`;

const Brasil = `${process.env.PUBLIC_URL}/img/flags/Brasil.svg`;
const Argentina = `${process.env.PUBLIC_URL}/img/flags/Argentina.svg`;
const Gabon = `${process.env.PUBLIC_URL}/img/flags/Gabon.svg`;
const Ireland = `${process.env.PUBLIC_URL}/img/flags/Ireland.svg`;
const Italia = `${process.env.PUBLIC_URL}/img/flags/Italia.svg`;
const Sierra = `${process.env.PUBLIC_URL}/img/flags/Sierra.svg`;

class TenantLicenceCount extends PureComponent {
  static propTypes = {
    t: PropTypes.func.isRequired,
  };
  constructor() {
    super();
    this.state = {
      admin_detail_arr: [],
    };
  }
  componentDidMount() {
    var tid = 1;
    if (localStorage.getItem('uid')) {
      tid = localStorage.getItem('uid');
    }

    fetch(myConstClass.BASE_URL + '/user/getTenantLicenceCount/'+tid)
      .then(response => response.json())
      .then(response =>
        this.setState({ admin_detail_arr: response })
      )
      .catch(err => console.log(err))
    if (!localStorage.getItem('session_id')) {
      this.props.history.push('/log_in');
    }
  }

  render() {
    const { t } = this.props;
    const { admin_detail_arr } = this.state;
    return (
      <Panel lg={12} xl={12} md={12} title={'Tenant licence detail'}>
        <Table responsive className="table--bordered dashboard__audience-table">
          <thead>
            <tr>
              <th width="30%">Tenant name</th>
              <th width="10%">Total licence</th>
              <th width="10%">Used licence</th>
              <th width="30%"></th>
            </tr>
          </thead>
          <tbody>
            {admin_detail_arr.map(item => (
              <tr>
                <td>
                  {/* <img className="dashboard__table-flag" src={Brasil} alt="flag" /> */}

                  <Image.PreviewGroup>
                    <span className="dashboard__table-flag">
                      <Image
                        // width={100}
                        height={40}
                        alt="Profile image" title='Profile image'
                        src={profile_image}
                      /></span>
                  </Image.PreviewGroup>
                  {item.admin_name}</td>
                <td>{item.licence_total_count} </td>
                <td>{item.licence_used_count}</td>
                <td>
                  <div className="progress-wrap progress-wrap--blue mb-0" data-title={item.licence_total_count_title} >
                    <Progress value={item.licence_total_count}>{item.licence_total_count} </Progress>
                  </div>
                  <div className="progress-wrap progress-wrap--small" data-title={item.licence_used_count_title} >
                    <Progress value={item.licence_used_count}>{item.licence_used_count} </Progress>
                  </div>
                </td>
              </tr>
            ))
            }
          </tbody>
        </Table>
      </Panel>

    );
  }
}

export default withTranslation('common')(TenantLicenceCount);
