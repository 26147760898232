import React, { Component } from 'react';
import { Card, CardBody, Col, Container, Row, Button } from 'reactstrap';
import { Link } from 'react-router-dom';
import { withTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import showResults from '../Show';
import { MDBDataTable } from 'mdbreact';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import moment from 'moment';
import Datatable from 'react-bs-datatable';
import * as myConstClass from '../../../constant.js';
import 'font-awesome/css/font-awesome.min.css';

import BootstrapTable from "react-bootstrap-table-next";
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";
import paginationFactory from "react-bootstrap-table2-paginator";
const { SearchBar } = Search;
const image = `${process.env.PUBLIC_URL}/img/map-location.png`;

const tableColumns = [
  {
    dataField: "sr_no",
    text: "#",
    sort: true,
    headerStyle: { width: "5%" }
  },
  {
    dataField: "user_id",
    text: "Name",
    sort: true,
    headerStyle: { width: "15%" }
  },
  {
    dataField: "mobile_no",
    text: "Mobile No",
    sort: true,
    headerStyle: { width: "10%" }
  },
  // {
  //   dataField: "user_lat",
  //   text: "Latitude",
  //   sort: true,
  //   headerStyle: { width:"10%" }
  // },
  // {
  //   dataField: "user_long",
  //   text: "Longitude",
  //   sort: true,
  //   headerStyle: { width:"10%" }
  // },
  // {
  //   dataField: "created_at",
  //   text: "Date/Time",
  //   headerStyle: { width:"10%" },
  //   csvExport:false
  // },
  {
    dataField: "tracking_start_time",
    text: "Start Date Time",
    headerStyle: { width: "10%" },
    csvExport: false
  },
  {
    dataField: "tracking_end_time",
    text: "Stop Date Time",
    headerStyle: { width: "10%" },
    csvExport: false
  },
  {
    dataField: "vehicle_number",
    text: "Vehicle Number",
    headerStyle: { width: "10%" },
    csvExport: false
  },
  // {
  //   dataField: "view_map",
  //   text: "View Map",
  //   headerStyle: { width: "10%" },
  //   csvExport: false
  // },
  {
    dataField: "last_status",
    text: "Last Status",
    headerStyle: { width: "5%" },
    csvExport: false
  },
  {
    dataField: "tenant_name",
    text: "Tenant name",
    sort: true,
    headerStyle: { width: "10%" }
  },
  {
    dataField: "view",
    text: "View",
    headerStyle: { width: "5%" },
    csvExport: false
  },
];
const tableColumnsTenant = [
  {
    dataField: "sr_no",
    text: "#",
    sort: true,
    headerStyle: { width: "5%" }
  },
  {
    dataField: "user_id",
    text: "Name",
    sort: true,
    headerStyle: { width: "15%" }
  },
  {
    dataField: "mobile_no",
    text: "Mobile No",
    sort: true,
    headerStyle: { width: "10%" }
  },
  // {
  //   dataField: "user_lat",
  //   text: "Latitude",
  //   sort: true,
  //   headerStyle: { width:"10%" }
  // },
  // {
  //   dataField: "user_long",
  //   text: "Longitude",
  //   sort: true,
  //   headerStyle: { width:"10%" }
  // },
  // {
  //   dataField: "created_at",
  //   text: "Date/Time",
  //   headerStyle: { width:"10%" },
  //   csvExport:false
  // },
  {
    dataField: "tracking_start_time",
    text: "Start Date Time",
    headerStyle: { width: "10%" },
    csvExport: false
  },
  {
    dataField: "tracking_end_time",
    text: "Stop Date Time",
    headerStyle: { width: "10%" },
    csvExport: false
  },
  {
    dataField: "vehicle_number",
    text: "Vehicle Number",
    headerStyle: { width: "10%" },
    csvExport: false
  },
  // {
  //   dataField: "view_map",
  //   text: "View Map",
  //   headerStyle: { width: "10%" },
  //   csvExport: false
  // },
  {
    dataField: "last_status",
    text: "Last Status",
    headerStyle: { width: "5%" },
    csvExport: false
  },
  {
    dataField: "view",
    text: "View",
    headerStyle: { width: "5%" },
    csvExport: false
  },
];
class PreTriggerNotifications extends Component {
  constructor(props) {
    super(props);
    this.state = {
      posts: [],
      formData: [],
      tid: "",
      curr_page_id: '',
      prev_page_id: '',
      next_page_id: '',
      isLoaded: false,
    }
    this.flag = 0;
    this.header = [
      { title: 'Sr. No.', prop: 'idd', filterable: true },
      { title: 'Employee Name   ', prop: 'first_name', filterable: true },
      { title: 'Employee SAP Code', prop: 'sap_code' },
      { title: 'Employee Mobile No.', prop: 'mobile_no' },
      { title: 'Employee Email', prop: 'email' },
      { title: 'Latitude      ', prop: 'user_lat' },
      { title: 'Longitude     ', prop: 'user_long' },
      { title: 'Date          ', prop: 'date' },
      { title: 'Time          ', prop: 'time' },
      { title: 'Panic Evidences', prop: 'action', cell: (row) => <div><center><i id={row.id} className="fa fa-eye ptr_css" onClick={() => this.view(row.id)}></i></center></div> },
    ];

    this.customLabels = {
      first: '<<',
      last: '>>',
      prev: '<',
      next: '>',
      show: 'Display',
      entries: 'rows',
      noResults: 'There is no data to be displayed',
    };
  }

  view(e) {
    this.props.history.push('/forms/cop_view/' + e);
  }

  edit(e) {
    // alert(e);
    this.props.history.push('/forms/add/' + e);
  }

  onSortFunction = {
    date(columnValue) {
      // Convert the string date format to UTC timestamp
      // So the table could sort it by number instead of by string
      return moment(columnValue, 'Do MMMM YYYY').valueOf();
    },
  };

  componentDidMount() {
    var tid = 1;
    if (localStorage.getItem('uid')) {
      tid = localStorage.getItem('uid');
      this.setState({ tid: tid })
    }
    fetch(myConstClass.BASE_URL + '/user/getPreTriggerNotifications/' + tid + '/' + 1)
      .then(response => response.json())
      .then(response => {
        let i = 1;
        response.forEach(element => {
          var id = element.id;
          var user_id_value = element.user_id_value;
          var pre_trigger_id = element.pre_trigger_id;
          const file_link = `${myConstClass.BASE_URL + '/user/getPreTriggerNotificationsByUser/' + user_id_value + "/" + pre_trigger_id}`;
          const file_link_map = `${myConstClass.BASE_URL + '/user/getPreTriggerNotificationsMapByUser/' + user_id_value + "/" + pre_trigger_id}`;
          // element.action = <div><center><i id={id} className="fa fa-eye ptr_css" onClick={() =>this.view(id)}></i></center></div>;
          element.view_map = <div><center>
            <a target="_blank" href={file_link} title="View Map"><img id={id} src={image} style={{ width: '20px', height: '16px' }} /></a>
          </center></div>;
          element.view = <div><center>
            <i id={id} className="fa fa-eye ptr_css" onClick={() => this.viewFollowMeSafe(element.tracking_id)}></i>&nbsp;&nbsp;
            &nbsp;
            {/* <a target="_blank" href={file_link} title="View Map"><img id={id} src={image} style={{ width: '20px', height: '16px' }} /></a> */}
            <a target="_blank" href={file_link_map} title="View Map"><img id={id} src={image} style={{ width: '20px', height: '16px' }} /></a>
            &nbsp;&nbsp;
          </center></div>;
          element.id = i;
          i++;
        });
        // this.setState({posts: response})
        this.setState({ formData: response })
        this.setState({ formData: response, isLoaded: true })
        this.setState({ curr_page_id: response[0]['curr_page_id'] });
        this.setState({ prev_page_id: response[0]['prv_page_id'] });
        this.setState({ next_page_id: response[0]['nxt_page_id'] });
      })
      .catch(err => console.log(err))

    if (!localStorage.getItem('session_id')) {
      console.log('History:', this.props);
      this.props.history.push('/log_in');
    }
  }
  GetPageRecords(next_page_id) {
    this.setState({ isLoaded: false })
    var tid = 1;
    if (localStorage.getItem('uid')) {
      tid = localStorage.getItem('uid');
      this.setState({ tid: tid })
    }
    fetch(myConstClass.BASE_URL + '/user/getPreTriggerNotifications/' + tid + '/' + next_page_id)
      .then(response => response.json())
      .then(response => {
        let i = 1;
        response.forEach(element => {
          var id = element.id;
          var user_id_value = element.user_id_value;
          var pre_trigger_id = element.pre_trigger_id;
          const file_link = `${myConstClass.BASE_URL + '/user/getPreTriggerNotificationsByUser/' + user_id_value + "/" + pre_trigger_id}`;
          const file_link_map = `${myConstClass.BASE_URL + '/user/getPreTriggerNotificationsMapByUser/' + user_id_value + "/" + pre_trigger_id}`;
          // element.action = <div><center><i id={id} className="fa fa-eye ptr_css" onClick={() =>this.view(id)}></i></center></div>;
          element.view_map = <div><center>
            <a target="_blank" href={file_link} title="View Map"><img id={id} src={image} style={{ width: '20px', height: '16px' }} /></a>
          </center></div>;
          element.view = <div><center>
            <i id={id} className="fa fa-eye ptr_css" onClick={() => this.viewFollowMeSafe(element.tracking_id)}></i>&nbsp;&nbsp;
            &nbsp;
            {/* <a target="_blank" href={file_link} title="View Map"><img id={id} src={image} style={{ width: '20px', height: '16px' }} /></a> */}
            <a target="_blank" href={file_link_map} title="View Map"><img id={id} src={image} style={{ width: '20px', height: '16px' }} /></a>
            &nbsp;&nbsp;
          </center></div>;
          element.id = i;
          i++;
        });
        this.setState({ formData: response, isLoaded: true })
        this.setState({ curr_page_id: response[0]['curr_page_id'] });
        this.setState({ prev_page_id: response[0]['prv_page_id'] });
        this.setState({ next_page_id: response[0]['nxt_page_id'] });
      })
      .catch(err => console.log(err))
  }

  viewFollowMeSafe(e) {
    this.props.history.push('/forms/view_followme_safe/' + e);
  }

  render() {
    const { tid, curr_page_id, prev_page_id, next_page_id, isLoaded } = this.state;

    return (
      <Container>
        <Row>
          <Col md={12}>
            {/* <h3 className="page-title">All PreTrigger Notifications */}
            <h3 className="page-title">Travel Safe</h3>
          </Col>
          <ToastContainer enableMultiContainer containerId={'A'} position={toast.POSITION.BOTTOM_LEFT} />
          <ToastContainer enableMultiContainer containerId={'B'} position={toast.POSITION.TOP_RIGHT} />

        </Row>
        {/* <Row>
    <Col md={12} lg={12}>
        <Link to="/forms/send_notification" style={{float:'right'}}> <Button
                    color="primary"
                    variant="contained"
                    type="button"
                   
                >Send Notification</Button>
                </Link>
        </Col>
    </Row> */}
        <Row>
          <Col md={12} lg={12}>
            <Card>
              {!isLoaded ? (
                <div style={{ display: 'flex' }}  >
                  <div className="load__icon-wrap">
                    <svg className="load__icon">
                      <path fill="#4ce1b6" d="M12,4V2A10,10 0 0,0 2,12H4A8,8 0 0,1 12,4Z" />
                    </svg>
                  </div>
                </div>
              ) : (
                <span>
                  {tid == 1 ? (
                    <ToolkitProvider
                      keyField="name"
                      data={this.state.formData}
                      columns={tableColumns}
                      exportCSV
                      search
                    >
                      {props => (
                        <div>
                          <CardBody>
                            {/* <SearchBar {...props.searchProps} /> */}
                            <BootstrapTable
                              {...props.baseProps}
                              bootstrap4
                              bordered={false}
                              // pagination={paginationFactory({
                                // sizePerPage: 25,
                                // sizePerPage: 10,
                                // sizePerPageList: [5, 10, 25, 50]
                              // })}
                            />
                            <div style={{ justifyContent: "center", display: "flex", marginTop: '10px' }}>
                              {this.state.prev_page_id == 0 ? (null
                              ) : <button class="page-link" onClick={() => this.GetPageRecords(prev_page_id)} >{"<<"}   Previous Page </button>}
                              &nbsp;&nbsp;&nbsp;
                                                            <button class=" table_active_page"  >{curr_page_id} </button>
                              &nbsp;&nbsp;&nbsp;
                              {this.state.next_page_id == 0 ? (null
                              ) : <button class="page-link" onClick={() => this.GetPageRecords(next_page_id)} >  Next Page {">>"} </button>}
                            </div>
                          </CardBody>
                        </div>
                      )}
                    </ToolkitProvider>
                  ) : (
                    <ToolkitProvider
                      keyField="name"
                      data={this.state.formData}
                      columns={tableColumnsTenant}
                      exportCSV
                      search
                    >
                      {props => (
                        <div>
                          <CardBody>
                            {/* <SearchBar {...props.searchProps} /> */}
                            <BootstrapTable
                              {...props.baseProps}
                              bootstrap4
                              bordered={false}
                              // pagination={paginationFactory({
                                // sizePerPage: 25,
                                // sizePerPage: 10,
                                // sizePerPageList: [5, 10, 25, 50]
                              // })}
                            />
                            <div style={{ justifyContent: "center", display: "flex", marginTop: '10px' }}>
                              {this.state.prev_page_id == 0 ? (null
                              ) : <button class="page-link" onClick={() => this.GetPageRecords(prev_page_id)} >{"<<"}   Previous Page </button>}
                              &nbsp;&nbsp;&nbsp;
                              {this.state.next_page_id == 0 ? (null
                              ) : <button class="page-link" onClick={() => this.GetPageRecords(next_page_id)} >  Next Page {">>"} </button>}
                            </div>
                          </CardBody>
                        </div>
                      )}
                    </ToolkitProvider>
                  )}
                </span>
              )}
            </Card>
          </Col>
        </Row>
      </Container>)
  }
}

export default withTranslation('common')(PreTriggerNotifications);
