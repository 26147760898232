import React, { Component } from 'react';
import { Card, CardBody, Col, Container, Row, Button } from 'reactstrap';
import { withTranslation } from 'react-i18next';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import * as myConstClass from '../../../constant.js';
import 'font-awesome/css/font-awesome.min.css';
import { ButtonToolbar, Modal } from 'reactstrap';

class Ambulance_view extends Component {
  constructor(props) {
    super(props);
    this.state = {
      ambulance: [],
      modal: false,
      ambulance_status_detail: [],
    }
    this.toggle = this.toggle.bind(this);
  }

  componentDidMount() {
    const answer_array = this.props.location.pathname.split('/');
    if (answer_array['3']) {
      fetch(myConstClass.BASE_URL + '/user/getCallAmbulanceById/' + answer_array['3'])
        .then(response => response.json())
        .then(response =>
          this.setState({ ambulance: response })
        )
        .catch(err => console.log(err))
    }
    if (!localStorage.getItem('session_id')) {
      this.props.history.push('/log_in');
    }
  }

  view_latest_status(request_id, user_id, ambulance_service_id) {
    const formData = new FormData();
    formData.append("request_id", request_id);
    formData.append("user_id", user_id);
    formData.append("ambulance_service_id", ambulance_service_id);
    fetch(myConstClass.BASE_URL + '/user/userGetAmbulanceDetailsMyresqr', {
      method: 'POST',
      body: formData,
    })
      .then(response => response.json())
      .then(response => {
        this.setState({ ambulance_status_detail: response });
        this.toggle();
      }
      )
      .catch(err => console.log(err))
  }

  toggle() {
    this.setState(prevState => ({ modal: !prevState.modal }));
  }

  render() {
    const { ambulance, modal, ambulance_status_detail } = this.state;

    return (
      <Container>
        <Row>
          <Col md={12}>
            <h3 className="page-title"> Ambulance Booking Detail</h3>
          </Col>
          <ToastContainer enableMultiContainer containerId={'A'} position={toast.POSITION.BOTTOM_LEFT} />
          <ToastContainer enableMultiContainer containerId={'B'} position={toast.POSITION.TOP_RIGHT} />
        </Row>
        <Row>
          <Col md={12} lg={12}>
            <Card>
              <CardBody>
                <Row>
                  <Col md={12}>
                    <Modal style={{
                      position: 'relative',
                      margin: '0 auto',
                      top: '28%',
                      width: '500px',
                    }}
                      isOpen={modal}
                      toggle={this.toggle}
                      modalClassName={`ltr-support`}
                      className={`modal-dialog--primary modal-dialog--header`} >
                      <div className="modal__header">
                        <button className="lnr lnr-cross modal__close-btn" type="button" onClick={this.toggle} style={{ marginBottom: '5px' }} />
                        <h4 className="modal__title white_text">{"Ambulance Latest Status Detail"}</h4>
                      </div>
                      <div className="modal__body">
                        <div className="form" style={{ textAlign: "center" }}>
                          <table className="table">
                            <tr>
                              <td>Request id :</td>
                              <td>{ambulance_status_detail.request_id}</td>
                            </tr>
                            <tr>
                              <td>Vehicle no :</td>
                              <td>{ambulance_status_detail.vehicle_no}</td>
                            </tr>
                            <tr>
                              <td>Vehicle driver phone :</td>
                              <td>{ambulance_status_detail.vehicle_driver_phone}</td>
                            </tr>
                            <tr>
                              <td>Last status :</td>
                              <td>{ambulance_status_detail.last_status}</td>
                            </tr>
                            <tr>
                              <td>Last status time :</td>
                              <td>{ambulance_status_detail.last_status_time}</td>
                            </tr>
                            <tr>
                              <td>Success status :</td>
                              <td>{ambulance_status_detail.success == 1 ? "True" : "False"}</td>
                            </tr>
                            <tr>
                              <td>Tracking url :</td>
                              <td>{ambulance_status_detail.tracking_url != "" ? <a target="_blank" href={ambulance_status_detail.tracking_url}>Tracking url</a> : ""}</td>
                            </tr>
                          </table>
                        </div>
                      </div>
                      <ButtonToolbar className="modal__footer">
                        <Button className="modal_cancel" onClick={this.toggle}>Cancel</Button>
                      </ButtonToolbar>
                    </Modal>
                  </Col>
                </Row>
                <Row>
                  <Col md={9} lg={9}>
                    <Row>
                      <Col md={3} lg={3}>
                        <h5><b>Request id : </b></h5>
                      </Col>
                      <Col md={6} lg={6}>
                        <h5>{ambulance.request_id}</h5>
                      </Col>
                    </Row>
                    <br />

                    <Row>
                      <Col md={3} lg={3}>
                        <h5><b>User Id : </b></h5>
                      </Col>
                      <Col md={6} lg={6}>
                        <h5>{ambulance.user_id}</h5>
                      </Col>
                    </Row>
                    <br />

                    <Row>
                      <Col md={3} lg={3}>
                        <h5><b>User name : </b></h5>
                      </Col>
                      <Col md={6} lg={6}>
                        <h5>{ambulance.first_name}</h5>
                      </Col>
                    </Row>
                    <br />

                    <Row>
                      <Col md={3} lg={3}>
                        <h5><b>Latitude : </b></h5>
                      </Col>
                      <Col md={6} lg={6}>
                        <h5>{ambulance.latitude}</h5>
                      </Col>
                    </Row>
                    <br />

                    <Row>
                      <Col md={3} lg={3}>
                        <h5><b>Longitude : </b></h5>
                      </Col>
                      <Col md={6} lg={6}>
                        <h5>{ambulance.longitude}</h5>
                      </Col>
                    </Row>
                    <br />

                    <Row>
                      <Col md={3} lg={3}>
                        <h5><b>Tenant name : </b></h5>
                      </Col>
                      <Col md={6} lg={6}>
                        <h5>{ambulance.tenant_name}</h5>
                      </Col>
                    </Row>
                    <br />

                    <Row>
                      <Col md={3} lg={3}>
                        <h5><b>Status : </b></h5>
                      </Col>
                      <Col md={6} lg={6}>
                        <h5>
                          {ambulance.status == '1' ? "Success" : ''}
                          {ambulance.status == '2' ? "Fail" : ''}
                        </h5>
                      </Col>
                    </Row>
                    <br />

                    <Row>
                      <Col md={3} lg={3}>
                        <h5><b>Ambulance service used : </b></h5>
                      </Col>
                      <Col md={6} lg={6}>
                        <h5>
                          {ambulance.ambulance_service_used == '0' ? "Not Used" : ''}
                          {ambulance.status == '1' ? "Used" : ''}
                        </h5>
                      </Col>
                    </Row>
                    <br />

                    <Row>
                      <Col md={3} lg={3}>
                        <h5><b>Date/Time : </b></h5>
                      </Col>
                      <Col md={6} lg={6}>
                        <h5>{ambulance.created_at}</h5>
                      </Col>
                    </Row>
                  </Col>
                  <Col md={3} lg={3}>
                    <Button className="mb-0" color="primary" style={{ float: 'right' }} onClick={() => this.view_latest_status(ambulance.request_id, ambulance.user_id, ambulance.ambulance_service_id)} > View Latest Status</Button>
                  </Col>
                </Row>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>)
  }
}

export default withTranslation('common')(Ambulance_view);
