import React, { Component } from 'react';
import { Card, CardBody, Col, Container, Row, Button } from 'reactstrap';
import { Link } from 'react-router-dom';
import { withTranslation } from 'react-i18next';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import * as myConstClass from '../../../constant.js';
import 'font-awesome/css/font-awesome.min.css';
import "video-react/dist/video-react.css";
import '../../../antd.css';
import { Image } from 'antd';

class Qrcode_view extends Component {
  constructor(props) {
    super(props);
    this.state = {
      qr_code: [],
      qr_code_img: "",
    }
  }

  componentDidMount() {
    this.getQrCodeDetail();
    if (!localStorage.getItem('session_id')) {
      this.props.history.push('/log_in');
    }
  }
  getQrCodeDetail() {
    const answer_array = this.props.location.pathname.split('/');
    if (answer_array['3']) {
      fetch(myConstClass.BASE_URL + '/qrcode/getQrCodeById/' + answer_array['3'])
        .then(response => response.json())
        .then(response => {
          this.setState({ qr_code: response });
          this.setState({ qr_code_img: response.code_image_path });
          if (response.code != "") {
            console.log(response.code_image);
            if (response.code_image == "" || response.code_image == null) {
              this.getQrCodeImage(response.code);
            } else {

            }
          }
        }
        )
        .catch(err => console.log(err))
    }
  }

  changeMissingStatus(id, missing_status) {
    const formData = new FormData();
    formData.append('id', id);
    formData.append('missing_status', missing_status);
    fetch(myConstClass.BASE_URL + `/qrcode/changeMissingStatus`, {
      method: 'POST',
      body: formData,
    }).then(response => response.json()).then((response) => {
      if (response == true) {
        toast('Missing status changed.', { containerId: 'B', type: toast.TYPE.SUCCESS })
        this.getQrCodeDetail();
      } else {
        toast('Failed to change Missing status.', { containerId: 'B', type: toast.TYPE.ERROR })
      }
    }).catch();
  }

  getQrCodeImage(qr_chl) {
    const answer_array = this.props.location.pathname.split('/');
    if (answer_array['3']) {
      const imageFormData = new FormData();
      imageFormData.append('qr_chl', qr_chl);
      imageFormData.append('qr_id', answer_array['3']);
      fetch(myConstClass.BASE_URL + '/qrcode/getQrCodeImage', {
        method: 'POST',
        body: imageFormData,
      }).then(response => response.json()).
        then((response) => {
          this.setState({ qr_code_img: response.image_name });
        }).catch();
      if (qr_chl != "") {
        // fetch('https://chart.googleapis.com/chart?chs=300x300&cht=qr&chl=' + qr_chl + '&choe=UTF-8')
        //   // .then(response => response.json())
        //   .then(response =>
        //     this.setState({ qr_code_img: response })
        //   )
        //   .catch(err => console.log(err))
      }
    }
  }

  downloadQrImage(url) {
    fetch(url, {
      mode: 'no-cors',
    })
      .then(response => response.blob())
      .then(blob => {
        let blobUrl = window.URL.createObjectURL(blob);
        let a = document.createElement('a');
        a.download = url.replace(/^.*[\\\/]/, '');
        a.href = blobUrl;
        document.body.appendChild(a);
        a.click();
        a.remove();
      })
  }

  render() {
    const { qr_code, qr_code_img } = this.state;
    return (
      <Container>
        <Row>
          <Col md={12}>
            <h3 className="page-title">QR Code Detail</h3>
          </Col>
          <ToastContainer enableMultiContainer containerId={'A'} position={toast.POSITION.BOTTOM_LEFT} />
          <ToastContainer enableMultiContainer containerId={'B'} position={toast.POSITION.TOP_RIGHT} />
        </Row>
        <Row>
          <Col md={12} lg={12}>
            <Card>
              <CardBody className="view_qrcode_div">
                <Row>
                  <Col md={9} lg={9}>
                    <Row>
                      <Col md={3} lg={3}>
                        <h5><b>User Name : </b></h5>
                      </Col>
                      <Col md={6} lg={6}>
                        {qr_code.first_name != null && qr_code.last_name != null ?
                          <h5>{qr_code.first_name} {qr_code.last_name}</h5>
                          : '-'}
                      </Col>
                    </Row>

                    <Row>
                      <Col md={3} lg={3}>
                        <h5><b>Mobile no : </b></h5>
                      </Col>
                      <Col md={6} lg={6}>
                        {qr_code.mobile_no != null ?
                          <h5>{qr_code.mobile_no}</h5>
                          : '-'}
                      </Col>
                    </Row>

                    <Row>
                      <Col md={3} lg={3}>
                        <h5><b>Code : </b></h5>
                      </Col>
                      <Col md={6} lg={6}>
                        <h5>{qr_code.code}</h5>
                      </Col>
                    </Row>

                    <Row>
                      <Col md={3} lg={3}>
                        <h5><b>Type : </b></h5>
                      </Col>
                      <Col md={6} lg={6}>
                        <h5>
                          {qr_code.type_title}
                        </h5>
                      </Col>
                    </Row>

                    <Row>
                      <Col md={3} lg={3}>
                        <h5><b>Code For : </b></h5>
                      </Col>
                      <Col md={6} lg={6}>
                        <h5>
                          {qr_code.code_for == '1' ? 'Self' : ''}
                          {qr_code.code_for == '2' ? 'Other' : ''}
                        </h5>
                      </Col>
                    </Row>
                    <Row>
                      <Col md={3} lg={3}>
                        <h5><b>Missing status : </b></h5>
                      </Col>
                      <Col md={6} lg={6}>
                        <h5>
                          {qr_code.missing_status == '1' ? 'No' : ''}
                          {qr_code.missing_status == '2' ? 'Yes' : ''}
                          <span style={{ fontSize: '25px', color: '#3ea3fc', marginLeft: '25px' }}>
                            {qr_code.missing_status == '1' ? <i className="fa fa-toggle-off ptr_css" onClick={() => this.changeMissingStatus(qr_code.id, qr_code.missing_status)} title={qr_code.missing_status == '1' ? 'Missing status - No' : ''}></i> : ''}
                            {qr_code.missing_status == '2' ? <i className="fa fa-toggle-on ptr_css" onClick={() => this.changeMissingStatus(qr_code.id, qr_code.missing_status)} title={qr_code.missing_status == '2' ? 'Missing status - Yes' : ''}></i> : ''}
                          </span>
                        </h5>
                      </Col>
                    </Row>
                    <Row>
                      <Col md={3} lg={3}>
                        <h5><b>Child QR Code : </b></h5>
                      </Col>
                      <Col md={6} lg={6}>
                        <h5>
                          {qr_code.child_qr_code != null ?

                            qr_code.child_qr_code.map((item, i) =>
                              <span>{i + 1}. <a href={"/forms/view_qrcode/" + item.id}>{item.code}</a><br /></span>
                            )

                            : '-'}
                        </h5>
                      </Col>
                    </Row>
                    <Row>
                      <Col md={3} lg={3}>
                        <h5><b>Date/Time : </b></h5>
                      </Col>
                      <Col md={6} lg={6}>
                        <h5>{qr_code.created_at}</h5>
                      </Col>
                    </Row>
                  </Col>

                  <Col md={3} lg={3}>
                    {qr_code_img != "" ?
                      <span>
                        <img
                          // width={100}
                          height={150}
                          alt="image" title='image'
                          src={qr_code_img}
                        />
                        <br />
                        <Button color="primary" style={{ marginLeft: "20px" }} onClick={() => this.downloadQrImage(qr_code_img)} > Download</Button>
                      </span>
                      : '-'}
                  </Col>
                </Row>

                {qr_code.type == '1' && qr_code.code_for == '2' ?
                  <div>
                    <Row>
                      <Col md={12} lg={12}>
                        <hr />
                      </Col>
                    </Row>

                    <Row>
                      <Col md={2} lg={2}>
                        <h4><b>Person detail  </b></h4>
                      </Col>
                      <Col md={6} lg={6}></Col>
                    </Row>

                    <Row>
                      <Col md={2} lg={2}>
                        <h5><b>Name : </b></h5>
                      </Col>
                      <Col md={6} lg={6}>
                        <h5>{qr_code.first_name} {qr_code.last_name}</h5>
                      </Col>
                    </Row>

                    <Row>
                      <Col md={2} lg={2}>
                        <h5><b>Mobile no : </b></h5>
                      </Col>
                      <Col md={6} lg={6}>
                        <h5>{qr_code.mobile_no != null ? qr_code.mobile_no : '-'}</h5>
                      </Col>
                    </Row>

                    <Row>
                      <Col md={2} lg={2}>
                        <h5><b>Email : </b></h5>
                      </Col>
                      <Col md={6} lg={6}>
                        <h5>{qr_code.email != null ? qr_code.email : '-'}</h5>
                      </Col>
                    </Row>

                    <Row>
                      <Col md={2} lg={2}>
                        <h5><b>Blood group : </b></h5>
                      </Col>
                      <Col md={6} lg={6}>
                        <h5>{qr_code.blood_group != null ? qr_code.blood_group : '-'}</h5>
                      </Col>
                    </Row>

                    <Row>
                      <Col md={2} lg={2}>
                        <h5><b>Image : </b></h5>
                      </Col>
                      <Col md={6} lg={6}>
                        {qr_code.image_path != null ?
                          <Image.PreviewGroup>
                            <span className="dashboard__table-flag">
                              <Image
                                // width={100}
                                height={90}
                                alt="image" title='image'
                                src={qr_code.image_path}
                              /></span>
                          </Image.PreviewGroup>
                          : '-'}
                      </Col>
                    </Row>
                    <Row>
                      <Col md={2} lg={2}>
                        <h5><b>Emergency contact : </b></h5>
                      </Col>
                      <Col md={6} lg={6}>
                        {qr_code.emergency_contacts != null ?
                          <h5>
                            {qr_code.emergency_contacts.map((item, i) =>
                              <span>{item.name} - {item.mobile_no}<br /></span>
                            )}
                          </h5>
                          : ''}
                      </Col>
                    </Row>
                  </div>
                  : ''}

                {qr_code.type == '1' && qr_code.code_for == '1' ?
                  <div>
                    <Row>
                      <Col md={12} lg={12}>
                        <hr />
                      </Col>
                    </Row>

                    <Row>
                      <Col md={2} lg={2}>
                        <h4><b>Self detail </b></h4>
                      </Col>
                      <Col md={6} lg={6}></Col>
                    </Row>

                    <Row>
                      <Col md={2} lg={2}>
                        <h5><b>Name : </b></h5>
                      </Col>
                      <Col md={6} lg={6}>
                        <h5>{qr_code.first_name} {qr_code.last_name}</h5>
                      </Col>
                    </Row>

                    <Row>
                      <Col md={2} lg={2}>
                        <h5><b>Mobile no : </b></h5>
                      </Col>
                      <Col md={6} lg={6}>
                        <h5>{qr_code.mobile_no != null ? qr_code.mobile_no : '-'}</h5>
                      </Col>
                    </Row>
                    <Row>
                      <Col md={2} lg={2}>
                        <h5><b>Email : </b></h5>
                      </Col>
                      <Col md={6} lg={6}>
                        <h5>{qr_code.email != null ? qr_code.email : '-'}</h5>
                      </Col>
                    </Row>
                    <Row>
                      <Col md={2} lg={2}>
                        <h5><b>Blood group : </b></h5>
                      </Col>
                      <Col md={6} lg={6}>
                        <h5>{qr_code.blood_group != null ? qr_code.blood_group : '-'}</h5>
                      </Col>
                    </Row>
                    <Row>
                      <Col md={2} lg={2}>
                        <h5><b>Emergency contact : </b></h5>
                      </Col>
                      <Col md={6} lg={6}>
                        {qr_code.emergency_contacts != null ?
                          <h5>
                            {qr_code.emergency_contacts.map((item, i) =>
                              <span>{item.name} - {item.mobile_no}<br /></span>
                            )}
                          </h5>
                          : ''}
                      </Col>
                    </Row>

                  </div>
                  : ''}

                {qr_code.type == '2' ?
                  <div>
                    <Row>
                      <Col md={12} lg={12}>
                        <hr />
                      </Col>
                    </Row>

                    <Row>
                      <Col md={2} lg={2}>
                        <h4><b>Pet detail  </b></h4>
                      </Col>
                      <Col md={6} lg={6}></Col>
                    </Row>

                    <Row>
                      <Col md={2} lg={2}>
                        <h5><b>Name : </b></h5>
                      </Col>
                      <Col md={6} lg={6}>
                        <h5>{qr_code.name}</h5>
                      </Col>
                    </Row>

                    <Row>
                      <Col md={2} lg={2}>
                        <h5><b>Color : </b></h5>
                      </Col>
                      <Col md={6} lg={6}>
                        <h5>{qr_code.color != null ? qr_code.color : '-'}</h5>
                      </Col>
                    </Row>

                    <Row>
                      <Col md={2} lg={2}>
                        <h5><b>Mobile no : </b></h5>
                      </Col>
                      <Col md={6} lg={6}>
                        <h5>{qr_code.animal_mobile_no != null ? qr_code.animal_mobile_no : '-'}</h5>
                      </Col>
                    </Row>

                    <Row>
                      <Col md={2} lg={2}>
                        <h5><b>Description : </b></h5>
                      </Col>
                      <Col md={6} lg={6}>
                        <h5>{qr_code.description != null ? qr_code.description : '-'}</h5>
                      </Col>
                    </Row>

                    <Row>
                      <Col md={2} lg={2}>
                        <h5><b>Image : </b></h5>
                      </Col>
                      <Col md={6} lg={6}>
                        {qr_code.image_path != null ?
                          <Image.PreviewGroup>
                            <span className="dashboard__table-flag">
                              <Image
                                // width={100}
                                height={90}
                                alt="image" title='image'
                                src={qr_code.image_path}
                              /></span>
                          </Image.PreviewGroup>
                          : '-'}
                      </Col>
                    </Row>
                    <br />
                    <Row>
                      <Col md={2} lg={2}>
                        <h5><b>Date Of Birth : </b></h5>
                      </Col>
                      <Col md={6} lg={6}>
                        <h5>{qr_code.date_of_birth != null ? qr_code.date_of_birth : '-'}</h5>
                      </Col>
                    </Row>
                    <Row>
                      <Col md={2} lg={2}>
                        <h5><b>Age : </b></h5>
                      </Col>
                      <Col md={6} lg={6}>
                        <h5>{qr_code.age != null ? qr_code.age : '-'}</h5>
                      </Col>
                    </Row>
                    <Row>
                      <Col md={2} lg={2}>
                        <h5><b>Identification Mark : </b></h5>
                      </Col>
                      <Col md={6} lg={6}>
                        <h5>{qr_code.identification_mark != null ? qr_code.identification_mark : '-'}</h5>
                      </Col>
                    </Row>
                    <Row>
                      <Col md={2} lg={2}>
                        <h5><b>Vaccination Name : </b></h5>
                      </Col>
                      <Col md={6} lg={6}>
                        <h5>{qr_code.vaccination_name != null ? qr_code.vaccination_name : '-'}</h5>
                      </Col>
                    </Row>
                    <Row>
                      <Col md={2} lg={2}>
                        <h5><b>Vaccination Date : </b></h5>
                      </Col>
                      <Col md={6} lg={6}>
                        <h5>{qr_code.vaccination_date != null ? qr_code.vaccination_date : '-'}</h5>
                      </Col>
                    </Row>
                    <Row>
                      <Col md={2} lg={2}>
                        <h5><b>Allergy : </b></h5>
                      </Col>
                      <Col md={6} lg={6}>
                        <h5>{qr_code.allergy != null ? qr_code.allergy : '-'}</h5>
                      </Col>
                    </Row>
                    <Row>
                      <Col md={2} lg={2}>
                        <h5><b>Surgery : </b></h5>
                      </Col>
                      <Col md={6} lg={6}>
                        <h5>{qr_code.surgery != null ? qr_code.surgery : '-'}</h5>
                      </Col>
                    </Row>
                    <Row>
                      <Col md={2} lg={2}>
                        <h5><b>Medication : </b></h5>
                      </Col>
                      <Col md={6} lg={6}>
                        <h5>{qr_code.medication != null ? qr_code.medication : '-'}</h5>
                      </Col>
                    </Row>
                    <Row>
                      <Col md={2} lg={2}>
                        <h5><b>Medical Condition : </b></h5>
                      </Col>
                      <Col md={6} lg={6}>
                        <h5>{qr_code.medical_condition != null ? qr_code.medical_condition : '-'}</h5>
                      </Col>
                    </Row>
                    <Row>
                      <Col md={2} lg={2}>
                        <h5><b>Mating Cycle : </b></h5>
                      </Col>
                      <Col md={6} lg={6}>
                        <h5>{qr_code.mating_cycle != null ? qr_code.mating_cycle : '-'}</h5>
                      </Col>
                    </Row>
                    <Row>
                      <Col md={2} lg={2}>
                        <h5><b>Emergency contact : </b></h5>
                      </Col>
                      <Col md={6} lg={6}>
                        {qr_code.emergency_contacts != null ?
                          <h5>
                            {qr_code.emergency_contacts.map((item, i) =>
                              <span>{item.name} - {item.mobile_no}<br /></span>
                            )}
                          </h5>
                          : ''}
                      </Col>
                    </Row>
                  </div>
                  : ''}

                {qr_code.type == '3' ?
                  <div>
                    <Row>
                      <Col md={12} lg={12}>
                        <hr />
                      </Col>
                    </Row>

                    <Row>
                      <Col md={2} lg={2}>
                        <h4><b>Things detail  </b></h4>
                      </Col>
                      <Col md={6} lg={6}></Col>
                    </Row>

                    <Row>
                      <Col md={2} lg={2}>
                        <h5><b>Name : </b></h5>
                      </Col>
                      <Col md={6} lg={6}>
                        <h5>{qr_code.name}</h5>
                      </Col>
                    </Row>

                    <Row>
                      <Col md={2} lg={2}>
                        <h5><b>Device name : </b></h5>
                      </Col>
                      <Col md={6} lg={6}>
                        <h5>{qr_code.device_name}</h5>
                      </Col>
                    </Row>

                    <Row>
                      <Col md={2} lg={2}>
                        <h5><b>Model number : </b></h5>
                      </Col>
                      <Col md={6} lg={6}>
                        <h5>{qr_code.model_number}</h5>
                      </Col>
                    </Row>

                    <Row>
                      <Col md={2} lg={2}>
                        <h5><b>Serial number : </b></h5>
                      </Col>
                      <Col md={6} lg={6}>
                        <h5>{qr_code.serial_number}</h5>
                      </Col>
                    </Row>

                    <Row>
                      <Col md={2} lg={2}>
                        <h5><b>Color : </b></h5>
                      </Col>
                      <Col md={6} lg={6}>
                        <h5>{qr_code.color != null ? qr_code.color : '-'}</h5>
                      </Col>
                    </Row>

                    <Row>
                      <Col md={2} lg={2}>
                        <h5><b>Description : </b></h5>
                      </Col>
                      <Col md={6} lg={6}>
                        <h5>{qr_code.description != null ? qr_code.description : '-'}</h5>
                      </Col>
                    </Row>
                    <Row>
                      <Col md={2} lg={2}>
                        <h5><b>Mobile no : </b></h5>
                      </Col>
                      <Col md={6} lg={6}>
                        <h5>{qr_code.things_mobile_no != null ? qr_code.things_mobile_no : '-'}</h5>
                      </Col>
                    </Row>
                    <Row>
                      <Col md={2} lg={2}>
                        <h5><b>Image : </b></h5>
                      </Col>
                      <Col md={6} lg={6}>
                        {qr_code.image_path != null ?
                          <Image.PreviewGroup>
                            <span className="dashboard__table-flag">
                              <Image
                                // width={100}
                                height={90}
                                alt="image" title='image'
                                src={qr_code.image_path}
                              /></span>
                          </Image.PreviewGroup>
                          : '-'}
                      </Col>
                    </Row>
                    <Row>
                      <Col md={2} lg={2}>
                        <h5><b>Emergency contact : </b></h5>
                      </Col>
                      <Col md={6} lg={6}>
                        {qr_code.emergency_contacts != null ?
                          <h5>
                            {qr_code.emergency_contacts.map((item, i) =>
                              <span>{item.name} - {item.mobile_no}<br /></span>
                            )}
                          </h5>
                          : ''}
                      </Col>
                    </Row>

                  </div>
                  : ''}


                <div>
                  <Row>
                    <Col md={12} lg={12}>
                      <hr />
                    </Col>
                  </Row>

                  {qr_code.location != '' || qr_code.location != null ?
                    <Row>
                      <Col md={2} lg={2}>
                        <h5><b>Location : </b></h5>
                      </Col>
                      <Col md={6} lg={6}>
                        <h5>{qr_code.location} </h5>
                      </Col>
                    </Row>
                    : ''}

                  {qr_code.lat != '' || qr_code.lat != null ?
                    <Row>
                      <Col md={2} lg={2}>
                        <h5><b>Lat : </b></h5>
                      </Col>
                      <Col md={6} lg={6}>
                        <h5>{qr_code.lat} </h5>
                      </Col>
                    </Row>
                    : ''}

                  {qr_code.long != '' || qr_code.long != null ?
                    <Row>
                      <Col md={2} lg={2}>
                        <h5><b>Long : </b></h5>
                      </Col>
                      <Col md={6} lg={6}>
                        <h5>{qr_code.long} </h5>
                      </Col>
                    </Row>
                    : ''}
                </div>


                <Row>
                  <Col md={2} lg={2}>
                    <Link className="btn btn-secondary" to="/forms/qrcode_list">Cancel</Link>
                  </Col>
                </Row>

              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    )
  }
}

export default withTranslation('common')(Qrcode_view);
