import React, { Component } from 'react';
import { Card, CardBody, Col, Container, Row } from 'reactstrap';
import { withTranslation } from 'react-i18next';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import * as myConstClass from '../../../constant.js';
import 'font-awesome/css/font-awesome.min.css';

class Rsa_view extends Component {
  constructor(props) {
    super(props);
    this.state = {
      ambulance: [],
    }
  }

  componentDidMount() {
    const answer_array = this.props.location.pathname.split('/');
    if (answer_array['3']) {
      fetch(myConstClass.BASE_URL + '/user/getCallRsaById/' + answer_array['3'])
        .then(response => response.json())
        .then(response =>
          this.setState({ ambulance: response })
        )
        .catch(err => console.log(err))
    }
    if (!localStorage.getItem('session_id')) {
      this.props.history.push('/log_in');
    }
  }

  render() {
    const { ambulance } = this.state;
    return (
      <Container>
        <Row>
          <Col md={12}>
            <h3 className="page-title"> RSA Booking Detail</h3>
          </Col>
          <ToastContainer enableMultiContainer containerId={'A'} position={toast.POSITION.BOTTOM_LEFT} />
          <ToastContainer enableMultiContainer containerId={'B'} position={toast.POSITION.TOP_RIGHT} />
        </Row>
        <Row>
          <Col md={12} lg={12}>
            <Card>
              <CardBody>
                <Row>
                  <Col md={2} lg={2}>
                    <h5><b>Caseid : </b></h5>
                  </Col>
                  <Col md={6} lg={6}>
                    <h5>{ambulance.caseid != "" ? ambulance.caseid : "-"}</h5>
                  </Col>
                </Row>
                <br />

                <Row>
                  <Col md={2} lg={2}>
                    <h5><b>User Id : </b></h5>
                  </Col>
                  <Col md={6} lg={6}>
                    <h5>{ambulance.user_id != "" ? ambulance.user_id : "-"}</h5>
                  </Col>
                </Row>
                <br />

                <Row>
                  <Col md={2} lg={2}>
                    <h5><b>User name : </b></h5>
                  </Col>
                  <Col md={6} lg={6}>
                    <h5>{ambulance.first_name != "" ? ambulance.first_name : "-"}</h5>
                  </Col>
                </Row>
                <br />

                <Row>
                  <Col md={2} lg={2}>
                    <h5><b>Tenant name : </b></h5>
                  </Col>
                  <Col md={6} lg={6}>
                    <h5>{ambulance.tenant_name != "" ? ambulance.tenant_name : "-"}</h5>
                  </Col>
                </Row>
                <br />

                <Row>
                  <Col md={2} lg={2}>
                    <h5><b>Client : </b></h5>
                  </Col>
                  <Col md={6} lg={6}>
                    <h5>{ambulance.client != "" ? ambulance.client : "-"}</h5>
                  </Col>
                </Row>
                <br />

                <Row>
                  <Col md={2} lg={2}>
                    <h5><b>Contact_name : </b></h5>
                  </Col>
                  <Col md={6} lg={6}>
                    <h5>{ambulance.contact_name != "" ? ambulance.contact_name : "-"}</h5>
                  </Col>
                </Row>
                <br />

                <Row>
                  <Col md={2} lg={2}>
                    <h5><b>Mobile no : </b></h5>
                  </Col>
                  <Col md={6} lg={6}>
                    <h5>{ambulance.mobile_no != "" ? ambulance.mobile_no : "-"}</h5>
                  </Col>
                </Row>
                <br />

                <Row>
                  <Col md={2} lg={2}>
                    <h5><b>Pincode : </b></h5>
                  </Col>
                  <Col md={6} lg={6}>
                    <h5>{ambulance.pincode != "" ? ambulance.pincode : "-"}</h5>
                  </Col>
                </Row>
                <br />

                <Row>
                  <Col md={2} lg={2}>
                    <h5><b>Latitude : </b></h5>
                  </Col>
                  <Col md={6} lg={6}>
                    <h5>{ambulance.bdlatitude != "" ? ambulance.bdlatitude : "-"}</h5>
                  </Col>
                </Row>
                <br />

                <Row>
                  <Col md={2} lg={2}>
                    <h5><b>Longitude : </b></h5>
                  </Col>
                  <Col md={6} lg={6}>
                    <h5>{ambulance.bdlongitude != "" ? ambulance.bdlongitude : "-"}</h5>
                  </Col>
                </Row>
                <br />

                <Row>
                  <Col md={2} lg={2}>
                    <h5><b>Location : </b></h5>
                  </Col>
                  <Col md={6} lg={6}>
                    <h5>{ambulance.bdlocation != "" ? ambulance.bdlocation : "-"}</h5>
                  </Col>
                </Row>
                <br />

                <Row>
                  <Col md={2} lg={2}>
                    <h5><b>State : </b></h5>
                  </Col>
                  <Col md={6} lg={6}>
                    <h5>{ambulance.state != "" ? ambulance.state : "-"}</h5>
                  </Col>
                </Row>
                <br />

                <Row>
                  <Col md={2} lg={2}>
                    <h5><b>Subject : </b></h5>
                  </Col>
                  <Col md={6} lg={6}>
                    <h5>{ambulance.subject != "" ? ambulance.subject : "-"}</h5>
                  </Col>
                </Row>
                <br />

                <Row>
                  <Col md={2} lg={2}>
                    <h5><b>Service : </b></h5>
                  </Col>
                  <Col md={6} lg={6}>
                    <h5>{ambulance.service != "" ? ambulance.service : "-"}</h5>
                  </Col>
                </Row>
                <br />

                <Row>
                  <Col md={2} lg={2}>
                    <h5><b>Subservice : </b></h5>
                  </Col>
                  <Col md={6} lg={6}>
                    <h5>{ambulance.subservice != "" ? ambulance.subservice : "-"}</h5>
                  </Col>
                </Row>
                <br />

                <Row>
                  <Col md={2} lg={2}>
                    <h5><b>Fuel : </b></h5>
                  </Col>
                  <Col md={6} lg={6}>
                    <h5>{ambulance.fuel != "" ? ambulance.fuel : "-"}</h5>
                  </Col>
                </Row>
                <br />

                <Row>
                  <Col md={2} lg={2}>
                    <h5><b>Vehicleno : </b></h5>
                  </Col>
                  <Col md={6} lg={6}>
                    <h5>{ambulance.vehicleno != "" ? ambulance.vehicleno : "-"}</h5>
                  </Col>
                </Row>
                <br />

                <Row>
                  <Col md={2} lg={2}>
                    <h5><b>Vinn : </b></h5>
                  </Col>
                  <Col md={6} lg={6}>
                    <h5>{ambulance.vinn != "" ? ambulance.vinn : "-"}</h5>
                  </Col>
                </Row>
                <br />

                <Row>
                  <Col md={2} lg={2}>
                    <h5><b>Manufacturer : </b></h5>
                  </Col>
                  <Col md={6} lg={6}>
                    <h5>{ambulance.manufacturer != "" ? ambulance.manufacturer : "-"}</h5>
                  </Col>
                </Row>
                <br />

                <Row>
                  <Col md={2} lg={2}>
                    <h5><b>Model : </b></h5>
                  </Col>
                  <Col md={6} lg={6}>
                    <h5>{ambulance.model != "" ? ambulance.model : "-"}</h5>
                  </Col>
                </Row>
                <br />

                <Row>
                  <Col md={2} lg={2}>
                    <h5><b>Running km : </b></h5>
                  </Col>
                  <Col md={6} lg={6}>
                    <h5>{ambulance.runningkm != "" ? ambulance.runningkm : "-"}</h5>
                  </Col>
                </Row>
                <br />

                <Row>
                  <Col md={2} lg={2}>
                    <h5><b>Service eligibility : </b></h5>
                  </Col>
                  <Col md={6} lg={6}>
                    <h5>{ambulance.serviceeligibility != "" ? ambulance.serviceeligibility : "-"}</h5>
                  </Col>
                </Row>
                <br />

                <Row>
                  <Col md={2} lg={2}>
                    <h5><b>Policyno : </b></h5>
                  </Col>
                  <Col md={6} lg={6}>
                    <h5>{ambulance.policyno != "" ? ambulance.policyno : "-"}</h5>
                  </Col>
                </Row>
                <br />

                <Row>
                  <Col md={2} lg={2}>
                    <h5><b>Warrplcy start date : </b></h5>
                  </Col>
                  <Col md={6} lg={6}>
                    <h5>{ambulance.warrplcystartdate != "" ? ambulance.warrplcystartdate : "-"}</h5>
                  </Col>
                </Row>
                <br />

                <Row>
                  <Col md={2} lg={2}>
                    <h5><b>Warrplcy end date : </b></h5>
                  </Col>
                  <Col md={6} lg={6}>
                    <h5>{ambulance.warrplcyenddate != "" ? ambulance.warrplcyenddate : "-"}</h5>
                  </Col>
                </Row>
                <br />

                <Row>
                  <Col md={2} lg={2}>
                    <h5><b>Policy type : </b></h5>
                  </Col>
                  <Col md={6} lg={6}>
                    <h5>{ambulance.policytype != "" ? ambulance.policytype : "-"}</h5>
                  </Col>
                </Row>
                <br />

                <Row>
                  <Col md={2} lg={2}>
                    <h5><b>Voice of customer : </b></h5>
                  </Col>
                  <Col md={6} lg={6}>
                    <h5>{ambulance.voiceofcustomer != "" ? ambulance.voiceofcustomer : "-"}</h5>
                  </Col>
                </Row>
                <br />

                <Row>
                  <Col md={2} lg={2}>
                    <h5><b>Vehicle condition : </b></h5>
                  </Col>
                  <Col md={6} lg={6}>
                    <h5>{ambulance.vehiclecondition != "" ? ambulance.vehiclecondition : "-"}</h5>
                  </Col>
                </Row>
                <br />

                <Row>
                  <Col md={2} lg={2}>
                    <h5><b>Sale date : </b></h5>
                  </Col>
                  <Col md={6} lg={6}>
                    <h5>{ambulance.saledate != "" ? ambulance.saledate : "-"}</h5>
                  </Col>
                </Row>
                <br />

                <Row>
                  <Col md={2} lg={2}>
                    <h5><b>Accident type : </b></h5>
                  </Col>
                  <Col md={6} lg={6}>
                    <h5>{ambulance.accidenttype != "" ? ambulance.accidenttype : "-"}</h5>
                  </Col>
                </Row>
                <br />

                <Row>
                  <Col md={2} lg={2}>
                    <h5><b>Vehicle type : </b></h5>
                  </Col>
                  <Col md={6} lg={6}>
                    <h5>{ambulance.vehicletype != "" ? ambulance.vehicletype : "-"}</h5>
                  </Col>
                </Row>
                <br />

                <Row>
                  <Col md={2} lg={2}>
                    <h5><b>Vehicle loaded : </b></h5>
                  </Col>
                  <Col md={6} lg={6}>
                    <h5>{ambulance.vehicleloaded != "" ? ambulance.vehicleloaded : "-"}</h5>
                  </Col>
                </Row>
                <br />

                <Row>
                  <Col md={2} lg={2}>
                    <h5><b>Extra fittings : </b></h5>
                  </Col>
                  <Col md={6} lg={6}>
                    <h5>{ambulance.extrafittings != "" ? ambulance.extrafittings : "-"}</h5>
                  </Col>
                </Row>
                <br />
                <Row>
                  <Col md={2} lg={2}>
                    <h5><b>Drploc type : </b></h5>
                  </Col>
                  <Col md={6} lg={6}>
                    <h5>{ambulance.drploctype != "" ? ambulance.drploctype : "-"}</h5>
                  </Col>
                </Row>
                <br />

                <Row>
                  <Col md={2} lg={2}>
                    <h5><b>Dealer : </b></h5>
                  </Col>
                  <Col md={6} lg={6}>
                    <h5>{ambulance.dealer != "" ? ambulance.dealer : "-"}</h5>
                  </Col>
                </Row>
                <br />

                <Row>
                  <Col md={2} lg={2}>
                    <h5><b>Custpreftype : </b></h5>
                  </Col>
                  <Col md={6} lg={6}>
                    <h5>{ambulance.custpreftype != "" ? ambulance.custpreftype : "-"}</h5>
                  </Col>
                </Row>
                <br />

                <Row>
                  <Col md={2} lg={2}>
                    <h5><b>Custdrploc : </b></h5>
                  </Col>
                  <Col md={6} lg={6}>
                    <h5>{ambulance.custdrploc != "" ? ambulance.custdrploc : "-"}</h5>
                  </Col>
                </Row>
                <br />

                <Row>
                  <Col md={2} lg={2}>
                    <h5><b>Custdrplat : </b></h5>
                  </Col>
                  <Col md={6} lg={6}>
                    <h5>{ambulance.custdrplat != "" ? ambulance.custdrplat : "-"}</h5>
                  </Col>
                </Row>
                <br />

                <Row>
                  <Col md={2} lg={2}>
                    <h5><b>Custdrplong : </b></h5>
                  </Col>
                  <Col md={6} lg={6}>
                    <h5>{ambulance.custdrplong != "" ? ambulance.custdrplong : "-"}</h5>
                  </Col>
                </Row>
                <br />

                <Row>
                  <Col md={2} lg={2}>
                    <h5><b>Cancel Status : </b></h5>
                  </Col>
                  <Col md={6} lg={6}>
                    <h5>
                      {ambulance.cancel_status == '0' ? "No" : ''}
                      {ambulance.cancel_status == '1' ? "Yes" : ''}
                    </h5>
                  </Col>
                </Row>
                <br />

                <Row>
                  <Col md={2} lg={2}>
                    <h5><b>Date/Time : </b></h5>
                  </Col>
                  <Col md={6} lg={6}>
                    <h5>{ambulance.created_at}</h5>
                  </Col>
                </Row>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>)
  }
}

export default withTranslation('common')(Rsa_view);
