import React, { Component } from 'react';
import { Col, Container, Row, Button, Card, CardBody, ButtonToolbar, Input } from 'reactstrap';
import { withTranslation } from 'react-i18next';
import TextField from '@material-ui/core/TextField';
import PropTypes from 'prop-types';
import * as myConstClass from '../../../constant.js';
import $ from 'jquery';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Field, reduxForm } from 'redux-form';
import { ValidatorForm, TextValidator } from 'react-material-ui-form-validator';
import { Link } from 'react-router-dom';

class Banner_edit extends Component {
  constructor(props) {
    super(props);
    this.state = {
      edit_id: '',
      formData: {},
      submitted: false,
      errors: {},
    }
  }

  componentDidMount() {
    const answer_array = this.props.location.pathname.split('/');
    if (answer_array['3']) {
      this.state.edit_id = answer_array['3'];
      fetch(myConstClass.BASE_URL + '/banner/getBannerView/' + answer_array['3'])
        .then(response => response.json())
        .then(response => {
          this.setState({ formData: response })
        }).catch(err => console.log(err))
    }
    if (!localStorage.getItem('session_id')) {
      console.log('History:', this.props);
      this.props.history.push('/log_in');
    }
  }

  handleChange = (event) => {
    const { formData } = this.state;
    formData[event.target.name] = event.target.value;
    this.setState({ formData });
  }

  handleSubmit = () => {
    let form = document.querySelector('#pform');
    const errors = {};
    const formData = new FormData(form);
    if (localStorage.getItem('uid')) {
      formData.append('user_id', localStorage.getItem('uid'));
    }
    this.setState({ submitted: true }, () => {
      setTimeout(() => this.setState({ submitted: false }), 5000);
      fetch(myConstClass.BASE_URL + `/banner/UpdateBanner/` + this.state.edit_id, {
        method: 'POST',
        body: formData,
      }).then(response => response.json()).then((response) => {
        if (response == true) {
          this.props.history.push('/forms/banner/');
          toast('Banner Updated Successfully..!', { containerId: 'B', type: toast.TYPE.SUCCESS })
        } else {
          toast('Banner Update Failed..!', { containerId: 'B', type: toast.TYPE.ERROR })
        }
      }).catch();
    });
  }
  onImageFileChange = event => {
    const errors = {};
    const size = (event.target.files[0].size / 1024).toFixed(2);
    if (size > 500) {
      alert("The file must be less than 500 kb");
      toast('The file must be less than 500 kb', { containerId: 'B', type: toast.TYPE.ERROR })
      errors["image_error"] = "The file must be less than 500 kb";
    } else {
      errors["image_error"] = "";
    }
    this.setState({ selectedImage: event.target.files[0] });
    this.setState({ errors: errors });
  };

  render() {
    const { formData, submitted } = this.state;
    return (
      <Container>
        <Row>
          <Col md={12}>
            <h3 className="page-title">Edit Banner</h3>
          </Col>
        </Row>
        <Row>
          <Card>
            <CardBody>
              <div className="card__title">
              </div>
              <div>
                <ToastContainer enableMultiContainer containerId={'A'} position={toast.POSITION.BOTTOM_LEFT} />
                <ToastContainer enableMultiContainer containerId={'B'} position={toast.POSITION.TOP_RIGHT} />
              </div>
              <ValidatorForm
                className="material-form" id="pform"
                onSubmit={this.handleSubmit}
              >
                <br />
                <Row>
                  <Col md={6} lg={6}>
                    <h6> Title</h6>
                    <TextValidator
                      onChange={this.handleChange}
                      name="title"
                      // label="Title"
                      value={formData.title}
                      validators={['required']}
                      errorMessages={['this field is required']}
                      className="material-form__field"
                    />
                    <div>
                    </div>
                  </Col>
                </Row>
                <Row>
                  <Col md={6} lg={6}>
                    <h6> Redirection url</h6>
                    <TextValidator
                      onChange={this.handleChange}
                      name="redirection_url"
                      // label="Title"
                      value={formData.redirection_url}
                      validators={['required']}
                      errorMessages={['this field is required']}
                      className="material-form__field"
                    />
                    <div>
                    </div>
                  </Col>
                </Row>
                <Row>
                  <Col md={6} lg={6}>
                    <h6>Select Image</h6>
                    <input type="file"
                      // onChange={this.handleChange}
                      onChange={this.onImageFileChange}
                      name="image"
                      value={formData.image}
                    />
                    <br />
                    <span className="">
                      (Note: The file must be less than 5 mb)
                    </span>
                    <br />
                    <span className="red-text">
                      {this.state.errors["image_error"]}
                    </span>
                    <div>
                    </div>
                  </Col>
                </Row>
                <Row>
                  <Col md={6} lg={6}>
                    <h6  >Image</h6>
                    <img src={formData.image_url} alt="image" style={{ height: '200px' }} />
                    <div>
                    </div>
                  </Col>
                </Row>
                <Row>
                  <Col md={6} lg={6}>
                    <br />
                    <h6>Created Date/Time</h6>
                    {formData.created_at}
                    <div>
                    </div>
                  </Col>
                </Row>
                <br />
                <Button
                  color="primary"
                  variant="contained"
                  type="submit"
                  disabled={submitted}
                >
                  {(submitted && 'Your form is submitted!') || (!submitted && 'Submit')}
                </Button>
                <Link className="btn btn-secondary" to="/forms/banner">Cancel</Link>

              </ValidatorForm>
            </CardBody>
          </Card>
        </Row>
      </Container>
    )
  }
}

export default reduxForm({
  form: 'floating_labels_form', // a unique identifier for this form
})(withTranslation('common')(Banner_edit));
