import React, { Component } from 'react';
import { Col, Container, Row, Button, Card, CardBody, ButtonToolbar, Input } from 'reactstrap';
import { FormGroup, Label } from 'reactstrap';
import { withTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import showResults from '../Show';
import { Field, reduxForm } from 'redux-form';
import FileUploadDefault from './components/FileUploadDefault';
import $ from 'jquery';
//import './custom.css';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { MDBDataTable } from 'mdbreact';
import * as myConstClass from '../../../constant.js';
import CsvDownloader from 'react-csv-downloader';

import BootstrapTable from "react-bootstrap-table-next";
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";
import paginationFactory from "react-bootstrap-table2-paginator";
import DatePicker1 from 'react-datepicker';


const { SearchBar } = Search;
const columns = [{
  id: 'id',
  displayName: 'ID'
}, {
  id: 'first_name',
  displayName: 'First Name'
}, {
  id: 'middle_name',
  displayName: 'First Name'
}, {
  id: 'last_name',
  displayName: 'Last Name'
}, {
  id: 'mobile',
  displayName: 'Mobile'
}, {
  id: 'email',
  displayName: 'Email'
}, {
  id: 'gender',
  displayName: 'Gender'
}, {
  id: 'blood_group',
  displayName: 'Blood Group'
}, {
  id: 'dob',
  displayName: 'DOB'
}, {
  id: 'age',
  displayName: 'Age'
}, {
  id: 'status',
  displayName: 'Status'
}, {
  id: 'action',
  displayName: 'Action'
}, {
  id: 'error',
  displayName: 'Error'
}];

const tableColumns = [
  {
    dataField: "sr_no",
    text: "#",
    sort: true,
    headerStyle: { width: "10%" }
  }, {
    dataField: "first_name",
    text: 'Name',
    sort: true,
    headerStyle: { width: "10%" }
  }, {
    dataField: "email",
    text: "Email",
    sort: true,
    headerStyle: { width: "10%" }
  }, {
    dataField: "mobile",
    text: "Mobile",
    headerStyle: { width: "10%" },
    csvExport: false
  }, {
    dataField: "gender",
    text: "Gender",
    sort: true,
    headerStyle: { width: "10%" }
  }, {
    dataField: "dob",
    text: 'DOB',
    sort: true,
    headerStyle: { width: "10%" }
  }, {
    dataField: "age",
    text: "Age",
    headerStyle: { width: "10%" },
    csvExport: false
  }, {
    dataField: "status",
    text: "Status",
    headerStyle: { width: "10%" },
    csvExport: false
  }, {
    dataField: "action",
    text: "Action",
    headerStyle: { width: "10%" },
    csvExport: false
  }, {
    dataField: "error",
    text: "Error",
    headerStyle: { width: "10%" },
    csvExport: false
  },
];

const file_link = `${process.env.PUBLIC_URL}/SAMPLE File.csv`;
const loader = `${process.env.PUBLIC_URL}/loader.gif`;
const rolling = `${process.env.PUBLIC_URL}/img/Rolling.gif`;

class Bulk_import extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedFile: null,
      posts: [],
      dusers: '',
      uusers: '',
      posts_icnt: 0,
      posts_ucnt: 0,
      posts_ecnt: 0,
      AdminData: [],
      admin_select: '',
      bulk_uploade_session: '',
      session_tenant_id: '',
      start_date: new Date(),
      formData: [],
      plan_id_select: '',
      plan_id: '',
      plan: [],
    }
    this.flag = 0;
    this.customLabels = {
      first: '<<',
      last: '>>',
      prev: '<',
      next: '>',
      show: 'Display',
      entries: 'rows',
      noResults: 'There is no data to be displayed',
    };
    this.handleSubmit = this.handleSubmit.bind(this);
  }
  componentDidMount() {
    console.log(localStorage.getItem('session_id'));

    // session uid
    if (localStorage.getItem('uid')) {
      if (localStorage.getItem('uid') != 1) {
        this.setState({ session_tenant_id: localStorage.getItem('uid') })
      }
    }

    if (!localStorage.getItem('session_id')) {
      this.props.history.push('/log_in');
    }
    fetch(myConstClass.BASE_URL + '/user/getAdmin')
      .then(response => response.json())
      .then(response => {
        let i = 1;
        response.forEach(element => {
          //  let id = element.uid;
          //  element.action = <div><i id={id} className="fa fa-remove ptr_css" onClick={() => this.remove(id)}></i><i id={id} className="fa fa-edit ptr_css" onClick={() => this.edit(id)}></i></div>;
          // element.uid = i;
          i++;
        });
        this.setState({ AdminData: response })
      })
      .catch(err => console.log(err))
    fetch(myConstClass.BASE_URL + '/user/getPlans')
      .then(response => response.json())
      .then(response => { this.setState({ plan: response }) }).catch(err => console.log(err))
  }

  handleSubmit = () => {
    alert(JSON.stringify($('#pform').serializeArray()));
  }

  onChangeHandler = event => {
    // this.setState({
    //   selectedFile: event.target.files[0],
    //   loaded: 0,
    // })
    event.persist();
    let suppotedVideoFormat = ["application/vnd.ms-excel", "text/csv", "application/csv", "text/comma-separated-values"];
    if (suppotedVideoFormat.includes(event.target.files[0].type)) {
      this.setState({
        selectedFile: event.target.files[0],
        loaded: 0,
      })
    } else {
      //this.setState({ errCsvEmpty: true, errCsvText: "Unsupported File Format, Please Add Csv File" });
      alert("Unsupported File Format, Please Add Csv File");
    }
  }

  onClickHandler = () => {
    var start_date = $("#start_date").val();
    var licence_days = $("#licence_days").val();
    var plan_id = $("#plan_id").val();
    var plan_id_select = $("#plan_id_select").val();
    var plan_id_select_split = plan_id_select.split("######");
    var plan_val_day = plan_id_select_split[1];

    if (this.state.selectedFile == null) {
      alert("Please select file")
    } else if (this.state.admin_select == "" && this.state.session_tenant_id == "") {
      alert("Please select admin")
    } else if (start_date != "" && licence_days == "") {
      alert("Please select days")
    } else if (start_date == "" && licence_days == "") {
      alert("Please select Start date or Licence Days")
    } else if (plan_id == "") {
      alert("Please select Plan");
    } else if (licence_days != plan_val_day) {
      alert('Days and plan day should be same.')
      // toast('Days and plan day should be same.', { containerId: 'B', type: toast.TYPE.ERROR })
    } else {

      var bulk_uploade_session = localStorage.getItem('session_id') + Number(new Date());
      // console.log(bulk_uploade_session);
      this.setState({ bulk_uploade_session: bulk_uploade_session })
      const data = new FormData()
      data.append('file', this.state.selectedFile);
      // data.append('session_id', localStorage.getItem('session_id'));
      data.append('session_id', bulk_uploade_session);
      if (this.state.session_tenant_id == "") {

        data.append('admin_select', this.state.admin_select);
      } else {

        data.append('admin_select', this.state.session_tenant_id);
      }
      data.append('start_date', start_date);
      data.append('licence_days', this.state.licence_days);
      data.append('plan_id', this.state.plan_id);


      $('#loader').show();
      fetch(myConstClass.BASE_URL + `/user/userBulkUpload`, {
        method: 'POST',
        body: data,
      }).then(response => response.json())
        .then(response => {
          // this.setState({ posts: response });
          this.setState({ posts_icnt: response['icnt'] });
          this.setState({ posts_ucnt: response['ucnt'] });
          this.setState({ posts_ecnt: response['ecnt'] });
          this.setState({ posts: response['rows'] });

          $('#loader').hide();

          fetch(myConstClass.BASE_URL + `/user/getDeclinedBulkUser`, {
            method: 'POST',
            body: data,
          }).then(response => response.json())
            .then(response => this.setState({ dusers: response }))

          fetch(myConstClass.BASE_URL + `/user/getUpdatedBulkUser`, {
            method: 'POST',
            body: data,
          }).then(response => response.json())
            .then(response => this.setState({ uusers: response }))
        })
    }
  }

  onSaveClickHandler = () => {
    const data = new FormData()
    data.append('file', this.state.selectedFile);
    //data.append('session_id', localStorage.getItem('session_id'));
    data.append('session_id', this.state.bulk_uploade_session);

    fetch(myConstClass.BASE_URL + `/user/saveBulkImportData`, {
      method: 'POST',
      body: data,
    }).then(response => response.json()).then((response) => {
      if (response) {
        toast('User Added Successfully..!', { containerId: 'B', type: toast.TYPE.SUCCESS })
        this.props.history.push('/forms/users/');
      } else {
        toast('User Insertion Failed..!', { containerId: 'B', type: toast.TYPE.ERROR })
      }
    }).catch();
  }

  handleAdminChange = (event) => {
    this.setState({ admin_select: event.target.value });
  }
  handleDateChange = (date) => {
    this.setState({ start_date: date });
    const { formData } = this.state;
    formData['start_date'] = date;
    this.setState({ formData });
  }

  handleLicenceDaysChange = (event) => {
    var licence_days = event.target.value;
    var plan_id_select = $("#plan_id_select").val();
    var plan_id_select_split = plan_id_select.split("######");
    var plan_val_day = plan_id_select_split[1];
    if (licence_days != "" && plan_id_select != "") {
      if (licence_days != plan_val_day) {
        toast('Days and plan day should be same.', { containerId: 'B', type: toast.TYPE.ERROR })
      }
    }
    const { formData } = this.state;
    formData['licence_days'] = event.target.value;
    this.setState({ formData });
    this.setState({ licence_days: event.target.value });
  }
  handlePlanChange = (event) => {
    var plan_val = event.target.value;
    var licence_days = $("#licence_days").val();
    var plan_val_split = plan_val.split("######");
    var plan_id = plan_val_split[0];
    var plan_val_day = plan_val_split[1];
    if (licence_days != "" && plan_val != "") {
      if (licence_days != plan_val_day) {
        toast('Days and plan day should be same.', { containerId: 'B', type: toast.TYPE.ERROR })
      }
    }
    const { formData } = this.state;
    $("#plan_id").val(plan_id);
    this.setState({ plan_id: plan_id });
    formData['plan_id'] = plan_id;
    formData['plan_id_select'] = event.target.value;
    this.setState({ formData });
  }
  render() {
    const { start_date, plan, plan_id } = this.state;

    return (
      <Container>
        <Row>
          <ToastContainer enableMultiContainer containerId={'A'} position={toast.POSITION.BOTTOM_LEFT} />
          <ToastContainer enableMultiContainer containerId={'B'} position={toast.POSITION.TOP_RIGHT} />
          <Col md={12}>
            <h3 className="page-title">User Bulk Import</h3>
          </Col>
        </Row>
        <Card className="card-body">
          <h5>
            <b>Import CSV file only</b>
            <a href={file_link} style={{ float: 'right' }}>
              <Button color="primary" type="button" className="previous">Download Sample File</Button>
            </a>
            <br /><br />
            1. The first line in downloaded csv file should remain as it is. Please do not change the order of columns in csv file.
            <br />
            2. The correct column order is <br />(First Name(*), Middle Name, Last Name(*), Mobile(*), Email(*), Gender(*), Blood Group(A+,B+,O+,AB+,A-,B-,O-,AB-), Age, DOB(*)(YYYY-MM-DD), Employee Status(*)(Active/Inactive/Suspended), Emergency Contact No 1, Emergency Contact Name 1, Emergency Contact No 2, Emergency Contact Name 2,  Emergency Contact No 3, Emergency Contact Name 3, Emergency Contact No 4, Emergency Contact Name 4
            ) <br />
            3. You must follow the csv file, otherwise your data will not be saved.
            <br />
            4. (*) indicate mandatory fields in csv file.
          </h5>
          <hr />
          {this.state.session_tenant_id == "" ?
            <Row>
              <Col md={4} lg={4}>
                <span className='text_light_b'>Select Tenant:</span>
                <Input type="select" name="admin_select" id="admin_select" onChange={this.handleAdminChange} className="width_150px" style={{ display: 'inline', marginLeft: '10px' }}>
                  <option value="">Select Tenant</option>
                  {this.state.AdminData.map((number) =>
                    <option value={number.uid}>{number.first_name}</option>
                  )}
                </Input>
              </Col>
              <Col md={4} lg={4}>
                <span className='text_light_b' style={{ marginRight: '10px' }}>Start date:</span>
                <DatePicker1
                  className='form-control width_150px'
                  style={{ width: "auto", display: 'inline' }}
                  dateFormat="dd-MM-yyyy"
                  selected={start_date}
                  name="start_date"
                  onChange={this.handleDateChange}
                  id="start_date"
                />
                <span className='text_light_b' style={{ marginRight: '10px', marginLeft: '10px' }}>Days:</span>
                <input
                  className='form-control width_50px'
                  style={{ display: "inline" }}
                  type="text"
                  placeholder=""
                  required
                  onChange={this.handleLicenceDaysChange}
                  name="licence_days"
                  id="licence_days"
                  validators={['matchRegexp:^[0-9]{1,6}$']}
                  errorMessages={['Enter maximum 6 digit Number..']}
                />
              </Col>
              <Col md={4} lg={4}>
                <span className='text_light_b' style={{ marginRight: '10px' }}>Select Plan:</span>
                <Input type="select" name="plan_id_select" id="plan_id_select" onChange={this.handlePlanChange} className="width_200px" style={{ display: 'inline', marginLeft: '10px' }}>
                  <option value="">Select Plan</option>
                  {this.state.plan.map((item, i) =>
                    <option value={item.id + '######' + item.duration}>{item.title} ({item.duration} days)</option>
                  )}
                </Input>
                <input type="hidden" id="plan_id" name="plan_id" value={plan_id} />
              </Col>
            </Row>
            : ""}
          {this.state.session_tenant_id == "" ?
            <Row>
              {/* <Col md={2} lg={2}> <h5>Select Tenant : </h5></Col>
              <Col md={3} lg={3}>
                <Input type="select" name="admin_select" id="admin_select" onChange={this.handleAdminChange}>
                  <option value="">Select Tenant</option>
                  {this.state.AdminData.map((number) =>
                    <option value={number.uid}>{number.first_name}</option>
                  )}
                </Input>
              </Col>

              <Col md={4} lg={4}>Start date
                <DatePicker1
                  className='form-control'
                  style={{ width: "auto" }}
                  dateFormat="dd-MM-yyyy"
                  selected={start_date}
                  name="start_date"
                  onChange={this.handleDateChange}
                  id="start_date"
                />
              </Col>
              <Col md={3} lg={3}>Days

                <input
                  className='form-control'
                  style={{ width: "50%", display: "inline" }}
                  type="text"
                  placeholder=""
                  required
                  onChange={this.handleLicenceDaysChange}
                  name="licence_days"
                  id="licence_days"
                  validators={['matchRegexp:^[0-9]{1,6}$']}
                  errorMessages={['Enter maximum 6 digit Number..']}
                />
              </Col> */}
            </Row>
            :
            ""}
        </Card>
        {this.state.posts && this.state.posts.length ?
          <Card className="card-body">
            <div>
              <Row>
                <Col md={9}>
                  <h5>Insert : {this.state.posts_icnt} &nbsp;&nbsp; Update : {this.state.posts_ucnt} &nbsp;&nbsp; Error : {this.state.posts_ecnt}</h5>
                </Col>
                <Col md={3} >
                  <CsvDownloader
                    filename="UpdatedUser"
                    separator=","
                    columns={columns}
                    datas={this.state.uusers}
                    className="btn btn-primary btn-block cmargin"
                    text="Updated User list" />
                  &nbsp;&nbsp;&nbsp;&nbsp;
                  <CsvDownloader
                    filename="UserError"
                    separator=","
                    columns={columns}
                    datas={this.state.dusers}
                    className="btn btn-primary btn-block"
                    text="User Error list" />
                </Col>
              </Row>
              <hr />
            </div>
          </Card>
          :
          <Card className="card-body">
            <div>
              <form className="form" id="pform" onSubmit={this.handleSubmit} style={{ display: "inline" }}>
                <Row>
                  <Col md={3}>
                    <input type="file" name="file" onChange={this.onChangeHandler} />
                  </Col>

                  <Col md={6}>
                    <button type="button" className="btn btn-primary btn-block col-md-4" onClick={this.onClickHandler}>Upload</button>
                    <img src={loader} id="loader" style={{ width: '12%', display: 'none' }} />
                  </Col>

                  <Col md={3}>

                  </Col>
                </Row>
              </form>
            </div>
          </Card>
        }
        {this.state.posts && this.state.posts.length ?
          <Row>
            <Col md={12} lg={12}>
              <Card>
                <ToolkitProvider
                  keyField="name"
                  data={this.state.posts}
                  columns={tableColumns}
                  exportCSV
                  search
                >
                  {props => (
                    <div>
                      <CardBody>
                        <BootstrapTable
                          {...props.baseProps}
                          bootstrap4
                          bordered={false}
                          pagination={paginationFactory({
                            sizePerPage: 10,
                            sizePerPageList: [5, 10, 25, 50]
                          })}
                        />
                      </CardBody>
                    </div>
                  )}
                </ToolkitProvider>
                <div className='card-body'>
                  <button type="button" className="btn btn-primary btn-block col-md-2" onClick={this.onSaveClickHandler}>Save</button>
                </div>
              </Card>
            </Col>
          </Row>
          : ''}

      </Container>)
  }
}

Bulk_import.propTypes = {
  reset: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired,
};
export default reduxForm({
  form: 'Bulk_import',
})(withTranslation('common')(Bulk_import));
